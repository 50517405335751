import React,{useState,useEffect,useRef} from 'react';
import { Platform,KeyboardAvoidingView,FlatList,Dimensions,TouchableHighlight,Text,View, Keyboard,SafeAreaView, Image ,TouchableOpacity} from 'react-native';
import styles from './styles';
import BottomButtons from '../../Components/BottomButtons/BottomButtons';
import CustomSwitch from '../../Components/CustomSwitch/CustomSwitch';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { loadProducts } from '../../Helpers/getSolutions';
import { normalize } from '../../Helpers/Normalizer';
import BottomSheet from 'reanimated-bottom-sheet';
import TextTicker from 'react-native-text-ticker';
import ProductFilterContent from '../../Components/BottomSheetContent/ProductFilterContent';
import { RFValue } from 'react-native-responsive-fontsize';
import AsyncStorage from '@react-native-async-storage/async-storage';
const { width, height } = Dimensions.get('window');
const AllProducts = ({navigation,route}) => {
    const keyboardVerticalOffset = Platform.OS === 'ios' ? normalize(80) :0
    const [filter,setFilter]=useState(route.params.filter);
    const [listView,setListView]=useState(true);
    const [filterState,setFilterState]=useState(false);
    const [query,setQuery]=useState(route.params.query);
    const [products,setProducts]=useState([]);
    const [openFilter,setOpenFilter]=useState(false);
    const [language,setLanguage]=useState(route.params.language);
    const trans={
        IT:{
            "Product Library":"Libreria Prodotti",
            "FILTER":"FILTRO"
        },
        EN:{
            "Product Library":"Product Library",
            "FILTER":"FILTER"
        },
        ES:{
            "Product Library":"Biblioteca de productos",
            "FILTER":"FILTRAR"
        },
        DE:{
            "Product Library":"Produktbibliothek",
            "FILTER":"FILTER"
        },
    }
    useEffect(()=>{
        const getLanguage= ()=>{
            AsyncStorage.getItem("language").then((response)=>{
              if(response!=null){
                  setLanguage(JSON.parse(response));
                //   console.log(language);
                }
              })
          }
        getLanguage();
        // console.log(filter);
        // console.log(language);
    })
    const onSelectSwitch = index => {
        if (index==2) {
            setListView(false);
            // console.log("apple");
        }
        else{
            setListView(true);
            // console.log("mango");
        }
      };
    const productDetail=(item)=>{
        console.log(item);
        navigation.navigate("Product", {
            name: item.product_name,
            image: item.product_image,
            product_line:item.product_line,
            solution_type:item.solution_type
        });
    };
      useEffect(()=>{
        (async () => {
            var p=await loadProducts(filter);
            var newArray = p.filter(function (item) {
                return (item.product_name.toLowerCase()).includes(query.toLowerCase()) || query=="";
            });
            setProducts(newArray);
        })();
        // console.log(query,"xuma");
      },[filter,query,openFilter,filterState,route]);
    
    
    useEffect(() => {
        AsyncStorage.getItem("token").then((response)=>{
            if(JSON.parse(response)==null){
                console.log(response,route)
                navigation.navigate("Login",{route:route});
            };
            })
    },[route]);
    return(
        <View
            
            style={[styles.container,{justifyContent:"space-between"}]}>
            <KeyboardAvoidingView
                behavior= {Platform.OS === 'ios' ?'position':'height' }keyboardVerticalOffset={keyboardVerticalOffset}>
                <View
                    style={{
                        flexDirection:"row",
                        justifyContent:"space-between",
                        paddingTop:normalize(50),
                        paddingHorizontal:normalize(20),
                        backgroundColor:"white",
                    }}
                >
                <BottomButtons
                    Buttons={1}
                    buttonText1={trans[language]["Product Library"]}
                />
                <TouchableOpacity
                    onPress={()=>{
                        navigation.navigate("Dashboard");
                    }}>
                    <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
                </TouchableOpacity>
                </View>
                <View style={[styles.bottomTab,openFilter?{backgroundColor:"white"}:null]}>
                    <View style={styles.galleryToggle}>
                    <CustomSwitch
                        selectionMode={1}
                        roundCorner={true}
                        option1={'First'}
                        onSelectSwitch={onSelectSwitch}
                        option2={'Second'}
                        
                        selectionColor={'#D90000'}
                    />
                    </View>
                    <SearchBar width={width>786?558:180} language={language}onChangeText={(query)=>setQuery(query)}/>
                    <TouchableOpacity style={styles.filterBtn}
                        onPress={()=>{
                            setOpenFilter(prev=>!prev);
                            // openFilter.current.snapTo(1);
                        }}
                        >
                        <Text style={styles.filterText}>{trans[language]["FILTER"]}</Text>
                        <Icon
                            name={openFilter?"chevron-up":"chevron-down"}
                            color="white"
                            size={normalize(25)}
                        />
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
            {openFilter?
            <ProductFilterContent 
                doFilter={(filter)=>{
                    setFilter(filter);
                    setFilterState(true);
                }} 
                _filter={filter}
                openFilter={()=>setOpenFilter(false)} 
                language={language}
            />:null}
            {listView?
            <FlatList
                // .filter(item=>(item.product_name.toLowerCase()).includes(query.toLowerCase()))
                data={products.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        (t.product_name + t.solution_type) === (thing.product_name + thing.solution_type)
                    ))
                    )}
                // data={products}
                style={styles.container}
                renderItem={({item ,index}) => {
                    return(
                        <TouchableHighlight
                            underlayColor='none'
                            onPress={()=>productDetail(item)}>
                            <View style={styles.pcontainer}>
                                <Image style={styles.photo} source={{uri:item.product_image}} />
                                <Text style={styles.title}>{item.product_name}</Text>
                                <Text style={styles.title}>{item.solution_type}</Text>
                            </View>
                        </TouchableHighlight>
                    )
                
                }}
                keyExtractor={(item,index)=>index.toString()}
                numColumns={width>786?4:2}
                key={"1"}
            />
            :
            <FlatList
                data={products.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        (t.product_name + t.solution_type) === (thing.product_name + thing.solution_type)
                    ))
                    )}
                style={styles.container}
                contentContainerStyle={{
                    borderTopWidth:2,
                    borderTopColor:"#D90000",
                    marginHorizontal:width>786?normalize(100):normalize(20),
                }}
                renderItem={({item ,index}) => {
                    return(
                        <TouchableHighlight
                            
                            underlayColor='none'
                            onPress={()=>productDetail(item)}>

                            <View 
                                style={{ 
                                    flexDirection:width>786?"row":'column',
                                    backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
                                    // width:normalize(334),
                                    height:normalize(45,735),
                                    paddingVertical:normalize(5),
                                    justifyContent:"flex-start",
                                    alignContent:"center",
                                     
                                }}>
                                <View 
                                    style={{
                                        width:normalize(261)
                                    }}
                                >
                                <TextTicker
                                    duration={3000}
                                    style={{
                                        color:"#333333",
                                        fontFamily:"OpenSansCondensedBold",
                                        fontSize:normalize(16),
                                        marginLeft:normalize(5),
                                        
                                    }}>{item.product_name + " " + item.solution_type}</TextTicker>
                                </View>
                                <Text style={{color:"#333333",fontFamily:"OpenSansCondensedBold",fontSize:normalize(16),marginLeft:normalize(5)}}>{(item.product_line).toUpperCase()}</Text>
                            </View>
                        </TouchableHighlight>)}
                }
                keyExtractor={(item,index)=>index.toString()}
                numColumns={1}
                key={"2"} // if you want to use dynamic numColumns then you have to use key props
            />
            }
        </View>
    );
};

export default AllProducts