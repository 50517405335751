export default[
    {
        id:'1',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",
    },
    {
        id:'2',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'3',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'4',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'5',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'6',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'7',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'8',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'9',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'10',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'11',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
    {
        id:'12',
        title:'Amotherm Brick WB',
        image:require('../../../assets/balti.jpeg'),
        doc:"TDS_IT(IT)_AMOTHERM_BRICK_WB_4319-A",

    },
];
// "@babel/eslint-parser": "^7.15.7",