import { StyleSheet,Dimensions,StatusBar, Platform} from "react-native";
const screenHeight = Dimensions.get('screen').height;
const windowHeight = Dimensions.get('window').height;
const navbarHeight = screenHeight - (windowHeight+getStatusBarHeight());
// const height=windowHeight-navbarHeight;
const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
import { getStatusBarHeight } from "react-native-status-bar-height";
import { RFValue } from "react-native-responsive-fontsize";
import { normalize } from "../../Helpers/Normalizer";
// import { normalize } from "react-native-responsive-fontsize";
import * as theme from "../../Theme";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent',
        alignItems:'center',
    },
    // body:{
    //     flex: 1,
    //   backgroundColor: "#fff",
    //   alignItems: "center",
    //   justifyContent: "space-between",
    //   height:height-normalize(240),
    //   paddingVertical:normalize(40),
    //   marginHorizontal:normalize(120),
    //   marginVertical:normalize(120),
    //   borderBottomLeftRadius:normalize(200),
    //   borderBottomRightRadius:normalize(200),
    //   borderTopRightRadius:normalize(200),
    //   width:width-normalize(240)
    //     // elevation:5,
    // },
    body:{
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
        height:width>786?height-normalize(240):height-normalize(10),
        paddingVertical:width>786?normalize(40):normalize(140),
        marginHorizontal:width>786?normalize(120):normalize(10),
        marginVertical:width>786?normalize(120):normalize(40),
        borderBottomLeftRadius:width>786?normalize(200):normalize(150),
        borderBottomRightRadius:width>786?normalize(200):normalize(150),
        borderTopRightRadius:width>786?normalize(200):normalize(150),
        width:width>786?width-normalize(240):width-normalize(20)
    },
    circle: {
        position:"absolute",
        height: normalize(60),
        width: normalize(60),
        borderRadius: 50,
        elevation:20,
        top:normalize(90),
        zIndex:1000,
    },
    avatar: {
        height: normalize(65),
        width: normalize(65),
    },
    logoView: {
        marginTop:normalize(40,735),
        width:normalize(170),
        height:normalize(33,735),
        justifyContent:"center",
        alignItems:"center"
    },
    logo:{
        width:normalize(170),
        height:normalize(33,735),
        resizeMode:"contain"
    },
    loginBtn: {
        width: normalize(121),
        borderRadius: normalize(25),
        backgroundColor:"#D90000",
        height: normalize(40),
        alignItems: "center",
        justifyContent: "center",
        marginVertical:normalize(50,735),
      
      },
    loginText:{
        fontFamily:"OpenSansCondensedBold",
        color:"#FFFFFF",
        fontSize:normalize(14),
    },
    singleBtn:{
        flexDirection:"row",
        width:normalize(338),
        height:normalize(40,735),
        backgroundColor:theme.pink,
        borderRadius:20,
        paddingHorizontal:normalize(10),
        justifyContent:"space-between",
        alignItems:"center",
        
    },
    singlebtnText:{
        fontFamily:"OpenSansCondensedBold",
        fontSize:normalize(14),
        color:theme.red,
    },
    regForm:{
        width:normalize(338),
        marginTop:normalize(43,735),
    },
    inputView: {
        backgroundColor:theme.pink,
        borderRadius: normalize(30),
        height: normalize(40),
        width:normalize(164),
        justifyContent:"center",
        marginBottom:normalize(10,735)
    },
    TextInput: {
        width: "100%",
        height: "100%",
        paddingLeft: normalize(20),
        fontFamily:"OpenSansCondensedBold",
        color:"#FFFFFF",
        fontSize:normalize(14),
    },
    twoInputView:{
        flexDirection:"row",
        justifyContent:"space-between",
    },
    activityIndicatorStyle: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
    
});
export default styles;