export default {
    "IT": {
        "Sei un progettista e vuoi accedere a più contenuti? Modifica il tuo status ed effettua un upgrade, potrai ottenere la documentazione e gli strumenti extra a te riservati.": "Modifica il tuo status ed effettua un upgrade, \n potrai ottenere la documentazione e gli strumenti extra a te riservati.",
        "Cambia password": "Cambia password",
        "Salva": "Salva",
        "Dati personali": "Dati personali",
        "0": "Privato",
        "1": "Tecnico professionista",
        "2": "Agente Amonn",
        "3": "Cliente Amonn",
        "update": "Aggiornare!"
    },
    "DE": {
        "Sei un progettista e vuoi accedere a più contenuti? Modifica il tuo status ed effettua un upgrade, potrai ottenere la documentazione e gli strumenti extra a te riservati.": "Sie sind Projektplaner und möchten Zugang zu zusätzlichen Inhalten? \n Ändern Sie Ihren Status und führen Sie ein Upgrade durch.\n Sie erhalten Zugang zu vorbehaltenen Informationen und Tools.",
        "Cambia password": "Passwort ändern",
        "Salva": "Speichern",
        "Dati personali": "Personenbezogene Daten",
        "0": "Privatkunde",
        "1": "Professioneller Techniker",
        "2": "Amonn Außendienstmitarbeiter",
        "3": "Cliente Amonn",
        "update": "Aktualisieren!"

    },
    "ES": {
        "Sei un progettista e vuoi accedere a più contenuti? Modifica il tuo status ed effettua un upgrade, potrai ottenere la documentazione e gli strumenti extra a te riservati.": "¿Es usted diseñador y quiere acceder a más contenidos? \n Si cambias de estado y te actualizas, obtendrás la documentación y \n las herramientas adicionales reservadas para ti.",
        "Cambia password": "Cambia la contraseña",
        "Salva": "Guarda",
        "Dati personali": "Datos personales",
        "0": "Privado",
        "1": "Técnico profesional",
        "2": "Agente Amonn",
        "3": "Cliente Amonn",
        "update": "Actualizar!"

    },
    "EN": {
        "Sei un progettista e vuoi accedere a più contenuti? Modifica il tuo status ed effettua un upgrade, potrai ottenere la documentazione e gli strumenti extra a te riservati.": "Are you a planner and want access to more content? \n Upgrade your status, you will get access to extra \n documentation and tools reserved for you.",
        "Cambia password": "Change password",
        "Salva": "Save",
        "Dati personali": "Personal data",
        "0": "Private",
        "1": "Professional technician",
        "2": "Amonn Agent",
        "3": "Customer Amonn",
        "update": "Update!"

    },

}