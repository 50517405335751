export default{
    "IT":{
        "INVIA":"INVIA",
        "Hai dimenticato la password?":"Hai dimenticato la password?",
        "Register":"Registrati",


    },
    "DE":{
        "INVIA":"ANMELDUNG",
        "Hai dimenticato la password?":"Ändere das Passwort?",
        "Register":"Registrieren",

    },
    "ES":{
        "INVIA":"ACCESO",
        "Hai dimenticato la password?":"Cambia la contraseña?",
        "Register":"Regístrate",

    },
    "EN":{
        "INVIA":"LOGIN",
        "Hai dimenticato la password?":"Change Password?",
        "Register":"Register",

    }
}