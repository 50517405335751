import React, { useState, useEffect } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from './styles';
import Alert from '../Alert/Alert';
import axios from 'axios';
import { normalize } from '../../Helpers/Normalizer';
const BottomTab = ({ language, productImage, productTitle, product_line, token }) => {
  const [addfav, setaddfav] = useState(false);
  const itemcart = {
    title: productTitle,
    image: productImage,
    product_line: product_line,
  }
  const trans = {
    "IT": {
      "Added to Favourites": "Aggiunto ai preferiti"
    },
    "EN": {
      "Added to Favourites": "Added to Favourites"
    },
    "DE": {
      "Added to Favourites": "Zu Favoriten hinzugefügt"
    },
    "ES": {
      "Added to Favourites": "Añadido a favoritos"
    },
  }
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // {"name":"hello1","product_line":"stufex","image_url":"https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Conditional_Operator"}

  useEffect(() => {
    // if(addfav==false){
    AsyncStorage.getItem('cart').then((datacart) => {
      if (datacart !== null) {
        const cart = JSON.parse(datacart);
        for (var index = 0; index < cart.length; index++) {
          var product = cart[index];
          if (product.title == itemcart.title) {
            setaddfav(true);
            break;
          }
        }
      }
    })
      .catch((err) => {
        console.log(err);
      })
    // }
  }, [addfav]);
  const addRemoteFav = async () => {
    axios(`https://myadmin.myamonn.com/fav/`,
      {

        method: "POST",
        headers: { Authorization: `Token ${token}` },
        data: { "name": `${itemcart.title}`, "product_line": `${itemcart.product_line}`, "image_url": `${itemcart.image}` }
      })
      .then(function (response) {
        console.log(response, "mango");
        setaddfav(true);
        setLoading(false);
        setAlert(true);
        setAlertMessage(trans[language]["Added to Favourites"]);

      }
      )
      .catch(function (error) {
        console.log(error, "apple");
        setaddfav(true);
        setLoading(false);
        setAlert(true);
        setAlertMessage("Added to Local Favourites");
      })
  }
  const removeRemoteFav = async () => {
    axios(`https://myadmin.myamonn.com/fav/${itemcart.title}/`,
      {
        method: "DELETE",
        headers: { Authorization: `Token ${token}` },
        // data:{"name":`${itemcart.title}`,"product_line":`${itemcart.product_line}`,"image_url":`${itemcart.image}`}
      })
      .then(function (response) {
        console.log(response, "mango");
        setaddfav(false);
        setLoading(false);
        setAlert(true);
        setAlertMessage("Removed from Favourites");

      }
      )
      .catch(function (error) {
        console.log(error, "apple");
        setaddfav(false);
        setLoading(false);
        setAlert(true);
        setAlertMessage("Removed from Local Favourites");
      })
  }
  const onClickAddFav = () => {
    if (addfav == false) {
      setLoading(true);
      AsyncStorage.getItem('cart').then((datacart) => {
        if (datacart !== null) {
          // We have data!!
          const cart = JSON.parse(datacart)
          cart.push(itemcart)
          AsyncStorage.setItem('cart', JSON.stringify(cart));
        }
        else {
          const cart = []
          cart.push(itemcart)
          AsyncStorage.setItem('cart', JSON.stringify(cart));
        }
      }).then(async () => {
        await addRemoteFav();
      })
        .catch((err) => {
          console.log(err);
        })
    }
  }
  const onClickRemoveFav = () => {
    AsyncStorage.getItem('cart').then((datacart) => {
      if (datacart !== null) {
        // We have data!!
        const cart = JSON.parse(datacart)
        for (var index = 0; index < cart.length; ++index) {
          var product = cart[index];
          if (product.title == itemcart.title) {
            console.log(product.title, itemcart.title);
            console.log(cart.length);
            cart.splice(index, 1);
            setaddfav(false);
            AsyncStorage.setItem('cart', JSON.stringify(cart));
            console.log(cart.length);
            break;
          }
        }
      }
      setAlert(true);
      setAlertMessage("Removed From Favourites")
    })
      .then(async () => await removeRemoteFav())
      .catch((err) => {
        console.log(err);
      })
  }
  const alerting = () => {
    setAlert(false);
    setAlertMessage("");

  }
  return (
    <View style={styles.bottomTab}>
      {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
      <TouchableOpacity style={styles.photoContainer} >
        <Image style={styles.photo} source={{ uri: productImage }} />
      </TouchableOpacity>
      <View style={styles.productDetail}>
        {loading ?
          <Image
            style={{ width: normalize(50), height: normalize(50) }}
            source={require("../../../assets/MyAmonn_loading_red.gif")} />
          : [!addfav ? (<TouchableOpacity
            onPress={onClickAddFav}
          >
            <Image style={styles.fav} source={require("../../../assets/myamonn-icons/favorites.png")} />
          </TouchableOpacity>) :
            (
              <TouchableOpacity
                onPress={onClickRemoveFav}
              >
                <Image style={styles.fav} source={require("../../../assets/myamonn-icons/check.png")} />
              </TouchableOpacity>
            )]}
        <Text style={styles.prdCat}>{product_line.charAt(0).toUpperCase() + product_line.slice(1)}</Text>
        <Text style={styles.prdTitle}>{productTitle}</Text>
      </View>
    </View>
  );

};
export default BottomTab;

