import React,{useEffect,useRef,useState} from "react";
import {Text,TouchableOpacity} from 'react-native';
import styles from "./styles";

const PreProductSolButton=({text})=>{
    
    useEffect(()=>{
    },[]);
    return(
        <TouchableOpacity style={styles.solBtn}>
            <Text style={styles.soltxt}>{text}</Text>
        </TouchableOpacity>
    );
}
export default PreProductSolButton;