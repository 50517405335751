import React,{useState,useEffect}from 'react';
import {View,Text,Image,Dimensions,SafeAreaView,TouchableOpacity} from "react-native";
import { FlatList } from 'react-native-gesture-handler';
import slides from '../Onboarding/slides';
import ProductButton from '../ProductButton/ProductButton';
import styles from './styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import { normalize } from 'react-native-responsive-fontsize';
import PreProductSolButton from '../PreProductSolButton/PreProductSolButton';
import SolutionsDropdown from '../SolutionsDropdown/SolutionsDropdown';
import { getSolutions } from '../../Helpers/getSolutions';
import { normalize } from '../../Helpers/Normalizer';
import Product_Filter_Translation from './Product_Filter_Translation';

const ProductFilterContent=({doFilter,openFilter,language,_filter})=>{
    const [productLine,setProductLine]=useState("");
    const [solutions,setSolutions]=useState([]);
    const [selectedDropdownName,setSelectedDropdownName]=useState("nothing");
    const [filter,setFilter]=useState(_filter);

    useEffect(()=>{
        console.log(filter,"apple");
        // doFilter(filter);
    },[productLine,solutions,selectedDropdownName,filter])
    return(
        <SafeAreaView style={
            {
                height: normalize(500),
            }}>
            <View
                style={{
                    backgroundColor: 'white',
                    paddingBottom:0,
                    height: normalize(500),
                }}>
                <View style={styles.body}>
                    <View style={styles.bodyleft}>
                        <Text
                            style={{
                                fontFamily:"OpenSansCondensedBold",
                                fontSize:normalize(16,735),
                                color:"#333333",
                                alignSelf:'center'
                            }}
                        >{Product_Filter_Translation[language]["Linea prodotto"]}</Text>
                        <FlatList
                            data={slides}
                            renderItem={({item ,index}) => (
                                <View style={{paddingTop:normalize(10,735)}}>
                                    <ProductButton 
                                        width={124}
                                        productName={item.title.toUpperCase()} 
                                        color={Object.keys(filter).includes(item.title)?"red":"pink"}
                                        onPress={async ()=>{
                                            if(!Object.keys(filter).includes(item.title)){
                                                let obj={};
                                                obj[item.title]=[];
                                                setFilter(obj);
                                            }
                                            doFilter(filter);
                                            setProductLine(productLine==item.title?"":item.title);
                                            setSolutions(await getSolutions(item.title.toLowerCase()));
                                            setSelectedDropdownName("");
                                        }}
                                        onLongPress={()=>{
                                            if(Object.keys(filter).includes(item.title)){
                                                setProductLine("");
                                                let obj={...filter};
                                                delete obj[item.title];
                                                setFilter(obj);
                                                
                                            }
                                        }}
                                    />
                                </View>
                            )}
                        
                        />
                    
                    </View>
                    <View style={styles.bodyright}>
                        <Text
                            style={{
                                fontFamily:"OpenSansCondensedBold",
                                fontSize:normalize(16),
                                color:"#333333",
                                alignSelf:'center'
                            }}
                        >{Product_Filter_Translation[language]["Soluzione"]}</Text>
                        {productLine==""?(<PreProductSolButton text={Product_Filter_Translation[language]["Please select product line"]}/>):(
                        <FlatList
                            // data={solutions}
                            data={
                                solutions.filter((thing, index, self) =>
                                   index === self.findIndex((t) => (
                                       t.solution === thing.solution
                                   ))
                                   )
                           }
                            renderItem={({item ,index}) => {
                                return(
                                <View>
                                    <SolutionsDropdown 
                                        name={item.solution} 
                                        data={solutions.filter(p=>p.solution==item.solution)}
                                        onPress={()=>{
                                            setSelectedDropdownName(selectedDropdownName==item.solution?"":item.solution);
                                        }}
                                        selectedDropdownName={selectedDropdownName}
                                        filter={filter}
                                        getFilter={(param)=>{
                                            setFilter(param);
                                            // console.log(filter);
                                        }}
                                        language={language}
                                        productLine={productLine}
                                    />
                                </View>)
                            }}
                        />
                        )}
                    </View>
                </View>
                <View style={styles.footer}>
                <TouchableOpacity
                    onPress={()=>{
                        setProductLine("");
                        setSolutions([]);
                        setSelectedDropdownName("nothing");
                        setFilter({"nothing":[]});
                        }}>
                        <Text style={styles.Registration_button}>{Product_Filter_Translation[language]["Clear filters"]}</Text>
                </TouchableOpacity>
                    <ProductButton 
                        width={124}
                        productName={Product_Filter_Translation[language]["CERCA"].toUpperCase()}
                        color={"red"}
                        onPress={()=>{
                            doFilter(filter);
                            openFilter();
                        }}
                    />
                </View>
            </View>    
            {/* <View 
                onTouchEnd={()=>{
                    openFilter.current.snapTo(0);
                }}
                style={{
                    borderBottomWidth:2,
                    borderBottomColor:"red",
                    alignItems:"center",
                    justifyContent:"flex-end",
                    backgroundColor:'rgba(52, 52, 52, 0.8)',
                    height: normalize(500),
                    }}>
                <Icon
                    name="reorder-horizontal"
                    color="red"
                    size={25}/>
            </View> */}
            
        </SafeAreaView>
    );
}
export default ProductFilterContent;