import { StyleSheet,Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { normalize } from "../../Helpers/Normalizer";
const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    container:{
        flexDirection:"row",
        justifyContent:"space-between",
        marginHorizontal:width>786?normalize(20):normalize(10),
    },
    singleBtn:{
        flexDirection:"row",
        width:width>786?normalize(260):normalize(160),
        height:normalize(50,735),
        backgroundColor:"#D90000",
        borderTopLeftRadius:normalize(40),
        borderTopRightRadius:normalize(40),
        justifyContent:"center",
        alignItems:"center"
    },
    singlebtnText:{
        color:"white",
        textAlign:"center",
        justifyContent:"center",
        fontFamily:"OpenSansCondensedBold",
        fontSize:normalize(16)
    },
    logo:{
        height:normalize(36.33),
        // width:width-normalize(184),
        width:normalize(230,735),
        marginVertical:normalize(16.67),
    },
});
export default  styles;