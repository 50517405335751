export default[
    {
        id:'1',
        title:'TDS',
        prefix:"TDS",
        filter:{
            IT:[
                "Italiano_IT",
                "Tedesco_DE",
                "Inglese_EN",
                "Spagnolo_ES",
                "Francese_FR",
                "Sloveno_SL"],
            EN:["Italian_IT","German_DE","English_EN","Spanish_ES","French_FR","Slovenian_SL"],
            DE:["Italienisch_IT","Deutsch_DE","Englisch_EN","Spanisch_ES","Französisch_FR","Slowenisch_SL"],
            ES:["italiano_IT","alemán_DE","inglés_EN","español_ES","francés_FR","esloveno_SL"]
            
        }

    },
    {
        id:'2',
        title:'SDS',
        prefix:"SDS",
        filter:{
            IT:[
                "Italiano_IT",
                "Tedesco_DE",
                "Inglese_EN",
                "Spagnolo_ES",
                "Francese_FR",
                "Sloveno_SL"],
            EN:["Italian_IT","German_DE","English_EN","Spanish_ES","French_FR","Slovenian_SL"],
            DE:["Italienisch_IT","Deutsch_DE","Englisch_EN","Spanisch_ES","Französisch_FR","Slowenisch_SL"],
            ES:["italiano_IT","alemán_DE","inglés_EN","español_ES","francés_FR","esloveno_SL"]
            
        }
    },
    {
        id:'3',
        title:'CERTIFICATI',
        prefix:["ETA_","FT_","OM_","RDC_","RDP_","RDV_"]
    },
    {
        id:'4',
        title:'VOCE DI CAPITOLATO',
        prefix:"VC_"
    },
    {
        id:'5',
        title:'DEPLIANT',
        prefix:'Depliant'
    },
    {
        id:'6',
        title:'CATALOGO',
        prefix:'Catalogo',
    },
    {
        id:'7',
        title:'LISTINO',
        prefix:'LISTINO'
    },
]