export default {
    "IT": {
        "Benvenuto su MyAmonn": "Benvenuto su MyAmonn.",
        "nizia a creare la tua bacheca personale": "nizia a creare la tua bacheca personale:",
        "Ricerca": "Ricerca PRODOTTI",
        "I miei preferiti": "I miei preferiti",
        "Avvisi": "Avvisi",
        "Contattaci": "Contattaci",
        "Prodotti": "Prodotti",
    },
    "EN": {
        "Benvenuto su MyAmonn": "Welcome to MyAmonn.",
        "nizia a creare la tua bacheca personale": "Start creating your personal dashboard:",
        "Ricerca": "Search PRODUCTS",
        "I miei preferiti": "My Favourites",
        "Avvisi": "Alert",
        "Contattaci": "Contact us",
        "Prodotti": "Products",
    },
    "DE": {
        "Benvenuto su MyAmonn": "Willkommen auf MyAmonn.",
        "nizia a creare la tua bacheca personale": "Beginnen Sie mit der Erstellung Ihres persönlichen Dashboards:",
        "Ricerca": "Suche PRODUKTE",
        "I miei preferiti": "Favoriten",
        "Avvisi": "Mitteilungen",
        "Contattaci": "Kontaktieren Sie uns",
        "Prodotti": "Produkte"

    },
    "ES": {
        "Benvenuto su MyAmonn": "Bienvenido a MyAmonn.",
        "nizia a creare la tua bacheca personale": "Empieza a crear tu area personal:",
        "Ricerca": "Búsqueda",
        "I miei preferiti": "Mis favoritos",
        "Avvisi": "Avisos PRODUCTOS",
        "Contattaci": "Contáctanos",
        "Prodotti": "productos"

    },


}