import React, { useState, useEffect } from 'react';
import { View, Text, Image, Dimensions, TouchableOpacity } from "react-native";
import { FlatList } from 'react-native-gesture-handler';
import slides from '../../Components/Onboarding/slides';
import ProductButton from '../../Components/ProductButton/ProductButton';
import styles from './styles';
// import { normalize } from 'react-native-responsive-fontsize';
import * as theme from "../../Theme";
import SearchBar from '../../Components/SearchBar/SearchBar';
import { ScrollView } from 'react-native-gesture-handler';
import PreProductSolButton from '../../Components/PreProductSolButton/PreProductSolButton';
import SolutionsDropdown from '../../Components/SolutionsDropdown/SolutionsDropdown';
import { getSolutions } from '../../Helpers/getSolutions';
import { normalize } from '../../Helpers/Normalizer';
import { Linking } from 'react-native';
// import { normalize } from 'react-native-responsive-fontsize';
import Dashboard_Translation from './Dashboard_Translation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Product_Filter_Translation from '../../Components/BottomSheetContent/Product_Filter_Translation';
const width = Dimensions.get('window').width;

const Dashboard = ({ route, navigation }) => {
    const [productLine, setProductLine] = useState("");
    const [solutions, setSolutions] = useState([]);
    const [selectedDropdownName, setSelectedDropdownName] = useState("nothing");
    const [filter, setFilter] = useState({ "nothing": [] });
    const [query, setQuery] = useState("");
    const [language, setLanguage] = useState(route.params.language);
    const search = () => {
        navigation.navigate("AllProducts", { filter: filter, query: query })
    }
    useEffect(() => {
        const getLanguage = () => {
            AsyncStorage.getItem("language").then((response) => {
                if (response != null) {
                    setLanguage(JSON.parse(response));
                    //   console.log(language);
                }
            })
        }
        getLanguage();
        // console.log(filter);
        // console.log(language);
    })
    useEffect(() => {
        setProductLine("");
        setSolutions([]);
        setSelectedDropdownName("nothing");
        setFilter({ "nothing": [] });
        setQuery("");
        setLanguage(route.params.language);
    }, [route])
    useEffect(() => {
        AsyncStorage.getItem("token").then((response) => {
            if (JSON.parse(response) == null) {
                console.log(response, route)
                navigation.navigate("Login", { route: route });
            };
        })
    }, [route]);
    return (
        <ScrollView style={styles.container}>
            <View style={styles.initialWindow}>
                <View style={styles.messageWindow}>
                    <Text style={styles.message}>
                        {"Sito in aggiornamento, per eventuale documentazione mancante contattateci a"}
                    </Text>
                    <Text
                        onPress={() => Linking.openURL(`mailto:info@amonncolor.com`)}
                        style={[styles.message, { textDecorationLine: 'underline' }]}>
                        {"info@amonncolor.com"}
                    </Text>
                </View>
            </View>

            <View style={styles.header}>
                <Text
                    style={{
                        fontFamily: "OpenSansCondensedBold",
                        fontSize: normalize(16),
                        color: "#333333",
                        marginTop: normalize(30, 735),
                    }}
                >{Dashboard_Translation[language]["Benvenuto su MyAmonn"]}</Text>
                <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
                <Text
                    style={{
                        fontFamily: "OpenSansCondensedBold",
                        fontSize: normalize(16, 735),
                        color: "#333333"
                    }}
                >{Dashboard_Translation[language]["nizia a creare la tua bacheca personale"]}</Text>
                {productLine !== "" ?
                    <SearchBar width={257} onChangeText={(query) => setQuery(query)} language={language} dashboard={true} search={search} />
                    : null}
            </View>
            <View style={styles.body}>
                <View style={styles.bodyleft}>
                    <Text
                        style={{
                            fontFamily: "OpenSansCondensedBold",
                            fontSize: normalize(16, 735),
                            color: "#333333",
                            alignSelf: 'center'
                        }}
                    >{Product_Filter_Translation[language]["Linea prodotto"]}</Text>
                    <FlatList
                        data={slides}
                        renderItem={({ item, index }) => (
                            <View style={{ paddingTop: normalize(10, 735) }}>
                                <ProductButton
                                    width={124}
                                    productName={item.title.toUpperCase()}
                                    color={productLine == (item.title) ? "red" : "pink"}
                                    onPress={async () => {
                                        if (!Object.keys(filter).includes(item.title)) {
                                            let obj = {};
                                            obj[item.title] = [];
                                            setFilter(obj);
                                            // setSelectedDropdownName("");
                                        }
                                        setProductLine(productLine == item.title ? "" : item.title);
                                        setSolutions(await getSolutions(item.title.toLowerCase()));
                                        setSelectedDropdownName("");

                                    }}
                                    onLongPress={() => {
                                        if (Object.keys(filter).includes(item.title)) {
                                            setProductLine("");
                                            let obj = { ...filter };
                                            delete obj[item.title];
                                            setFilter(obj);

                                        }
                                    }}
                                />
                            </View>
                        )}

                    />

                </View>
                <View style={styles.bodyright}>
                    <Text
                        style={{
                            fontFamily: "OpenSansCondensedBold",
                            fontSize: normalize(16, 735),
                            color: "#333333",
                            alignSelf: 'center'
                        }}
                    >{Product_Filter_Translation[language]["Soluzione"]}</Text>
                    {productLine == "" ? (<PreProductSolButton text={Product_Filter_Translation[language]["Please select product line"]} />) : (
                        <FlatList
                            // data={solutions}
                            data={
                                solutions.filter((thing, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.solution === thing.solution
                                    ))
                                )
                            }
                            renderItem={({ item, index }) => (
                                <View>
                                    <SolutionsDropdown
                                        name={item.solution}
                                        data={solutions.filter(p => p.solution == item.solution)}
                                        onPress={() => {
                                            setSelectedDropdownName(selectedDropdownName == item.solution ? "" : item.solution);
                                        }}
                                        selectedDropdownName={selectedDropdownName}
                                        filter={filter}
                                        getFilter={(param) => {
                                            setFilter(param);
                                            // console.log(filter);
                                        }}
                                        productLine={productLine}
                                        language={language}
                                    />
                                </View>
                            )}
                        />
                    )}
                </View>
            </View>

            <View style={styles.footer}>
                {productLine != "" ?
                    <TouchableOpacity
                        onPress={() => {
                            // setFilter({"nothing":[]});
                            setProductLine("");
                            setSolutions([]);
                            setSelectedDropdownName("nothing");
                            setFilter({ "nothing": [] });

                        }}>
                        <Text style={styles.Registration_button}>{Product_Filter_Translation[language]["Clear filters"]}</Text>
                    </TouchableOpacity> : null}
                <ProductButton
                    width={productLine != "" ? 124 : 290}
                    productName={productLine != "" ? Product_Filter_Translation[language]["CERCA"].toUpperCase() : Dashboard_Translation[language]["selection"]}
                    color={productLine != "" ? "red" : "pink"}
                    onPress={() => {
                        if (productLine != "")
                            navigation.navigate("AllProducts", { filter: filter, query: query })
                    }}
                />
            </View>
            {productLine != "" ?
                <View style={{
                    alignSelf: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    backgroundColor: theme.pink,
                    width: normalize(290),
                    height: normalize(40),
                    // marginTop:normalize(40),
                    borderRadius: normalize(20),
                }}>
                    <Text
                        style={{
                            fontFamily: "OpenSansCondensedBold",
                            fontSize: normalize(16),
                            color: theme.red,
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >{Dashboard_Translation[language]["message"][(Object.keys(filter)[0]).toLowerCase()]}</Text>

                </View> : null}


        </ScrollView>
    );
};

export default Dashboard;
