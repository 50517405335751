import { StyleSheet,Dimensions,StatusBar, Platform} from "react-native";
// import { normalize } from "react-native-responsive-fontsize";
import { normalize } from "../../Helpers/Normalizer";
const screenHeight = Dimensions.get('screen').height;
const windowHeight = Dimensions.get('window').height;
const height = screenHeight - (windowHeight+getStatusBarHeight());
// const height=windowHeight-navbarHeight;
const width = Dimensions.get('window').width;
const iosHeight = Dimensions.get('window').height;
import { getStatusBarHeight } from "react-native-status-bar-height";
import * as theme from "../../Theme";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent',
        alignItems:'center',
    },
    body:{
        flex: 1,
        backgroundColor: theme.red,
        alignItems: "center",
        justifyContent: "center",
        height:width>786?height-normalize(240):height-normalize(10),
        paddingVertical:width>786?normalize(40):normalize(140),
        // marginHorizontal:width>786?normalize(120):normalize(10),
        marginVertical:width>786?normalize(120):normalize(40),
        borderBottomLeftRadius:width>786?normalize(200):normalize(150),
        borderBottomRightRadius:width>786?normalize(200):normalize(150),
        borderTopRightRadius:width>786?normalize(200):normalize(150),
        width:width>786?width-normalize(240):width-normalize(20),
        // flex: 1,
        // backgroundColor: theme.red,
        // alignItems: "center",
        // justifyContent: "space-between",
        // height:height-normalize(240),
        // paddingVertical:normalize(40),
        // marginHorizontal:normalize(120),
        // marginVertical:normalize(120),
        // borderBottomLeftRadius:normalize(200),
        // borderBottomRightRadius:normalize(200),
        // borderTopRightRadius:normalize(200),
        // width:width-normalize(240),
        // elevation:5,
        // flex:1,
        // width:normalize(373),
        // backgroundColor: theme.red,
        // alignItems: "center",
        // height:height-normalize(240),
        // // height:(iosHeight-getStatusBarHeight())-normalize(120),
        // marginHorizontal:normalize(20),
        // marginVertical:normalize(40,735),
        // borderBottomLeftRadius:100,
        // borderBottomRightRadius:100,
        // borderTopRightRadius:100,
        elevation:5,
    },
    circle: {
        position:"absolute",
        height: normalize(100),
        width: normalize(100),
        borderRadius: 50,
        elevation:50,
        top:normalize(70),
        zIndex:1000,
    },
    avatar: {
        height: normalize(100),
        width: normalize(100),
    },
    logoView: {
        marginTop:normalize(66,735),
        width:normalize(159),
        height:normalize(25,735),
        justifyContent:"center",
        alignItems:"center"
    },
    logo:{
        width:normalize(159),
        height:normalize(25,735),
        resizeMode:"contain"
    },
    radixText:{
        marginTop:normalize(119,735),
        fontFamily:"OpenSansCondensedBold",
        fontSize:normalize(17),
        color:"white",
    },
    inputView: {
        backgroundColor: theme.pink,
        borderRadius: normalize(30),
        width:"70%",
        height: normalize(40,735),
        marginTop:normalize(10,735),
        alignItems: "center",
        justifyContent:"center",
    },

    TextInput: {
        height: normalize(50,735),
        width:"100%",
        flex: 1,
        padding: normalize(10,735),
        textAlign:"center",
        color:"white",
        fontFamily:"OpenSansCondensedBold",
        fontSize:normalize(14,735),
  },
  RgBtn: {
    width: "35%",
    borderRadius: normalize(30),
    height: normalize(40,735),
    alignItems: "center",
    justifyContent: "center",
    marginTop: normalize(20),
    backgroundColor: "white",
  },
  RgText:{
      color:"#D90000",
      fontFamily:"OpenSansCondensedBold",
      fontSize:normalize(14,735),
  },
  bottomText:{
      marginTop:normalize(83,735),
      color:"white",
      textAlign:"center",
      fontFamily:"OpenSansCondensedBold",
      fontSize:normalize(11,735),
      lineHeight:normalize(13,735),
      
  },
  activityIndicatorStyle: {
    flex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default styles;