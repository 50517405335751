import React ,{ useEffect, useRef, useState }from 'react';
import {View,Dimensions,Text,TouchableHighlight,FlatList,TouchableOpacity,Image, ActivityIndicator} from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import axios from 'axios';
import styles from './styles';
// import { normalize } from 'react-native-responsive-fontsize';
import BottomButtons from '../../Components/BottomButtons/BottomButtons';
// import Swipeout from 'react-native-swipeout';
import TextTicker from 'react-native-text-ticker';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { normalize } from '../../Helpers/Normalizer';
import { RFValue } from 'react-native-responsive-fontsize';
import * as theme from "../../Theme";
import { deleteFile } from '../../Helpers/downloadDocument';
import AsyncStorage from '@react-native-async-storage/async-storage';

const { width, height } = Dimensions.get('window');

const Notifications = ({route,navigation}) => {
    const [type,setType]=useState("Unread");
    const [notifications,setNotifications]=useState(route.params.notifications);
    useEffect(()=>{
        // console.log(notifications);
    },[notifications]);
    useEffect(() => {
        AsyncStorage.getItem("token").then((response)=>{
            if(JSON.parse(response)==null){
                console.log(response,route)
                navigation.navigate("Login",{route:route});
            };
            })
    },[route]);
    const onSelectButton = (button) => {
        if (button=="myproduct") {
            // console.log("Unread");
            setType("Unread");
        }
        else{
            console.log("all");
            setType("all");
        }
      };
      var swipeoutBtns = [
        {
            
            backgroundColor:"transparent",
            component:<View style={{ flex:1,justifyContent:"center",alignItems:"center"}}><Icon
                name={"delete"}
                color={"grey"}
                size={normalize(25)}
                /></View>,
        },
      ]
    return (
        <View style={styles.container}>
            <FlatList
                // data={["Discover the new Amotherm Brick WB","Discover the new Amotherm Brick WB","Discover the new Amotherm Brick WB"]}
                data={notifications}
                contentContainerStyle={{
                    justifyContent:"center",
                    alignItems:'center',
                    marginVertical:normalize(7,735)
                }}
                renderItem={({item ,index}) => (
                    // <Swipeout 	
                    //     backgroundColor="transparent"
                    //     right={swipeoutBtns}>
                        <TouchableHighlight
                            underlayColor="none"
                            onPress={()=>{
                                item.notification_type=="0"?
                                navigation.navigate("MyProducts",{filter:[]}):
                                [item.notification_type=="1"?
                                    navigation.navigate("BIM"):
                                    [item.notification_type=="2"?
                                        navigation.navigate("RadixLogin"):
                                        navigation.navigate("MyProducts",{filter:[]})
                                    ]
                                ]
                                
                            }}
                        
                        >
                            <View 
                                style={{ 
                                    backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
                                    width:width>786?normalize(664):normalize(320),
                                    height:normalize(80,735),
                                    justifyContent:"center",
                                    borderLeftWidth:type=="Unread"?2:null,
                                    borderLeftColor:type=="Unread"?"#FFB300":null
                                    // alignContent:"center",
                                }}
                            >   
                            <View style={{flexDirection:"row",marginHorizontal:normalize(20)}}>
                                {item.notification_type=="0"?
                                <View style={{justifyContent:"center"}}>
                                    <Image
                                        style={{
                                            width:normalize(22),
                                            height:normalize(22),
                                            resizeMode:"contain"}}
                                        source={require("../../../assets/myamonn-icons/video-library.png")}/>
                                </View>:
                                [item.notification_type=="1"?
                                    <View style={{justifyContent:"center"}}>
                                        <Image
                                            style={{
                                                width:normalize(22),
                                                height:normalize(22),
                                                resizeMode:"contain"}} 
                                            source={require("../../../assets/myamonn-icons/document-library.png")}/>
                                    </View>:
                                    [item.notification_type=="2"?
                                        <View style={{justifyContent:"center"}}>
                                            <Image 
                                                style={{
                                                    width:normalize(22),
                                                    height:normalize(22),
                                                    resizeMode:"contain"}}
                                                source={require("../../../assets/myamonn-icons/external-link.png")}/>
                                        </View>:
                                        <View style={{justifyContent:"center"}}>
                                            <Image 
                                                style={{
                                                    width:normalize(22),
                                                    height:normalize(22),
                                                    resizeMode:"contain"}}
                                                source={require("../../../assets/myamonn-icons/paintBucket.png")}/>
                                        </View>
                                    ]
                                ]
                                }
                                <View 
                                    style={{
                                        width:width>786?normalize(600):normalize(295),
                                    }}
                            

                                >
                                <Text style={{marginLeft:normalize(15),fontFamily:"OpenSansCondensedLightItalic",fontSize:normalize(14)}}>
                                    {item.created_at}
                                </Text>
                                <TextTicker
                                    duration={3000}
                                    style={{
                                        color:"#333333",
                                        fontFamily:"OpenSansCondensedBold",
                                        fontSize:normalize(16),
                                        marginLeft:normalize(15),
                                        
                                    }}>
                                        {item.message}
                                </TextTicker>
                                </View>
                            </View>
                            </View>
                        </TouchableHighlight>
                    // </Swipeout>
                )}
                keyExtractor={(item,index)=>index.toString()}
                numColumns={1}
                key={"3"} // if you wan

            />
            <BottomButtons
                Buttons={1}
                onSelectButton={onSelectButton}
                // selectionMode={"myproduct"}
                buttonText1={"Notifications"}
                // buttonText2={"All Notifications"}
            />
            <View
                style={{
                    flexDirection:"row",
                    justifyContent:"flex-end",
                    paddingTop:normalize(50),
                    paddingBottom:normalize(20),
                    paddingHorizontal:normalize(20),
                    backgroundColor:"white",
                }}
                >
                <TouchableOpacity
                    onPress={()=>{
                        navigation.navigate("Dashboard");
                    }}>
                    <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
                </TouchableOpacity>                
            
            </View>

        </View>
    )

}
export default Notifications;