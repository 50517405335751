import React, { useRef, useState } from "react";
import {View,TouchableOpacity,TextInput,Image} from "react-native";
import styles from "./styles";
import * as theme from "../../Theme";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import { normalize } from "react-native-responsive-fontsize";
import Drawer_Translation from "../DrawerContent/Drawer_Translation";
import { normalize } from "../../Helpers/Normalizer";

const SearchBar=({width,onChangeText,search,language,dashboard})=>{
    const [query,setQuery]=useState("");
    const input=useRef();
    return(
        <View style={styles(width).searchBtn}>
            <TextInput
                ref={input}
                placeholder={Drawer_Translation[language]["Ricerca"].toUpperCase()}
                placeholderTextColor={theme.red}
                onChangeText={(query) => {
                    setQuery(query);
                    onChangeText(query);
                }}
                style={styles(width).searchText}
            />
            <TouchableOpacity style={{position:"absolute",right:10}}
                onPress={
                    ()=>{
                        if(dashboard){
                            if(query!=""){
                                search();
                            }
                            else{
                                input.current.focus();
                            }
                        }
                    
                }}
                >
                {query==""?<Image style={{width:normalize(18),height:normalize(18)}}source={require("../../../assets/myamonn-icons/search.png")} />
                :<Icon
                    name="chevron-right"
                    color={theme.red}
                    size={normalize(25)}
                />}
            </TouchableOpacity>
        </View>
    );
}

export default SearchBar;