import React, { useEffect, useState, useRef } from 'react';
import {
    View,
    Text,
    ActivityIndicator,
    Image,
    ImageBackground, TouchableHighlight, Animated, Dimensions
} from "react-native";
import styles from './styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import BottomTab from '../../Components/BottomTab/BottomTab';
import BottomButtons from '../../Components/BottomButtons/BottomButtons';
import documents from './documents';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import { getDocuments } from '../../Helpers/downloadDocument';
import { downloadFile } from '../../Helpers/downloadDocument';
import AsyncStorage from '@react-native-async-storage/async-storage';
import productslist from './productslist';
import { normalize } from '../../Helpers/Normalizer';
// import { normalize } from 'react-native-responsive-fontsize';
import axios from 'axios';

// import { normalize } from "react-native-responsive-fontsize";
const { width, height } = Dimensions.get('window');
// orientation must fixed
const SCREEN_WIDTH = width < height ? width : height;
const SCREEN_HEIGHT = width > height ? width : height;
const Product = ({ route, navigation }) => {
    const [userRole, setUserRole] = useState(AsyncStorage.getItem('userRole').then((role) => { setUserRole(JSON.parse(role)) }))
    const [loading, setLoading] = useState(true);
    const [downloadedNow, setDownloadedNow] = useState("");
    const [shouldShowVideo, setShouldShowVideo] = useState(false);
    const [showDocuments, setShowDocuments] = useState(true);
    const [docs, setDocs] = useState([]);
    const [downloadedDoc, setDownloadedDoc] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const productFullName = route.params.name;
    const product_line = route.params.product_line;
    const [language, setLanguage] = useState("IT");
    const productName = productFullName.replace(/ /g, '_');
    const [token, setToken] = useState(null);
    const [docsFilterOpen, setDocsFilterOpen] = useState("");
    // const [documType,setDocumType]=useState("");
    useEffect(() => {
        AsyncStorage.getItem("token").then((response) => {
            if (JSON.parse(response) == null) {
                console.log(response, route)
                navigation.navigate("Login", { route: route });
            };
        })
    }, [route]);
    const [Filter, setFilter] = useState({
        "TDS": "",
        "SDS": "",
    });
    const trans = {
        "IT": {
            "Documenti": "Documenti",
            "TDS": "TDS",
            "SDS": "SDS",
            "LISTINO": "LISTINO",
            "VOCE DI CAPITOLATO": "VOCE DI CAPITOLATO",
            "DEPLIANT": "DEPLIANT",
            "CERTIFICATI": "CERTIFICATI"
        },
        "DE": {
            "Documenti": "Dokumente",
            "TDS": "TDS",
            "SDS": "SDS",
            "LISTINO": "Liste",
            "VOCE DI CAPITOLATO": "Technische Daten",
            "DEPLIANT": "Flugblatt",
            "CERTIFICATI": "ZERTIFIKATE"
        },
        "ES": {
            "Documenti": "Documentos",
            "TDS": "TDS",
            "SDS": "SDS",
            "LISTINO": "Lista",
            "VOCE DI CAPITOLATO": "Artículo de especificaciones",
            "DEPLIANT": "folleto",
            "CERTIFICATI": "CERTIFICADOS"
        },
        "EN": {
            "Documenti": "Documments",
            "TDS": "TDS",
            "SDS": "SDS",
            "LISTINO": "List",
            "VOCE DI CAPITOLATO": "Specifications item",
            "DEPLIANT": "leaflet",
            "CERTIFICATI": "CERTIFICATES"
        },
    }
    const onSelectButton = button => {
        if (button == "myproduct") {
            setShowDocuments(true);
            setShouldShowVideo(false);
        }
        else {
            setShowDocuments(false);
        }
    };
    const getToken = async () => {
        AsyncStorage.getItem('token').then((token) => {
            if (token != null) {
                console.log("token", token);
                setToken(JSON.parse(token));
            }
        })
            .then(() => {
            })
            .catch((err) => {
                alert(err)
            })
    };
    const checkLang = async () => {
        AsyncStorage.getItem('language').then((language) => {
            let lang = JSON.parse(language);
            console.log(lang);
            setLanguage(lang);
        })
            .catch((err) => {
                alert(err)
            })
    };
    const lodocuments = async () => {
        // , {

        //     headers: { Authorization: `Token ${token}` }
        //     }
        // axios.get('https://myadmin.myamonn.com/Documents/' + `(${productName})` + '/' + product_line)
        axios.get('https://myadmin.myamonn.com/Documents/' + productName + '/' + product_line )

            .then(function (response) {
                console.log(response.data);
                setDocs(response.data);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error, "apples");
            })
            .then(function () {
                // always executed
            });
    }
    const getDownloadedDocs = async () => {
        setDownloadedDoc(await getDocuments());
    }
    const lodata = async () => {
        setLoading(true);
        await getToken();
        await lodocuments();
        await checkLang();
        // getDownloadedDocs();


    }
    useEffect(() => {
        lodata().then((response) => { console.log(language, "zinger"); })


    }, [token, route]);

    return (
        <View style={styles.container}>
            <View style={loading ? { justifyContent: 'center', top: normalize(500 / 2) } : null}>
                {loading ?
                    <View style={{ position: "absolute", justifyContent: 'center', alignItems: "center", alignSelf: 'center' }}>
                        <Image
                            style={{ width: normalize(200), height: normalize(200) }}
                            source={require("../../../assets/MyAmonn_loading_red.gif")} />
                    </View> :
                    [showDocuments ?
                        <FlatList
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            // pagingEnabled
                            // bounces={true}
                            keyExtractor={(item) => item.id}
                            data={documents}
                            // snapToInterval={normalize(324)}
                            style={styles.horizontallist}
                            contentContainerStyle={styles.horizontalist}
                            renderItem={({ item, index }) => {
                                const type = item.prefix;
                                const sd = item.title
                                var docavl = false;
                                console.log(userRole);

                                if (item.title != "CERTIFICATI") {
                                    docavl = docs.filter(item => ((item.name).toLowerCase()).includes(type.toLowerCase())).length > 0
                                }
                                else {
                                    docavl = docs.filter(item => (type.some(v =>
                                        ((item.name).toLowerCase()).includes(v.toLowerCase()) &&
                                        ((item.name).toLowerCase()).includes(((route.params.solution_type).toLowerCase()).split(' ').join('_'))))).length > 0
                                    // docavl = docs.filter(item => (type.some(v => (item.name).includes(v)))).length > 0
                                }
                                if (userRole == 0 && item.title == "LISTINO") {
                                    docavl = false
                                }
                                if (docavl)
                                    return (
                                        <View style={[styles.pcontainer]}>
                                            {docsFilterOpen == "" || docsFilterOpen != item.title ?
                                                <TouchableOpacity
                                                    style={[styles.docCat, { width: width > 786 ? width / 7 : normalize(200), flexDirection: 'row' }]}
                                                    onPress={() => {
                                                        if (item.title == "SDS" || item.title == "TDS") {
                                                            console.log(item.title);
                                                            setDocsFilterOpen(item.title);
                                                        }
                                                    }}
                                                >

                                                    <View
                                                        style={[styles.docCat, { width: width > 786 ? (width / 7) - normalize(25) : normalize(200 - 25) }]}>
                                                        <Text style={[styles.docCatTitle, { textAlign: "center" }]}>{trans[language][item.title].toUpperCase()}</Text>
                                                    </View>
                                                    {item.title == "SDS" || item.title == "TDS" ?
                                                        <Icon
                                                            name={docsFilterOpen ? "chevron-up" : "chevron-down"}
                                                            color="white"
                                                            size={normalize(25)}
                                                        /> : null}
                                                </TouchableOpacity> :
                                                <View
                                                    style={{
                                                        height: normalize(200),
                                                        backgroundColor: "white",
                                                        position: "absolute",
                                                        elevation: 50,
                                                        zIndex: 1000,
                                                        width: width > 786 ? width / 7 : normalize(200),
                                                        borderWidth: 2,
                                                        borderColor: "#D90000",
                                                        borderRadius: normalize(25),
                                                        paddingBottom: normalize(12),
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        style={[styles.docCat, { width: width > 786 ? (width / 7) - normalize(5) : normalize(200 - 5) }]}
                                                        onPress={() => {
                                                            console.log(item.title);
                                                            setDocsFilterOpen("");
                                                        }}
                                                    >

                                                        <View
                                                            style={styles.docCat}>
                                                            <Text style={styles.docCatTitle}>{item.title}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <FlatList
                                                        // showsVerticalScrollIndicator={true}
                                                        data={
                                                            // item["filter"][language]
                                                            item["filter"][language].filter(item => docs.some(element => element.name.includes(item.split("_")[1]) && element.name.includes("TDS") || element.name.includes("SDS") ))
                                                        }
                                                        style={{
                                                            width: width > 786 ? (width / 7) - normalize(4) : normalize(200 - 4),
                                                            height: normalize(100),
                                                        }}
                                                        renderItem={({ item, index }) => (
                                                            <TouchableOpacity
                                                                style={{
                                                                    flexDirection: "row",
                                                                    width: width > 786 ? (width / 7) - normalize(8) : normalize(200 - 8),
                                                                    backgroundColor: Filter[type] == item ? "#D90000" : ((index + 1) % 2) != 0 ? "#F2F2F2" : "white",
                                                                    borderRadius: normalize(25),
                                                                    // borderWidth:0,
                                                                    height: normalize(40, 735),
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                    paddingHorizontal: normalize(10, 735),
                                                                    marginHorizontal: 2,
                                                                    marginVertical: 2
                                                                }}
                                                                onPress={() => {
                                                                    var obj = Filter;
                                                                    if (obj[type] == item) {
                                                                        obj[type] = "";
                                                                    }
                                                                    else {
                                                                        obj[type] = item;
                                                                    }
                                                                    setFilter(obj);
                                                                    setDocsFilterOpen(false);
                                                                    console.log(obj);
                                                                }}
                                                            >
                                                                <Text style={{
                                                                    color: Filter[type] == item ? "white" : "black",
                                                                    fontSize: normalize(14),
                                                                    fontFamily: "OpenSansCondensedLight"
                                                                }}>
                                                                    {item}
                                                                </Text>
                                                            </TouchableOpacity>
                                                        )}
                                                        numColumns={1}
                                                        keyExtractor={item => item.id}
                                                    />
                                                </View>

                                            }
                                            <FlatList
                                                // showsVerticalScrollIndicator={true}
                                                // 
                                                data={
                                                    item.title != "CERTIFICATI" ?
                                                        item.title == "VOCE DI CAPITOLATO" ? docs.filter(item => ((item.name).toLowerCase()).includes(type.toLowerCase()) && ((item.name).toLowerCase()).includes(`(${productName.toLowerCase()})`) && ((item.name).toLowerCase()).includes((`(${(route.params.solution_type).toLowerCase()})`).split(' ').join('_'))) :

                                                            docs.filter(item => ((item.name).toLowerCase()).includes(type.toLowerCase())) :
                                                        docs.filter(item =>
                                                        (type.some(v =>
                                                            ((item.name).toLowerCase()).includes(v.toLowerCase()) &&
                                                            ((item.name).toLowerCase()).includes(`(${productName.toLowerCase()})`) &&
                                                            ((item.name).toLowerCase()).includes((`(${(route.params.solution_type).toLowerCase()})`).split(' ').join('_')))))

                                                    // item.title != "CERTIFICATI" ?
                                                    //     item.title == "VOCE DI CAPITOLATO" ? docs.filter(item => (item.name).includes(type) && ((item.name).toLowerCase()).includes(((route.params.solution_type).toLowerCase()).split(' ').join('_'))) :
                                                    //         docs.filter(item => (item.name).includes(type)) :
                                                    //     docs.filter(item => (type.some(v => (item.name).includes(v) && ((item.name).toLowerCase()).includes(((route.params.solution_type).toLowerCase()).split(' ').join('_')))))
                                                }
                                                renderItem={({ item, index }) => {
                                                    console.log(item, "dsfsfsdf", type);
                                                    var check = false;
                                                    if (type == "SDS" || type == "TDS") {
                                                        if (Filter[type] == "") {
                                                            check = (item.name).includes("_" + language);
                                                        }
                                                        else {
                                                            if (type == "TDS"|| type=="SDS")//change
                                                                check = ((item.name).includes(Filter[type].split("_")[1]));
                                                            else check = ((item.name).includes("(" + Filter[type].split("_")[1] + ")"));
                                                        }
                                                    }
                                                    else if (type == "LISTINO") {
                                                        check = (item.name).includes(language + "_");
                                                    }
                                                    else {
                                                        // if((item.name).includes(language)){
                                                        check = true;
                                                        // }
                                                    }
                                                    if (check)
                                                        return (

                                                            <TouchableHighlight
                                                                underlayColor="none"
                                                                onPress={async () => {
                                                                    setDownloading(true);
                                                                    setDownloadedNow(item.name);
                                                                    console.log(item.name, type);
                                                                    var docLink;
                                                                    if (type == "SDS" || type == "TDS") {

                                                                        await downloadFile(item.name, type, product_line,productName, () => { setDownloading(false); });

                                                                    }
                                                                    else if (type == "VC_")
                                                                        await downloadFile(item.name, "voci", product_line,productName,  () => { setDownloading(false); });
                                                                    else if (type == "Depliant") {
                                                                        console.log("depliant", item.name);
                                                                        await downloadFile(item.name, "depliant", product_line,productName,  () => { setDownloading(false); });
                                                                    }
                                                                    else if (type == "LISTINO")
                                                                        await downloadFile(item.name, "listino", product_line,productName,  () => { setDownloading(false); });
                                                                    else if (type == "Catalogo")
                                                                        await downloadFile(item.name, "cataloghi", product_line,productName,  () => { setDownloading(false); });
                                                                    else {
                                                                        if (sd == "CERTIFICATI") {
                                                                            await downloadFile(item.name, "certificati", product_line,productName,  () => { setDownloading(false); });
                                                                        }
                                                                    }
                                                                    // else if(type=="Catalogo") {
                                                                    //     await downloadFile(item.name,"certificati",product_line,()=>{setDownloading(false);});

                                                                    // }

                                                                    // await downloadFile(item.name,type,product_line,()=>{setDownloading(false);});

                                                                }}
                                                            >
                                                                <View style={styles.doccontainer}>
                                                                    {downloadedDoc.some(value => value.includes(item.name)) || downloadedNow == item.name ?
                                                                        <Image
                                                                            style={{
                                                                                height: normalize(20),
                                                                                width: (20),
                                                                                resizeMode: "contain",
                                                                                right: normalize(30),
                                                                                top: normalize(20),
                                                                                zIndex: 1000
                                                                            }} source={require("../../../assets/myamonn-icons/check.png")} /> : null}
                                                                    {downloading && downloadedNow == item.name ?
                                                                        <Image
                                                                            style={{ width: normalize(100), height: normalize(100) }}
                                                                            source={require("../../../assets/MyAmonn_loading_red.gif")} /> :
                                                                        // <ActivityIndicator
                                                                        //     size="large" 
                                                                        //     color="#D90000"
                                                                        // />:
                                                                        <Image
                                                                            style={{
                                                                                resizeMode: "contain",
                                                                                width: normalize(50),
                                                                                height: normalize(70)
                                                                            }}
                                                                            source={require("../../../assets/myamonn-icons/file-pdf.png")} />}
                                                                    <Text style={styles.docname}>{item.name}</Text>
                                                                </View>
                                                            </TouchableHighlight>
                                                        )
                                                }}

                                            />

                                        </View>
                                    );
                            }}
                        />



                        :
                        <FlatList
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            // pagingEnabled
                            // bounces={true}
                            // snapToInterval={normalize(450)}
                            style={[styles.horizontallist, { alignSelf: "center" }]}
                            contentContainerStyle={{ justifyContent: "center", alignItems: "center" }}
                            data={productslist}
                            renderItem={({ item, index }) => (
                                <TouchableOpacity>
                                    <View
                                        style={{
                                            width: normalize(304),
                                            height: normalize(438, 735),
                                            borderRadius: normalize(40),
                                            justifyContent: "center",
                                            alignContent: "center",
                                            marginTop: normalize(15, 735),
                                            marginHorizontal: normalize(50),

                                        }}>
                                        <ImageBackground source={require("../../../assets/balti.jpeg")} resizeMode="stretch" style={styles.backgroundimage}>
                                            <View style={styles.overlay} />
                                            <Image style={styles.playButton} source={require("../../../assets/myamonn-icons/play.png")} />
                                        </ImageBackground>
                                    </View>
                                    <Text style={{ marginTop: normalize(15, 735), marginBottom: normalize(10, 735), marginHorizontal: normalize(50), fontFamily: "OpenSansCondensedLight", fontSize: normalize(14, 735) }}>Tutorial {productFullName}</Text>
                                </TouchableOpacity>
                            )}
                            numColumns={1}
                            keyExtractor={(item, index) => index.toString()}
                            key={"1"} // if you want to use dynamic numColumns then you have to use key props
                        />
                    ]
                }
            </View>
            <View>
                <BottomButtons
                    Buttons={1}
                    // onSelectButton={onSelectButton}
                    selectionMode={"myproduct"}
                    buttonText1={trans[language]["Documenti"]}
                // buttonText2={"Video"}
                />
                <BottomTab language={language} productImage={route.params.image} productTitle={route.params.name} product_line={route.params.product_line} token={token} />
            </View>

        </View>
    );
}
export default Product;
