import React,{useState,useEffect,useRef} from 'react';
import { Platform,KeyboardAvoidingView,FlatList,TouchableHighlight,Text,View, Dimensions, Image ,TouchableOpacity, ScrollView} from 'react-native';
import styles from './styles';
import BottomButtons from '../../Components/BottomButtons/BottomButtons';
import CustomSwitch from '../../Components/CustomSwitch/CustomSwitch';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { loadProducts } from '../../Helpers/getSolutions';
import { normalize } from '../../Helpers/Normalizer';
import BottomSheet from 'reanimated-bottom-sheet';
import ProductFilterContent from '../../Components/BottomSheetContent/ProductFilterContent';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getDocuments } from '../../Helpers/downloadDocument';
import { openMyDocument } from '../../Helpers/downloadDocument';
import TextTicker from 'react-native-text-ticker';
import DocumentFilter from '../../Components/BottomSheetContent/DocumentFilter';
// import Swipeout from 'react-native-swipeout';
// import { RFValue } from 'react-native-responsive-fontsize';
const { width, height } = Dimensions.get('window');


import { deleteFile } from '../../Helpers/downloadDocument';
import MPS_TRANS from './MPS_TRANS';


const MyProducts = ({navigation,route}) => {
    const keyboardVerticalOffset = Platform.OS === 'ios' ? normalize(80) :0
    const [filter,setFilter]=useState(route.params.filter );
    const [prodLineFilter,setProdLineFilter]=useState([]);
    const [docTypeFilter,setDocTypeFilter]=useState([]);
    const [listView,setListView]=useState(true);
    const [query,setQuery]=useState("");
    const [products,setProducts]=useState([]);
    const [myProducts,setMyProducts]=useState([]);
    const [showDocuments,setShowDocuments]=useState(false);
    const [myDocuments,setmyDocuments]=useState();
    const [deleteDoc,setDeleteDoc]=useState();
    const [refresh,setRefresh]=useState(false);
    const [openFilter,setOpenFilter]=useState(false);
    const [deleteItem,setDeleteItem]=useState("");
    const [language,setLanguage]=useState(route.params.language);
    useEffect(()=>{
        const getLanguage= ()=>{
            AsyncStorage.getItem("language").then((response)=>{
              if(response!=null){
                  setLanguage(JSON.parse(response));
                  console.log(language);
                }
              })
          }
        getLanguage();
        console.log(filter);
        console.log(language);
    })
    useEffect(() => {
        AsyncStorage.getItem("token").then((response)=>{
            if(JSON.parse(response)==null){
                console.log(response,route)
                navigation.navigate("Login",{route:route});
            };
            })
    },[route]);
    const deletef=()=>{
        deleteFile(deleteDoc).then((response)=>{
            setRefresh(prev=>!prev);
            onSelectButton("mydocument");
        })
        
        
    }
    
    // var swipeoutBtns = [
    //     {
    //         onPress:()=>deletef(),
    //         backgroundColor:"transparent",
    //         component:<View style={{ flex:1,justifyContent:"center",alignItems:"center"}}><Icon
    //             name={"delete"}
    //             color={"grey"}
    //             size={normalize(25)}
    //             /></View>,
    //     },
    //     {
    //         backgroundColor:"transparent",
    //         component:<View style={{ flex:1,justifyContent:"center",alignItems:"center"}}><Icon
    //             name={"arrow-down-circle"}
    //             color={"grey"}
    //             size={normalize(25)}
    //             /></View>,
    //     }
    //   ]
    const onSelectButton = async (button) => {
        if (button=="myproduct") {
            setShowDocuments(false);
        }
        else{
            setmyDocuments(await getDocuments());
            setProdLineFilter([]);
            setDocTypeFilter([]);
            setShowDocuments(true);
        }
      };
    const onSelectSwitch = index => {
        if (index==2) {
            setListView(false);
            // console.log("apple");
        }
        else{
            setListView(true);
            // console.log("mango");
        }
      };
    const productDetail=(item)=>{
        navigation.navigate("Product", {
            name: item.product_name,
            image: item.product_image,
            product_line:item.product_line,
        });
    };
    const loadMyProducts= ()=>{
        AsyncStorage.getItem('cart').then((cart)=>{
            if (cart !== null) {
              const items = JSON.parse(cart);
              console.log(items,"zzzz");
              setMyProducts(items);
            }
          })
          .catch((err)=>{
            alert(err)
          })

    }
    const onClickRemoveFav=(itemcart)=>{
        AsyncStorage.getItem('cart').then((datacart)=>{
            if (datacart !== null) {
              // We have data!!
              const cart = JSON.parse(datacart)
              for (var index = 0; index < cart.length; ++index) {
                var product = cart[index];
                if(product.title == itemcart.name){
                    console.log(product.title,itemcart.name);
                    console.log(cart.length);
                    cart.splice(index,1);
                    AsyncStorage.setItem('cart',JSON.stringify(cart));
                    console.log(cart.length);
                    setRefresh(prev=>!prev);
                    break;
                }
               }
            }
            alert("Removed From Favourites");
            
          })
          .catch((err)=>{
            alert(err)
          })
  }

    //   useEffect(()=>{
    //     loadMyProducts();
    //     var p=loadProducts(filter);
    //     var newArray = p.filter(function (item) {
    //         return (item.product_name.toLowerCase()).includes(query.toLowerCase()) || query=="";
    //       });
    //     setProducts(newArray);
        
        
    //   },
    //   );
      
      useEffect(()=>{
        loadMyProducts();
        (async () => {
            var p=await loadProducts(filter);
            var newArray = p.filter(function (item) {
                return (item.product_name.toLowerCase()).includes(query.toLowerCase()) || query=="";
            });
            setProducts(newArray);
        })();
        // console.log(query,"xuma");
      },[refresh,filter,query,route,docTypeFilter,prodLineFilter]);
    return(
        <View style={[styles.container,{justifyContent:"space-between"}]}>
            <KeyboardAvoidingView
                behavior= {Platform.OS === 'ios' ?'position':'height' }keyboardVerticalOffset={keyboardVerticalOffset}>
                <View
                    style={{
                        flexDirection:"row",
                        justifyContent:"space-between",
                        paddingTop:normalize(50),
                        paddingHorizontal:normalize(20),
                        backgroundColor:"white",
                    }}
                >
                <BottomButtons
                    Buttons={1}
                    onSelectButton={onSelectButton}
                    selectionMode={"myproduct"}
                    buttonText1={MPS_TRANS[language]["My Products"]}
                    // buttonText2={MPS_TRANS[language]["My Documents"]}
                />
                {/* {width>786? */}
                <TouchableOpacity
                    onPress={()=>{
                        navigation.navigate("Dashboard");
                    }}>
                    <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
                </TouchableOpacity>                {/* :null} */}
                </View>
                
                <View style={[styles.bottomTab,openFilter?{backgroundColor:"white"}:null]}>
                {!showDocuments?
                    <View style={styles.galleryToggle}>
                    <CustomSwitch
                        selectionMode={1}
                        roundCorner={true}
                        option1={'First'}
                        onSelectSwitch={onSelectSwitch}
                        option2={'Second'}
                        
                        selectionColor={'#D90000'}
                    />
                    </View>:null}
                    <SearchBar width={width>786?558:180} language={language} onChangeText={(query)=>setQuery(query)}/>
                    <TouchableOpacity style={styles.filterBtn}
                        onPress={()=>{
                            setOpenFilter(prev=>!prev)
                        }}
                        >
                        <Text style={styles.filterText}>{MPS_TRANS[language]["FILTER"]}</Text>
                        <Icon
                            name="chevron-up"
                            color="white"
                            size={normalize(25)}
                        />
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
            {openFilter?
            <ProductFilterContent 
                doFilter={(filter)=>{
                    setFilter(filter);
                    // setFilterState(true);
                }} 
                _filter={filter}
                openFilter={()=>setOpenFilter(false)} 
                language={language}
            />:null}

            {showDocuments?
                <FlatList
                    data={myDocuments.filter(item=>docTypeFilter.some(word => item.includes(word)) && prodLineFilter.some(word => item.includes(word.toLowerCase())) && ((item.substring(0,item.indexOf("&")).toLowerCase()).includes(query.toLowerCase()) || query=="") || docTypeFilter.some(word => item.includes(word)) && prodLineFilter.length==0 && ((item.substring(0,item.indexOf("&")).toLowerCase()).includes(query.toLowerCase()) || query=="") || prodLineFilter.some(word => item.includes(word.toLowerCase())) && docTypeFilter.length==0 &&((item.substring(0,item.indexOf("&")).toLowerCase()).includes(query.toLowerCase()) || query=="") || docTypeFilter.length==0 && prodLineFilter.length==0 && ((item.substring(0,item.indexOf("&")).toLowerCase()).includes(query.toLowerCase()) || query=="") )}
                    // style={styles.container}
                    contentContainerStyle={{
                        justifyContent:"center",
                        alignItems:'center',
                        marginVertical:normalize(7,735)
                    }}
                    renderItem={({item ,index}) => {
                        // if(Platform.OS=="android" || Platform.OS=="ios"){
                            return(
                            // <Swipeout 
                            //     onOpen={()=>{setDeleteDoc(item)}}	
                            //     backgroundColor="transparent"
                            //     right={swipeoutBtns}>
                                <TouchableHighlight
                                underlayColor="none"
                                    onPress={()=>{
                                        openMyDocument(item);
                                    }}
                                
                                >
                                    <View 
                                        style={{ 
                                            backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
                                            width:normalize(334),
                                            height:normalize(60,735),
                                            justifyContent:"center",
                                            // alignContent:"center",
                                        }}
                                    >   
                                        <View 
                                            style={{
                                                width:normalize(261),
                                            }}
                                    

                                        >
                                        <TextTicker
                                            duration={3000}
                                            style={{
                                                color:"#333333",
                                                fontFamily:"OpenSansCondensedBold",
                                                fontSize:normalize(16),
                                                marginLeft:normalize(5),
                                                
                                            }}>
                                                {/* {item} */}
                                                {item.substring(0,item.indexOf("&"))}
                                        </TextTicker>
                                        </View>
                                        
                                    </View>
                                </TouchableHighlight>
                            // </Swipeout>
                            )
                        // }
                    }}
                    keyExtractor={(item,index)=>index.toString()}
                    numColumns={1}
                    key={"3"} // if you want to use dynamic numColumns then you have to use key props
            />:[
            (listView?
            <FlatList
                data={products.filter(item => myProducts.some(({title}) => item.product_name === title)).filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.product_name === thing.product_name
                ))
                )}
                style={styles.container}
                renderItem={({item ,index}) => {
                    return(
                        
                        <TouchableHighlight
                            underlayColor='none'
                            onLongPress={()=>{setDeleteItem(item.product_name);console.log(item.product_name)}}
                            onPress={()=>{productDetail(item);
                                            setDeleteItem("")}}>
                            <View style={styles.pcontainer}>
                            {deleteItem==item.name?<TouchableHighlight 
                                    underlayColor="none"
                                    onPress={()=>{onClickRemoveFav(item);setDeleteItem("")}}
                                    style={{position:"absolute",top:normalize(10),right:normalize(20),zIndex:1000,elevation:50,justifyContent:"flex-start",alignSelf:"flex-end"}}><Icon
                                    name={"delete"}
                                    color={"grey"}
                                    size={normalize(25)}
                                    /></TouchableHighlight>:null}
                                <Image style={styles.photo} source={{uri:item.product_image}} />
                                <Text style={styles.title}>{item.product_name}</Text>
                            </View>
                        </TouchableHighlight>
                    )
                
                }}
                keyExtractor={(item,index)=>index.toString()}
                numColumns={width>786?4:2}
                key={"1"}
            />
            :
            <FlatList
                data={products.filter((item) => myProducts.some(({title}) => item.product_name === title)).filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.product_name === thing.product_name
                    ))
                )}
     
                style={styles.container}
                contentContainerStyle={{
                    borderTopWidth:2,
                    borderTopColor:"#D90000",
                    marginHorizontal:width>786?normalize(100):normalize(20),
                }}
                renderItem={({item ,index}) => {
                    return(
                        <TouchableHighlight
                            
                            underlayColor='none'
                            onPress={()=>productDetail(item)}>
                            <View 
                                style={{ 
                                    flexDirection:"row",
                                    backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
                                    // width:normalize(334),
                                    height:normalize(40,735),
                                    justifyContent:"flex-start",
                                    alignContent:"center",
                                     
                                }}>
                                <View 
                                    style={{
                                        width:normalize(261)
                                    }}
                                >
                                <TextTicker
                                    duration={3000}
                                    style={{
                                        color:"#333333",
                                        fontFamily:"OpenSansCondensedBold",
                                        fontSize:normalize(16),
                                        marginLeft:normalize(5),
                                        
                                    }}>{item.product_name}</TextTicker>
                                </View>
                                <Text style={{color:"#333333",fontFamily:"OpenSansCondensedBold",fontSize:normalize(16),marginLeft:normalize(5)}}>{(item.product_line).toUpperCase()}</Text>
                            </View>
                        </TouchableHighlight>)
                        
                }
                }
                keyExtractor={(item,index)=>index.toString()}
                numColumns={1}
                key={"2"} // if you want to use dynamic numColumns then you have to use key props
            />
            )]}
            
        </View>
    );
};

export default MyProducts;