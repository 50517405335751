import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Text, TouchableHighlight, ActivityIndicator, TextInput, TouchableOpacity, Image, ScrollView } from "react-native";
import { normalize } from '../../Helpers/Normalizer';
import * as theme from "../../Theme";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import axios from 'axios';
import styles from './styles';
// import { normalize } from 'react-native-responsive-fontsize';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Alert from '../../Components/Alert/Alert';
import MP_TRANS from './MP_TRANS';

const MyProfile = ({ route, navigation }) => {
    const [language, setLanguage] = useState(route.params.language);
    const [edit, setEdit] = useState("");
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [username, setusername] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [profession, setProfession] = useState('');
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [countryCode, setCountryCode] = useState('0');
    const [province, setProvince] = useState('0');
    const [zip, setZip] = useState('0');
    const [vat, setVat] = useState('0');
    useEffect(() => {
        AsyncStorage.getItem("token").then((response) => {
            if (JSON.parse(response) == null) {
                console.log(response, route)
                navigation.navigate("Login", { route: route });
            };
        })
    }, [route]);
    function LoadingIndicatorView() {
        return <View style={styles.activityIndicatorStyle}>
            <Image
                style={{ width: normalize(200), height: normalize(200) }}
                source={require("../../../assets/MyAmonn_loading_red.gif")} />
        </View>
    }
    const getCurrentUser = async () => {
        setLoading(true);
        await axios.get('https://myadmin.myamonn.com/current-user', {

            headers: { Authorization: `Token ${token}` }
        })
            .then(function (response) {
                setusername(response.data.first_name);
                setUserLastName(response.data.last_name);
                setProfession(response.data.usertype);
                setName(response.data.first_name);
                setphonenumber(response.data.phone_no);
                setAddress(response.data.address);
                setLastName(response.data.last_name);
                setCountryCode(response.data.country == 0 ? "" : response.data.country);
                setEmail(response.data.email);
                setVat(response.data.vat_no);

            })
            .catch(function (error) {
                // console.log(error,"applemyp");
            })
            .then(function () {
                // always executed
            });
    }
    const getToken = () => {
        AsyncStorage.getItem('token').then((token) => {
            if (token != null) {
                //   console.log("token",token);
                setToken(JSON.parse(token));
            }
        })
            .then(async () => {
                await getCurrentUser();
                setLoading(false);
            })
            .catch((err) => {
                alert(err)
            })
    }
    const ChangePersonalInformation = () => {
        if (name && lastName && address && phonenumber) {
            var form = new FormData();
            form.append("first_name", name);
            form.append("last_name", lastName);
            form.append("address", address);
            form.append("phone_no", phonenumber);

            // console.log(`Token ${token}`,"I am in change password");
            fetch('https://myadmin.myamonn.com/api/edit-profile/', {
                method: 'PUT',
                headers: {
                    Authorization: `Token ${token}`,
                },
                body: form,
            })
                .then(r => r.json()
                    .then(data => {
                        if (data.status == "success") {
                            setAlertMessage("Profile edited successfully");
                            setAlert(true);
                        }
                        else {
                            setAlertMessage("some thing went wrong");
                            setAlert(true);

                        }
                    }))
        }
        else {
            setAlertMessage("All feilds are required");
            setAlert(true);
        }

    };
    const ChangePassword = () => {
        var form = new FormData();
        form.append("old_password", oldPassword);
        form.append("new_password", newPassword);

        // console.log(`Token ${token}`,"I am in change password");
        fetch('https://myadmin.myamonn.com/api/change-password/', {
            method: 'PUT',
            headers: {
                Authorization: `Token ${token}`,
            },
            body: form,
        })
            .then(r => r.json()
                .then(data => {
                    if (data.status == "success") {
                        navigation.navigate("Login", {
                            action: "logout",
                        })
                    }
                    else {
                        alert("old password is in coorect");
                    }
                }))

    }
    useEffect(() => {
        getToken();
    }, [token])
    useEffect(() => {
        const getLanguage = () => {
            AsyncStorage.getItem("language").then((response) => {
                if (response != null) {
                    setLanguage(JSON.parse(response));
                    //   console.log(language);
                }
            })
        }
        getLanguage();
    }, [route])

    const alerting = () => {
        setAlert(false);
        setAlertMessage("");

    }
    if (loading) {
        return LoadingIndicatorView();
    }
    else return (
        <ScrollView style={{ flex: 1 }}>
            {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
            <View style={styles.container}>
                <View style={styles.body}>
                    {/* <TouchableOpacity style={{top:normalize(30,735),left:normalize(25),elevation:50,zIndex:1000}}>
                    <Image source={require("../../../assets/myamonn-icons/edit-white.png")}/>
                </TouchableOpacity> */}
                    <Image
                        source={require("../../../assets/myamonn-icons/USER.png")}
                        style={{ height: normalize(100), width: normalize(100) }}
                    />
                    <View style={styles.profileDescription}>
                        <Text style={styles.username}>
                            {username}
                        </Text>
                        <Text style={styles.userType}>

                            {MP_TRANS[language][profession]}
                        </Text>

                    </View>
                    {edit == "personal" ?
                        <TouchableOpacity
                            // onPress={()=>setEdit("")} 
                            style={styles.formBody}>
                            <View style={styles.regForm}>
                                <Text style={[styles.editButtonText, { marginBottom: 10 }]}>
                                    {MP_TRANS[language]["Dati personali"]}
                                </Text>
                                <View style={styles.twoInputView}>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder="NAME"
                                            placeholderTextColor={theme.red}
                                            value={name}
                                            onChangeText={(name) => setName(name)}
                                        />
                                    </View>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder="LAST NAME"
                                            placeholderTextColor={theme.red}
                                            value={lastName}
                                            onChangeText={(lastname) => setLastName(lastname)}
                                        />
                                    </View>
                                </View>
                                <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                    <TextInput
                                        style={styles.TextInput}
                                        placeholder="ADDRESS"
                                        placeholderTextColor={theme.red}
                                        value={address}
                                        onChangeText={(address) => setAddress(address)}
                                    />
                                </View>
                                <View style={styles.twoInputView}>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder="PHONE NUMBER"
                                            placeholderTextColor={theme.red}
                                            value={phonenumber}
                                            onChangeText={(phonenumber) => setphonenumber(phonenumber)}
                                        />
                                    </View>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder="EMAIL"
                                            editable={false}
                                            placeholderTextColor={theme.red}
                                            value={email}
                                            onChangeText={(email) => setEmail(email)}
                                        />
                                    </View>
                                </View>
                                {vat !== "0" ?
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder="VAT"
                                            editable={false}
                                            placeholderTextColor={theme.red}
                                            value={vat}
                                            onChangeText={(vat) => setVat(vat)}
                                        />
                                    </View> : null}
                                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            ChangePersonalInformation();
                                            setEdit("")
                                        }}
                                        style={{
                                            backgroundColor: theme.red,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: normalize(63),
                                            height: normalize(40, 735),
                                            borderRadius: 25
                                        }}>
                                        <Text style={{ color: "white", fontFamily: "OpenSansCondensedBold", fontSize: normalize(14) }}>
                                            {MP_TRANS[language]["Salva"]}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </TouchableOpacity>
                        :
                        <TouchableOpacity
                            style={styles.editButton}
                            onPress={() => {
                                setEdit("personal")
                            }
                            }
                        >
                            <Text style={styles.editButtonText}>
                                {MP_TRANS[language]["Dati personali"]}
                            </Text>
                            <Image
                                style={{ height: normalize(25), width: normalize(25) }}
                                source={require("../../../assets/myamonn-icons/edit-red.png")} />
                        </TouchableOpacity>}
                    {edit == "password" ?
                        <TouchableOpacity
                            // onPress={()=>setEdit("")}
                            style={styles.formBody}>
                            <View style={styles.regForm}>
                                <Text style={[styles.editButtonText, { marginBottom: 10 }]}>
                                    {MP_TRANS[language]["Cambia password"]}
                                </Text>

                                <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                    <TextInput
                                        secureTextEntry={true}
                                        style={styles.TextInput}
                                        placeholder="ACTUAL PASSWORD"
                                        placeholderTextColor={theme.red}
                                        onChangeText={(oldpassword) => setOldPassword(oldpassword)}
                                    />
                                </View>
                                <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                    <TextInput
                                        secureTextEntry={true}
                                        style={styles.TextInput}
                                        placeholder="NEW PASSWORD"
                                        placeholderTextColor={theme.red}
                                        onChangeText={(newpassword) => setNewPassword(newpassword)}
                                    />
                                </View>
                                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            ChangePassword();
                                            setEdit("")
                                        }}
                                        style={{
                                            backgroundColor: theme.red,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: normalize(63),
                                            height: normalize(40, 735),
                                            borderRadius: 25
                                        }}>
                                        <Text style={{ color: "white", fontFamily: "OpenSansCondensedBold", fontSize: normalize(14) }}>
                                            {MP_TRANS[language]["Salva"]}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </TouchableOpacity>

                        : <TouchableOpacity
                            style={styles.editButton}
                            onPress={() => setEdit("password")}
                        >
                            <Text style={styles.editButtonText}>
                                {MP_TRANS[language]["Cambia password"]}
                            </Text>
                            <Image
                                style={{ height: normalize(25), width: normalize(25) }}
                                source={require("../../../assets/myamonn-icons/edit-red.png")} />
                        </TouchableOpacity>}

                </View>
                {profession == 0 || profession == 1 ?
                    <View
                        style={{ marginVertical: normalize(50) }}
                    >
                        <Text style={styles.contact}>
                            {MP_TRANS[language]["Sei un progettista e vuoi accedere a più contenuti? Modifica il tuo status ed effettua un upgrade, potrai ottenere la documentazione e gli strumenti extra a te riservati."]}
                            {/* {"SE DESIDERI CAMBIARE LA TUA PROFESSIONE, \n CONTATTACI A INFO@AMONNCOLOR.COM"} */}
                        </Text>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("UpdateProfile");
                            }}>
                            <Text style={styles.Registration_button}>{"UPDATE!"}</Text>
                        </TouchableOpacity>
                    </View> : null}


            </View>
        </ScrollView>
    )
}

export default MyProfile;