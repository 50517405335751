export default {
    "IT": {
        "Benvenuto su MyAmonn": "Benvenuto su MyAmonn.",
        "nizia a creare la tua bacheca personale": "nizia a creare la tua bacheca personale:",
        "Linea prodotto": "Linea prodotto",
        "Soluzione": "Soluzione",
        "Clear filters": "Pulisci filtri",
        "Please select product line": "Seleziona un linea prodotto",
        "CERCA": "CERCA",
        "Document Type": "Tipo di documento",
        "ORDINE": "ORDINE",
        "OFFERTA": "OFFERTA",
        "ACCREDITO": "ACCREDITO",
        "DDT": "DDT",
        "FATTURA": "FATTURA",
        "CONDIZIONI": "CONDIZIONI",
        "Personalizzato": "Personalizzato",
        "Oggi": "Oggi",
        "Leri": "Leri",
        "Ultimi 7 giorni": "Ultimi 7 giorni",
        "Ultimi 14 giorni": "Ultimi 14 giorni",
        "Ultimi 30 giorni": "Ultimi 30 giorni",
        "Ultimi 90 giorni": "Ultimi 90 giorni",
        "language": "lingua",
        "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",


    },
    "EN": {
        "Benvenuto su MyAmonn": "Welcome to MyAmonn.",
        "nizia a creare la tua bacheca personale": "Start creating your personal dashboard:",
        "Linea prodotto": "Product Range",
        "Soluzione": "Solution",
        "Clear filters": "Clear filters",
        "Please select product line": "Please select product line",
        "CERCA": "SEARCH",
        "Document Type": "Document Type",
        "ORDINE": "ORDERS",
        "OFFERTA": "OFFERS",
        "ACCREDITO": "CREDIT NOTES",
        "DDT": "DDT",
        "FATTURA": "INVOICES",
        "CONDIZIONI": "Terms and conditions",
        "Personalizzato": "Custom filters",
        "Oggi": "Today",
        "Leri": "Yesterday",
        "Ultimi 7 giorni": "Last 7 days",
        "Ultimi 14 giorni": "Last 14 days",
        "Ultimi 30 giorni": "Last 30 days",
        "Ultimi 90 giorni": "Last 90 Days",
        "language": "language",
        "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI": "PROTECTION TO FIRE RETROFIT OF EXISTING SLABS",
        "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI": "PROTECTION TO FIRE RETROFIT OF EXISTING WALLS",
        "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI": "PROTECTION TO FIRE RETROFIT OF STRUCTURAL ELEMENTS",
        "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI": "PROTECTION TO FIRE RETROFIT OF NON-STRUCTURAL WOODEN ELEMENTS",


    },
    "DE": {
        "Benvenuto su MyAmonn": "Willkommen auf MyAmonn.",
        "nizia a creare la tua bacheca personale": "Beginnen Sie mit der Erstellung Ihres persönlichen Dashboards:",
        "Linea prodotto": "Produktlinie",
        "Soluzione": "Lösung",
        "Clear filters": "Filter entfernen",
        "Please select product line": "Lösung auswählen",
        "CERCA": "Suche",
        "Document Type": "Art des Dokuments",
        "ORDINE": "Bestellungen",
        "OFFERTA": "Angebote",
        "ACCREDITO": "Gutschriften",
        "DDT": "DDT",
        "FATTURA": "Rechnungen",
        "CONDIZIONI": "Geschäftsbedingungen",
        "Personalizzato": "Individuelle Filter",
        "Oggi": "Heute",
        "Leri": "Gestern",
        "Ultimi 7 giorni": "Letzte 7 Tage",
        "Ultimi 14 giorni": "Letzte 14 Tagei",
        "Ultimi 30 giorni": "Letzte 30 Tage",
        "Ultimi 90 giorni": "Letzte 90 Tage",
        "language": "Sprache"

    },
    "ES": {
        "Benvenuto su MyAmonn": "Bienvenido a MyAmonn.",
        "nizia a creare la tua bacheca personale": "Empieza a crear tu area personal:",
        "Linea prodotto": "Línea de producto",
        "Soluzione": "Soluciones",
        "Clear filters": "Restablecer filtros",
        "Please select product line": "Selecciona una de producto",
        "CERCA": "Búsqueda",
        "Document Type": "Tipo de Documento",
        "ORDINE": "PEDIDOS",
        "OFFERTA": "OFERTAS",
        "ACCREDITO": "NOTAS DE CRéDITO",
        "DDT": "DDT",
        "FATTURA": "FACTURAS",
        "CONDIZIONI": "TéRMINOS Y CONDICIONES",
        "Personalizzato": "Personalizado",
        "Oggi": "Hoy",
        "Leri": "Ayer",
        "Ultimi 7 giorni": "Últimos 7 días",
        "Ultimi 14 giorni": "Últimos 14 días",
        "Ultimi 30 giorni": "Últimos 30 días",
        "Ultimi 90 giorni": "Últimos 90 días",
        "language": "idioma"

    },


}