import Data from "../Data";
import Products from "../Products";
import AsyncStorage from "@react-native-async-storage/async-storage";

// export const getSolutions=(productName)=>{
//     for(var i in Data){
//         if(i==productName){
//             return (Data[i].solutions);
//         }
//     }
// };
export const getSolutions=async (productName)=>{
    var Pp=await AsyncStorage.getItem("PRODUCTS");
    var Products=await JSON.parse(Pp);
    const result = Products.filter(p => (p.product_line).toLowerCase()==productName.toLowerCase());
    // console.log(result,"sds");
    return result;
    // for(var i in Data){
    //     console.log(i,"me");
    //     if(i==productName){
    //         return (Data[i].solutions);
    //     }
    // }
};
Object.size = function(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};
export const loadProducts=async (filte,query)=>{
    var Pp=await AsyncStorage.getItem("PRODUCTS");
    var Products=await JSON.parse(Pp);
    var alldatas=[]
    if(Object.keys(filte).length>0){
    for(var i in filte){
        if(i=="Bessemer"){
            if(filte[i].length==0){
                var res=Products.filter(p=>i.toLowerCase()==p.product_line.toLowerCase());
                alldatas=alldatas.concat(res);
            }
            else{
                var res=Products.filter(p=>filte[i].includes(p.solution));
                console.log(res,"fdfdfd");
                alldatas=alldatas.concat(res);
            }
        }
        else{
            if(filte[i].length==0){
                var res=Products.filter(p=>i.toLowerCase()==p.product_line.toLowerCase());
                alldatas=alldatas.concat(res);
            }
            else{
                var res=Products.filter(p=>filte[i].includes(p.solution_type));
                alldatas=alldatas.concat(res);
            }
        }
        
    }
    return alldatas;
    }
    else{
        return Products;
    }
}
  
// export const loadProducts=(filter,query)=>{
    
//     var alldata=[]
//     for(var i in Data){
//         // console.log(i,"shoop");
//         let productline=i.charAt(0).toUpperCase()+i.slice(1);
//         if(productline in filter){
//             for(var j in Data[i].solutions){
//                 if(filter[productline].includes((Object.values(Data[i].solutions[j]))[0]) && i=="bessemer" || filter[productline].length==0 && i=="bessemer"){
//                     alldata=alldata.concat([...(Object.values(Data[i].solutions[j]))[2]]);
//                 }
//                 for(var k in Data[i].solutions[j].types){
//                     if(filter[productline].includes((Object.values(Data[i].solutions[j].types[k]))[0]) && i!="bessemer" || filter[productline].length==0 && i!="bessemer"){
//                         alldata=alldata.concat((Object.values(Data[i].solutions[j].types[k]))[2])
//                     }
//                 }
                
//             }
//         }
//         else if(Object.size(filter)==0){
//             for(var j in Data[i].solutions){
//                 if(i=="bessemer"){
//                     alldata=alldata.concat([...(Object.values(Data[i].solutions[j]))[2]]);
//                 }
//                 for(var k in Data[i].solutions[j].types){
//                     if(i!="bessemer"){
//                         alldata=alldata.concat((Object.values(Data[i].solutions[j].types[k]))[2])
//                     }
//                 } 
//             }
//         }
//     }
//     return alldata;
// }

// AsyncStorage.getItem('data').then((datacart)=>{
    //     if (datacart !== null) {
    //         // We have data!!
    //         console.log(datacart);
    //         const data = JSON.parse(datacart)
    //         for(var i in data){
    //             if(i==productName){
    //                 setSolutions(data[i].solutions);
    //             }
    //         }  
    //     }
    // })
    // .catch((err)=>{
    //     alert(err)
    // })