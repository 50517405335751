import React, { useState, useEffect } from 'react';
import { View, Image, TextInput, Text, TouchableOpacity, Platform, ActivityIndicator, Dimensions, ScrollView, SafeAreaView, PixelRatio, Switch } from 'react-native';
const { width, height } = Dimensions.get('window');
import CountryPicker from 'react-native-country-picker-modal'
import { FlatList } from 'react-native-gesture-handler';
import styles from './styles';
import { normalize } from '../../Helpers/Normalizer';
import axios from "axios";
import { RFValue } from 'react-native-responsive-fontsize';
import * as theme from "../../Theme";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Alert from '../../Components/Alert/Alert';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Register_Translation from '../Register/Register_Translation';
import { checkVAT, countries } from 'jsvat';
import { set } from 'react-native-reanimated';

const Register = ({ navigation, route }) => {
    const [countryCode, setCountryCode] = useState('IT');
    const [country, setCountry] = useState(null);
    const [language, setLanguage] = useState(route.params.language);
    const [withCountryNameButton, setWithCountryNameButton] = useState(true)
    const [withFlag, setWithFlag] = useState(true)
    const [withEmoji, setWithEmoji] = useState(true)
    const [withFilter, setWithFilter] = useState(true)
    const [withAlphaFilter, setWithAlphaFilter] = useState(false)
    const [withCallingCode, setWithCallingCode] = useState(false)
    const [showContryPicker, setShoowCountryPickr] = useState(false);
    const onSelect = (country) => {
        setCountryCode(country.cca2)
        setCountry(country);
        setShoowCountryPickr(false);
    }
    const onClose = () => {
        setShoowCountryPickr(false);
    }
    const [open, setOpen] = useState(false);
    const userTypeData = ["Privato", "Tecnico professionista", "Agente Amonn", "Cliente Amonn"];
    const [userType, setUserType] = useState("User type");
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [vat, setVat] = useState('0');
    const [vatCheck, setVatCheck] = useState(false);
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [radixId, setRadixId] = useState('0');
    const [radixPwd, setRadixPwd] = useState('0');
    const [zip, setZip] = useState('');
    const [province, setProvince] = useState(province);
    const register = () => {
        var form = new FormData();
        var user = "0";
        if (userType == "Privato") {
            user = "0"
        }
        else if (userType == "Tecnico professionista") {
            user = "1"
        }
        else if (userType == "Agente Amonn") {
            user = "2"
        }
        else {
            user = "3"
        }
        // var condition=false
        // if(user=="3"){
        //     condition=name!='' && lastName!='' && address!='' && phonenumber!='' && email!='' && password!='' && radixId!='0' && radixPwd!='0' && vat!='0' && vatCheck;
        // }
        // else if(user=="0"){
        //     condition=name!='' && lastName!='' && address!='' && phonenumber!='' && email!='' && password!='';
        // }
        // else{
        //     condition=name!='' && lastName!='' && address!='' && phonenumber!='' && email!='' && password!='' && vat!='0' && vatCheck;
        // }
        // console.log(condition);
        var condition = false
        // if(user=="0"){
        //     condition= vat!='0'&& vatCheck==true
        // }
        // else{
        //     condition= radixId !='0' && radixPwd!='0' && vat!='0' && vatCheck;
        // }
        if (user == "3") {
            condition = name != '' && lastName != '' && address != '' && phonenumber != '' && email != '' && password != '' && radixId != '1' && radixPwd != '1' && vat != '0' && vatCheck;
        }
        else if (user == "0") {
            condition = name != '' && lastName != '' && address != '' && phonenumber != '' && email != '' && password != '';
        }
        else {
            condition = name != '' && lastName != '' && address != '' && phonenumber != '' && email != '' && password != '' && vat != '0' && vatCheck;
        }
        console.log(condition, name, lastName, address, phonenumber, email);
        if (condition) {
            form.append('username', `${email.toLowerCase()}`);
            form.append('password', `${password}`);
            form.append('first_name', `${name}`);
            form.append('last_name', `${lastName}`);
            form.append('email', `${email.toLowerCase()}`);
            form.append('address', `${address}`);
            form.append('usertype', `${user}`);
            form.append('vat_no', `${vat}`);
            form.append('phone_no', `${phonenumber}`);
            form.append('pwd', `${radixPwd}`);
            form.append('r_id', `${radixId}`);
            form.append('zipp', `${zip}`);
            form.append('country', `${countryCode}`);
            form.append('province', `${province}`);
            fetch('https://myadmin.myamonn.com/auth/register/', {
                method: 'POST',
                body: form,
            })
                .then(r => r.json()
                    .then(data => {
                        console.log(data);
                        if (data.token) {
                            // console.log(data.token);
                            setLoading(false);
                            // AsyncStorage.setItem('token',JSON.stringify(data.token));
                            // AsyncStorage.setItem('userRole',JSON.stringify(""));
                            navigation.navigate("Login");
                        }
                        else if (data.email) {
                            setLoading(false);
                            setAlert(true);
                            setAlertMessage(data["email"][0]);
                        }
                        else {
                            setLoading(false);
                            setAlert(true);
                            setAlertMessage("One or more field is invalid");
                        }
                    }))
                .catch(function (error) {
                    setLoading(false);
                    console.log(error, "register");
                    setAlert(true);
                    setAlertMessage("User Already exist with this email!");
                })
        }
        else {
            setLoading(false);
            setAlert(true);
            setAlertMessage("One or more fields are invalid");
        }

    }
    const alerting = () => {
        setAlert(false);
        setAlertMessage("");
    }
    function LoadingIndicatorView() {
        // return <ActivityIndicator
        //           color={theme.red}
        //           size="large"
        //           style={styles.activityIndicatorStyle}
        //         />
        return (
            <View style={styles.activityIndicatorStyle}>
                <Image
                    style={{ width: normalize(200), height: normalize(200) }}
                    source={require("../../../assets/MyAmonn_loading_red.gif")} />
            </View>)
    }
    // useEffect(()=>{
    //     getToken();
    // },[token])
    // useEffect(()=>{
    //     const getLanguage= ()=>{
    //         AsyncStorage.getItem("language").then((response)=>{
    //           if(response!=null){
    //               setLanguage(JSON.parse(response));
    //             //   console.log(language);
    //             }
    //           })
    //       }
    //     getLanguage();
    // },[route])
    useEffect(() => {
        setLoading(false);
    }, [language])
    if (loading) {
        return LoadingIndicatorView();
    }
    else return (

        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ alignItems: "center" }}>
            {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
            <View style={styles.circle}>
                <Image style={styles.avatar} source={require("../../../assets/myamonn-icons/USER.png")} />
            </View>
            <View style={styles.body}>
                <View style={{ alignItems: "center" }}>
                    <View style={styles.logoView}>
                        <Image style={styles.logo} source={require("../../../assets/myamonn-icons/logo.png")} />
                    </View>
                    <View style={{ flexDirection: "row", marginTop: normalize(10) }}>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Register", { language: "IT" });
                                setLanguage("IT");
                                AsyncStorage.setItem('language', JSON.stringify("IT"));
                            }}
                            style={{
                                width: normalize(30),
                                height: normalize(35),
                                borderRadius: normalize(10),
                                borderWidth: 2,
                                borderColor: theme.red,
                                marginLeft: normalize(10),
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: language == "IT" ? theme.pink : "transparent"
                            }}>
                            <Text style={{
                                fontFamily: "OpenSansCondensedBold",
                                fontSize: normalize(14),
                                color: theme.red
                            }}>{"IT"}</Text>

                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Register", { language: "ES" });
                                setLanguage("ES");
                                AsyncStorage.setItem('language', JSON.stringify("ES"));
                            }}
                            style={{
                                width: normalize(30),
                                height: normalize(35),
                                borderRadius: normalize(10),
                                borderWidth: 2,
                                borderColor: theme.red,
                                marginLeft: normalize(10),
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: language == "ES" ? theme.pink : "transparent"
                            }}>
                            <Text style={{
                                fontFamily: "OpenSansCondensedBold",
                                fontSize: normalize(14),
                                color: theme.red
                            }}>{"ES"}</Text>

                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Register", { language: "DE" });
                                setLanguage("DE");
                                AsyncStorage.setItem('language', JSON.stringify("DE"));
                            }}
                            style={{
                                width: normalize(30),
                                height: normalize(35),
                                borderRadius: normalize(10),
                                borderWidth: 2,
                                borderColor: theme.red,
                                marginLeft: normalize(10),
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: language == "DE" ? theme.pink : "transparent"
                            }}>
                            <Text style={{
                                fontFamily: "OpenSansCondensedBold",
                                fontSize: normalize(14),
                                color: theme.red
                            }}>{"DE"}</Text>

                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Register", { language: "EN" });
                                setLanguage("EN");
                                AsyncStorage.setItem('language', JSON.stringify("EN"));
                            }}
                            style={{
                                width: normalize(30),
                                height: normalize(35),
                                borderRadius: normalize(10),
                                borderWidth: 2,
                                borderColor: theme.red,
                                marginLeft: normalize(10),
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: language == "EN" ? theme.pink : "transparent"
                            }}>
                            <Text style={{
                                fontFamily: "OpenSansCondensedBold",
                                fontSize: normalize(14),
                                color: theme.red
                            }}>{"EN"}</Text>

                        </TouchableOpacity>

                    </View>
                    <View
                        style={open ? {
                            borderTopWidth: 0,
                            borderColor: "#D90000",
                            borderWidth: 2,
                            width: normalize(340),
                            borderRadius: 20,
                            backgroundColor: "white",
                            justifyContent: "center",
                            marginTop: normalize(48, 735),
                        } : { marginTop: normalize(48, 735) }}
                    >
                        <TouchableOpacity
                            onPress={() => setOpen(prev => !prev)}
                            style={[styles.singleBtn, open || userType != "User type" ? { backgroundColor: "#D90000" } : null]}>
                            <Text style={[styles.singlebtnText, open || userType != "User type" ? { color: "white" } : null]}>{Register_Translation[route.params.language][userType]}</Text>
                            <Icon
                                name={open ? "chevron-down" : "chevron-up"}
                                color={open || userType != "User type" ? "white" : "#D90000"}
                                size={normalize(25)}
                            />
                        </TouchableOpacity>
                        {open ?
                            <View>
                                <FlatList
                                    showsVerticalScrollIndicator={false}
                                    data={userTypeData}
                                    style={{
                                        width: normalize(338),
                                        height: normalize(160, 735),
                                        marginVertical: normalize(10, 735),
                                    }}
                                    contentContainerStyle={{
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}

                                    renderItem={({ item, index }) => (

                                        <TouchableOpacity
                                            style={{
                                                flexDirection: "row",
                                                width: normalize(328),
                                                backgroundColor: ((index + 1) % 2) != 0 ? "#F2F2F2" : null,
                                                borderRadius: 25,
                                                borderWidth: 0,
                                                height: normalize(40, 735),
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                alignSelf: "center",
                                                paddingHorizontal: normalize(10),
                                                marginVertical: 2
                                            }}
                                            onPress={() => {
                                                setOpen(prev => !prev);
                                                setUserType(item);
                                            }}
                                        >
                                            <Text style={{
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(14),
                                                color: "black"
                                            }}>{Register_Translation[route.params.language][item]}</Text>
                                        </TouchableOpacity>
                                    )}
                                    numColumns={1}
                                    key={"2"}
                                />
                            </View> : null}
                    </View>
                    {!open && userType !== "User type" ? [
                        <View style={styles.regForm}>
                            <View style={styles.twoInputView}>
                                <View style={styles.inputView}>
                                    <TextInput
                                        style={styles.TextInput}
                                        value={name}
                                        placeholder={Register_Translation[route.params.language]["Name"].toUpperCase()}
                                        placeholderTextColor={theme.red}
                                        onChangeText={(name) => setName(name)}
                                    />
                                </View>
                                <View style={styles.inputView}>
                                    <TextInput
                                        style={styles.TextInput}
                                        value={lastName}
                                        placeholder={Register_Translation[route.params.language]["Surname"].toUpperCase()}
                                        placeholderTextColor={theme.red}
                                        onChangeText={(lastname) => setLastName(lastname)}
                                    />
                                </View>
                            </View>
                            <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                <TextInput
                                    style={styles.TextInput}
                                    value={address}
                                    placeholder={Register_Translation[route.params.language]["Address"].toUpperCase()}
                                    placeholderTextColor={theme.red}
                                    onChangeText={(address) => setAddress(address)}
                                />
                            </View>
                            <View style={styles.twoInputView}>
                                <View style={styles.inputView}>
                                    <TextInput
                                        style={styles.TextInput}
                                        value={phonenumber}
                                        placeholder={Register_Translation[route.params.language]["Telephone"].toUpperCase()}
                                        placeholderTextColor={theme.red}
                                        onChangeText={(phonenumber) => setphonenumber(phonenumber)}
                                    />
                                </View>
                                <View style={styles.inputView}>
                                    <TextInput
                                        style={styles.TextInput}
                                        placeholder={Register_Translation[route.params.language]["E-mail"].toUpperCase()}
                                        placeholderTextColor={theme.red}
                                        value={email}
                                        onChangeText={(email) => setEmail(email)}
                                    />
                                </View>
                            </View>
                            <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                <TextInput
                                    style={styles.TextInput}
                                    secureTextEntry={true}
                                    placeholder={Register_Translation[route.params.language]["Password"].toUpperCase()}
                                    placeholderTextColor={theme.red}
                                    value={password}
                                    onChangeText={(password) => setPassword(password)}
                                />
                            </View>

                            {/* radix id and radix pwd fields (huzaifa) */}
                            
                            {/* {userType == "Cliente Amonn" ? (
                                <View style={styles.twoInputView}>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder={"RADIX ID"}
                                            value={radixId}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(radixId) => setRadixId(radixId)}
                                        />
                                    </View>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            secureTextEntry={true}
                                            value={radixPwd}
                                            placeholder={"RADIX " + Register_Translation[route.params.language]["Password"].toUpperCase()}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(radixpwd) => setRadixPwd(radixpwd)}
                                        />
                                    </View>
                                </View>) : null} */}
                            <TouchableOpacity
                                onPress={() => { setShoowCountryPickr(true) }}
                                style={[styles.inputView, { width: normalize(338), flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingHorizontal: normalize(20) }]}>
                                <CountryPicker
                                    {...{
                                        countryCode,
                                        withFilter,
                                        withFlag,
                                        withCountryNameButton,
                                        withAlphaFilter,
                                        withCallingCode,
                                        withEmoji,
                                        onSelect,
                                        onClose,
                                    }}
                                    visible={showContryPicker}
                                />
                                <Icon
                                    onPress={() => { setShoowCountryPickr(true) }}
                                    name={"chevron-down"}
                                    color={"#D90000"}
                                    size={normalize(25)}
                                />
                                {/* <Text style={styles.instructions}>Press on the flag to open modal</Text>
                            {country !== null && (
                                <Text style={styles.data}>{JSON.stringify(country, null, 2)}</Text>
                            )} */}
                            </TouchableOpacity>
                            {countryCode == "IT" ?
                                <View style={styles.twoInputView}>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder={"ZIP"}
                                            value={zip}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(zip) => setZip(zip)}
                                        />
                                    </View>
                                    <View style={styles.inputView}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder={"PROVINCE"}
                                            value={province}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(province) => setProvince(province)}
                                        />
                                    </View>
                                </View>
                                :
                                <View style={styles.inputView}>
                                    <TextInput
                                        style={styles.TextInput}
                                        placeholder={"ZIP"}
                                        value={zip}
                                        placeholderTextColor={theme.red}
                                        onChangeText={(zip) => setZip(zip)}
                                    />
                                </View>
                            }
                            {userType == "Tecnico professionista" || userType == "Agente Amonn" || userType == "Cliente Amonn" ? (
                                <View style={styles.twoInputView}>
                                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <View style={styles.inputView}>
                                            <TextInput
                                                style={styles.TextInput}
                                                placeholder={Register_Translation[route.params.language]["VAT No"].toUpperCase()}
                                                placeholderTextColor={theme.red}
                                                onChangeText={(vat) => {
                                                    setVat(vat);
                                                    setVatCheck(checkVAT(vat, countries).isValid);
                                                }}
                                            />
                                        </View>
                                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                            <Icon
                                                style={{
                                                    marginBottom: normalize(8),
                                                    marginLeft: normalize(5)
                                                }}
                                                name={vatCheck ? "check-circle" : "close-circle-outline"}
                                                color={vatCheck ? "green" : "#D90000"}
                                                size={normalize(25)}
                                            />
                                            <View style={[styles.inputView, { backgroundColor: "transparent", width: normalize(148), borderWidth: 2, borderColor: "black" }]}>
                                                <TextInput
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        paddingLeft: normalize(5),
                                                        fontFamily: "OpenSansCondensedBold",
                                                        color: "black",
                                                        fontSize: normalize(10.5),
                                                    }}
                                                    placeholder={Register_Translation[route.params.language]["VAT No reg"].toUpperCase()}
                                                    placeholderTextColor={theme.red}
                                                    editable={false}
                                                />
                                            </View>

                                        </View>

                                    </View>
                                </View>) : null}
                        </View>
                    ] : null}
                </View>
                <TouchableOpacity style={styles.loginBtn}
                    onPress={() => {
                        setLoading(true);
                        register();
                    }}
                >
                    <Text style={styles.loginText}>{Register_Translation[route.params.language]["Register"]}</Text>
                </TouchableOpacity>
            </View>

        </ScrollView>



    );
};

export default Register;

