export default[
    {
        "id": 563,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Fondi protettivi",
        "product_name": "Hydrogrund Plus",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Hydrogrund-Plus-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.586214Z",
        "updated_at": "2021-12-13T15:13:13.586234Z"
    },
    {
        "id": 564,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Fondi protettivi",
        "product_name": "Lignex Grund BPIv",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Grund-BPIv-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.586783Z",
        "updated_at": "2021-12-13T15:13:13.586793Z"
    },
    {
        "id": 565,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Fondi protettivi",
        "product_name": "Lignex Aquadefend",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex®-Aquadefend-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.587371Z",
        "updated_at": "2021-12-13T15:13:13.587380Z"
    },
    {
        "id": 566,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Fondi protettivi",
        "product_name": "Lignex Defend",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex®-Defend-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.587772Z",
        "updated_at": "2021-12-13T15:13:13.587780Z"
    },
    {
        "id": 567,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Impregnanti",
        "product_name": "Aqua Lignex I",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Aqua-Lignex®-I-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.588188Z",
        "updated_at": "2021-12-13T15:13:13.588196Z"
    },
    {
        "id": 568,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Impregnanti",
        "product_name": "Lignex Lasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex®-Lasur-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.588582Z",
        "updated_at": "2021-12-13T15:13:13.588589Z"
    },
    {
        "id": 569,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Impregnanti",
        "product_name": "Lignex High Solid",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ak-ak20-.jpg",
        "created_at": "2021-12-13T15:13:13.588959Z",
        "updated_at": "2021-12-13T15:13:13.588966Z"
    },
    {
        "id": 570,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Impregnanti",
        "product_name": "Lignex Aqualasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/06/Lignex-Aqualazur-Zero-1200x1200-1.jpg",
        "created_at": "2021-12-13T15:13:13.593070Z",
        "updated_at": "2021-12-13T15:13:13.593083Z"
    },
    {
        "id": 571,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture impregnanti effetto cera",
        "product_name": "Lignex Aquagel",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex®-Aquagel-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.593507Z",
        "updated_at": "2021-12-13T15:13:13.593515Z"
    },
    {
        "id": 572,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture impregnanti effetto cera",
        "product_name": "Lignex Renova",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex®-Renova-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.593886Z",
        "updated_at": "2021-12-13T15:13:13.593896Z"
    },
    {
        "id": 573,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture impregnanti effetto cera",
        "product_name": "Hydrolignex Design",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/HIDROLIGNEX-DESIGN.jpg",
        "created_at": "2021-12-13T15:13:13.594279Z",
        "updated_at": "2021-12-13T15:13:13.594288Z"
    },
    {
        "id": 574,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture impregnanti effetto cera",
        "product_name": "Lignex Aquawax",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/06/Lignex-Aquawax-Zero-1200x1200-1.jpg",
        "created_at": "2021-12-13T15:13:13.594649Z",
        "updated_at": "2021-12-13T15:13:13.594657Z"
    },
    {
        "id": 575,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture",
        "product_name": "Hydrotop",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Hydrotop-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.595032Z",
        "updated_at": "2021-12-13T15:13:13.595040Z"
    },
    {
        "id": 576,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture",
        "product_name": "Lignex UV Filter",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex®-UV-Filter-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.595463Z",
        "updated_at": "2021-12-13T15:13:13.595470Z"
    },
    {
        "id": 577,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture",
        "product_name": "Lignex DS 601",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/lignex_ds601.jpg",
        "created_at": "2021-12-13T15:13:13.595935Z",
        "updated_at": "2021-12-13T15:13:13.595943Z"
    },
    {
        "id": 578,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Finiture",
        "product_name": "Lignex Compact",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/lignex_compact.jpg",
        "created_at": "2021-12-13T15:13:13.596318Z",
        "updated_at": "2021-12-13T15:13:13.596325Z"
    },
    {
        "id": 579,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Protezione climatica",
        "product_name": "Amolis",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Amolis-25lt-4.jpg",
        "created_at": "2021-12-13T15:13:13.596685Z",
        "updated_at": "2021-12-13T15:13:13.596691Z"
    },
    {
        "id": 580,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Protezione climatica",
        "product_name": "Lignex Prodeck",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Prodeck-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.597032Z",
        "updated_at": "2021-12-13T15:13:13.597039Z"
    },
    {
        "id": 581,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Hydrolac",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Hydrolac-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.597361Z",
        "updated_at": "2021-12-13T15:13:13.597368Z"
    },
    {
        "id": 582,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Lignex Aqua Isoliergrund",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/Lignex_aqua_Isoliergrund.jpg",
        "created_at": "2021-12-13T15:13:13.597681Z",
        "updated_at": "2021-12-13T15:13:13.597687Z"
    },
    {
        "id": 583,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Rinfresko",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Rinfresko-0.750L-ETI.jpg",
        "created_at": "2021-12-13T15:13:13.597997Z",
        "updated_at": "2021-12-13T15:13:13.598004Z"
    },
    {
        "id": 584,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Hydrolac Marine",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Hydrolac-Marine-0.750L-ETI-2.jpg",
        "created_at": "2021-12-13T15:13:13.598844Z",
        "updated_at": "2021-12-13T15:13:13.598851Z"
    },
    {
        "id": 585,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Fresko",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Fresko-0.750L-ETI.jpg",
        "created_at": "2021-12-13T15:13:13.599180Z",
        "updated_at": "2021-12-13T15:13:13.599186Z"
    },
    {
        "id": 586,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Polilac",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/polilac.jpg",
        "created_at": "2021-12-13T15:13:13.599512Z",
        "updated_at": "2021-12-13T15:13:13.599519Z"
    },
    {
        "id": 587,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Netto",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/netto.tif.jpg",
        "created_at": "2021-12-13T15:13:13.599865Z",
        "updated_at": "2021-12-13T15:13:13.599871Z"
    },
    {
        "id": 588,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Prodotti Complementari",
        "product_name": "Holzstuk",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/holzstuck.jpg",
        "created_at": "2021-12-13T15:13:13.600259Z",
        "updated_at": "2021-12-13T15:13:13.600266Z"
    },
    {
        "id": 589,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Nature Oil",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Nature-Oil-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.600582Z",
        "updated_at": "2021-12-13T15:13:13.600589Z"
    },
    {
        "id": 590,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Longlife Oil",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Longlife-Oil-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.601093Z",
        "updated_at": "2021-12-13T15:13:13.601100Z"
    },
    {
        "id": 591,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Hydro Oil",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Hydro-Oil-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.601424Z",
        "updated_at": "2021-12-13T15:13:13.601431Z"
    },
    {
        "id": 592,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Marine Teak Oil",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Teak-Oil-Marine-0.750L-ETI.jpg",
        "created_at": "2021-12-13T15:13:13.601767Z",
        "updated_at": "2021-12-13T15:13:13.601774Z"
    },
    {
        "id": 593,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Hard Oil",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Lignex┬-Hard-Oil-0.750L-ETI.jpg",
        "created_at": "2021-12-13T15:13:13.602090Z",
        "updated_at": "2021-12-13T15:13:13.602096Z"
    },
    {
        "id": 594,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Garten Oel",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Garten-Oel.jpg",
        "created_at": "2021-12-13T15:13:13.602422Z",
        "updated_at": "2021-12-13T15:13:13.602429Z"
    },
    {
        "id": 595,
        "product_line": "lignex",
        "solution": "Lignex",
        "solution_type": "Lignex – Oli e complementari",
        "product_name": "Lignex Garten Aufheller",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/aufheller-copia.jpg",
        "created_at": "2021-12-13T15:13:13.602975Z",
        "updated_at": "2021-12-13T15:13:13.602981Z"
    },
    {
        "id": 596,
        "product_line": "lignex",
        "solution": "Woodpro",
        "solution_type": "Woodpro – Impregnanti",
        "product_name": "Wasserlasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ac-ac53-.jpg",
        "created_at": "2021-12-13T15:13:13.603506Z",
        "updated_at": "2021-12-13T15:13:13.603512Z"
    },
    {
        "id": 597,
        "product_line": "lignex",
        "solution": "Woodpro",
        "solution_type": "Woodpro – Impregnanti",
        "product_name": "Impraegnierlasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ak-ak08-.jpg",
        "created_at": "2021-12-13T15:13:13.603832Z",
        "updated_at": "2021-12-13T15:13:13.603838Z"
    },
    {
        "id": 598,
        "product_line": "lignex",
        "solution": "Woodpro",
        "solution_type": "Woodpro – Finiture impregnanti effetto cera",
        "product_name": "Aqua MS-Lasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ad-ad69-.jpg",
        "created_at": "2021-12-13T15:13:13.604144Z",
        "updated_at": "2021-12-13T15:13:13.604150Z"
    },
    {
        "id": 599,
        "product_line": "lignex",
        "solution": "Woodpro",
        "solution_type": "Woodpro – Finiture impregnanti effetto cera",
        "product_name": "UV Lasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ad-ad16-.jpg",
        "created_at": "2021-12-13T15:13:13.604477Z",
        "updated_at": "2021-12-13T15:13:13.604484Z"
    },
    {
        "id": 600,
        "product_line": "lignex",
        "solution": "Woodpro",
        "solution_type": "Woodpro – Finiture",
        "product_name": "Aquatop",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ad-ad61-.jpg",
        "created_at": "2021-12-13T15:13:13.604828Z",
        "updated_at": "2021-12-13T15:13:13.604835Z"
    },
    {
        "id": 601,
        "product_line": "lignex",
        "solution": "Woodpro",
        "solution_type": "Woodpro – Finiture",
        "product_name": "UV Stop",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ad-ad01-.jpg",
        "created_at": "2021-12-13T15:13:13.605138Z",
        "updated_at": "2021-12-13T15:13:13.605145Z"
    },
    {
        "id": 602,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Fondi protettivi",
        "product_name": "Aquaprofi Protektor PIvT",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUAPROFI-PROTEKTOR-PIvT.jpg",
        "created_at": "2021-12-13T15:13:13.605453Z",
        "updated_at": "2021-12-13T15:13:13.605459Z"
    },
    {
        "id": 603,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Fondi protettivi",
        "product_name": "Aquaprofi Hydrogrund Plus BP",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-HYDROGRUND-PLUS-BP.jpg",
        "created_at": "2021-12-13T15:13:13.605770Z",
        "updated_at": "2021-12-13T15:13:13.605776Z"
    },
    {
        "id": 604,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Fondi protettivi",
        "product_name": "Aquaprofi Grund Plus BPIvT",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-GRUND-PLUS-BPIvT.jpg",
        "created_at": "2021-12-13T15:13:13.606131Z",
        "updated_at": "2021-12-13T15:13:13.606138Z"
    },
    {
        "id": 605,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Fondi protettivi",
        "product_name": "Aquaprofi Defend",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-DEFEND.jpg",
        "created_at": "2021-12-13T15:13:13.606463Z",
        "updated_at": "2021-12-13T15:13:13.606470Z"
    },
    {
        "id": 606,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi Air FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUAPROFI-Air-FK-1.jpg",
        "created_at": "2021-12-13T15:13:13.606798Z",
        "updated_at": "2021-12-13T15:13:13.606805Z"
    },
    {
        "id": 607,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi Holzlasur FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUAPROFI-Holzlasur-FK-1.jpg",
        "created_at": "2021-12-13T15:13:13.607127Z",
        "updated_at": "2021-12-13T15:13:13.607134Z"
    },
    {
        "id": 608,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi Decorlasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-DECORLASUR.jpg",
        "created_at": "2021-12-13T15:13:13.607460Z",
        "updated_at": "2021-12-13T15:13:13.607467Z"
    },
    {
        "id": 609,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi Lasur FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-LASUR-FK.jpg",
        "created_at": "2021-12-13T15:13:13.607827Z",
        "updated_at": "2021-12-13T15:13:13.607834Z"
    },
    {
        "id": 610,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi Effektlasur FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-EFFEKTLASUR-FK.jpg",
        "created_at": "2021-12-13T15:13:13.608210Z",
        "updated_at": "2021-12-13T15:13:13.608216Z"
    },
    {
        "id": 611,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi HSL BIv",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-HSL-BIV.jpg",
        "created_at": "2021-12-13T15:13:13.608537Z",
        "updated_at": "2021-12-13T15:13:13.608544Z"
    },
    {
        "id": 612,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Impregnanti",
        "product_name": "Aquaprofi Lasur Zero",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/AQUAPROFI-LASUR-ZERO.jpg",
        "created_at": "2021-12-13T15:13:13.608899Z",
        "updated_at": "2021-12-13T15:13:13.608906Z"
    },
    {
        "id": 613,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Finiture impregnanti effetto cera",
        "product_name": "Aquaprofi Interno UV Zero",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUAPROFI-INTERNO-UV-ZERO.jpg",
        "created_at": "2021-12-13T15:13:13.609232Z",
        "updated_at": "2021-12-13T15:13:13.609238Z"
    },
    {
        "id": 614,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Finiture impregnanti effetto cera",
        "product_name": "Aquaprofi MS Top FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-MS-TOP-FK.jpg",
        "created_at": "2021-12-13T15:13:13.609544Z",
        "updated_at": "2021-12-13T15:13:13.609550Z"
    },
    {
        "id": 615,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Finiture impregnanti effetto cera",
        "product_name": "Aquaprofi MS Lasur FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-MS-LASUR-FK.jpg",
        "created_at": "2021-12-13T15:13:13.609862Z",
        "updated_at": "2021-12-13T15:13:13.609868Z"
    },
    {
        "id": 616,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Finiture impregnanti effetto cera",
        "product_name": "Aquaprofi MS Zero",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/AQUAPROFI-MS-ZERO.jpg",
        "created_at": "2021-12-13T15:13:13.610189Z",
        "updated_at": "2021-12-13T15:13:13.610196Z"
    },
    {
        "id": 617,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Complementari",
        "product_name": "Amonn Tampone Spugna Mohair",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUAPROFI-TAMPONE.jpg",
        "created_at": "2021-12-13T15:13:13.610557Z",
        "updated_at": "2021-12-13T15:13:13.610564Z"
    },
    {
        "id": 618,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Complementari",
        "product_name": "Aqua Retardo",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUA-Retardo.jpg",
        "created_at": "2021-12-13T15:13:13.610925Z",
        "updated_at": "2021-12-13T15:13:13.610932Z"
    },
    {
        "id": 619,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Complementari",
        "product_name": "Aquaprofi Reiniger",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-REINIGER.jpg",
        "created_at": "2021-12-13T15:13:13.611244Z",
        "updated_at": "2021-12-13T15:13:13.611250Z"
    },
    {
        "id": 620,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Complementari",
        "product_name": "Aquaprofi Siegel",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/AQUAPROFI-SIEGEL-1.jpg",
        "created_at": "2021-12-13T15:13:13.611578Z",
        "updated_at": "2021-12-13T15:13:13.611585Z"
    },
    {
        "id": 621,
        "product_line": "lignex",
        "solution": "Aquaprofi",
        "solution_type": "Aquaprofi – Oli",
        "product_name": "Aquaprofi Oil FK",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/10/AQUAPROFI-Oil-FK-1.jpg",
        "created_at": "2021-12-13T15:13:13.611906Z",
        "updated_at": "2021-12-13T15:13:13.611913Z"
    },
    {
        "id": 622,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Fondi protettivi",
        "product_name": "Hydrogrund Plus",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Hydrogrund-Plus-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.612232Z",
        "updated_at": "2021-12-13T15:13:13.612239Z"
    },
    {
        "id": 623,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Impregnanti",
        "product_name": "Hydrodip NEW",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDRODIP_NEW.jpg",
        "created_at": "2021-12-13T15:13:13.612578Z",
        "updated_at": "2021-12-13T15:13:13.612585Z"
    },
    {
        "id": 624,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Impregnanti",
        "product_name": "Hydrodip Uniformante",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDRODIP_UNIFORMANTE_801_QUERCIA_COPRENTE_EICHE_DECKEND.jpg",
        "created_at": "2021-12-13T15:13:13.612891Z",
        "updated_at": "2021-12-13T15:13:13.612898Z"
    },
    {
        "id": 625,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Impregnanti",
        "product_name": "Hydrotop MS Lasur",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDROTOP_MS_LASUR_INCOLORE.jpg",
        "created_at": "2021-12-13T15:13:13.613197Z",
        "updated_at": "2021-12-13T15:13:13.613204Z"
    },
    {
        "id": 626,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Fondi intermedi",
        "product_name": "Hydrotop Fondo Intermedio FC",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDROTOP_FONDO_INTERMEDIO_FCZ.jpg",
        "created_at": "2021-12-13T15:13:13.613576Z",
        "updated_at": "2021-12-13T15:13:13.613588Z"
    },
    {
        "id": 627,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Fondi intermedi",
        "product_name": "Hydrotop Fondo Intermedio S",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDROTOP_FONDO_INTERMEDIO_SZ.jpg",
        "created_at": "2021-12-13T15:13:13.613996Z",
        "updated_at": "2021-12-13T15:13:13.614003Z"
    },
    {
        "id": 628,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Fondi intermedi",
        "product_name": "Hydrotop Primer",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDROTOP_PRIMER.jpg",
        "created_at": "2021-12-13T15:13:13.614318Z",
        "updated_at": "2021-12-13T15:13:13.614324Z"
    },
    {
        "id": 629,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Fondi intermedi",
        "product_name": "Hydrotop Filler",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/HYDROTOP_FILLER_Nr_51_BIANCO.jpg",
        "created_at": "2021-12-13T15:13:13.614692Z",
        "updated_at": "2021-12-13T15:13:13.614701Z"
    },
    {
        "id": 630,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Finiture",
        "product_name": "Hydrotop Airless New",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/a-ad-ad56-.jpg",
        "created_at": "2021-12-13T15:13:13.615108Z",
        "updated_at": "2021-12-13T15:13:13.615116Z"
    },
    {
        "id": 631,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Finiture",
        "product_name": "Fenstertop",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/AMONN_FENSTER_TOP_Nr_30_BETULLA_BIRKE.jpg",
        "created_at": "2021-12-13T15:13:13.615463Z",
        "updated_at": "2021-12-13T15:13:13.615470Z"
    },
    {
        "id": 632,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Finiture",
        "product_name": "Hydrotop",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Hydrotop-25lt.jpg",
        "created_at": "2021-12-13T15:13:13.615876Z",
        "updated_at": "2021-12-13T15:13:13.615884Z"
    },
    {
        "id": 633,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Complementari",
        "product_name": "Fresko",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Fresko-0.750L-ETI.jpg",
        "created_at": "2021-12-13T15:13:13.616251Z",
        "updated_at": "2021-12-13T15:13:13.616258Z"
    },
    {
        "id": 634,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Complementari",
        "product_name": "Rinfresko",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/07/Rinfresko-0.750L-ETI.jpg",
        "created_at": "2021-12-13T15:13:13.616604Z",
        "updated_at": "2021-12-13T15:13:13.616611Z"
    },
    {
        "id": 635,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Complementari",
        "product_name": "Amonn Sigillante",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/Lignex-Sigillante-CF011309_1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.616970Z",
        "updated_at": "2021-12-13T15:13:13.616978Z"
    },
    {
        "id": 636,
        "product_line": "lignex",
        "solution": "Industria serramento",
        "solution_type": "Industria serramento – Complementari",
        "product_name": "Holzstuk",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/08/holzstuck.jpg",
        "created_at": "2021-12-13T15:13:13.619896Z",
        "updated_at": "2021-12-13T15:13:13.619913Z"
    },
    {
        "id": 637,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.620428Z",
        "updated_at": "2021-12-13T15:13:13.620436Z"
    },
    {
        "id": 638,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.621231Z",
        "updated_at": "2021-12-13T15:13:13.621238Z"
    },
    {
        "id": 639,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.622017Z",
        "updated_at": "2021-12-13T15:13:13.622025Z"
    },
    {
        "id": 640,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.622587Z",
        "updated_at": "2021-12-13T15:13:13.622594Z"
    },
    {
        "id": 641,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.622928Z",
        "updated_at": "2021-12-13T15:13:13.622934Z"
    },
    {
        "id": 642,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.623273Z",
        "updated_at": "2021-12-13T15:13:13.623279Z"
    },
    {
        "id": 643,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.623618Z",
        "updated_at": "2021-12-13T15:13:13.623625Z"
    },
    {
        "id": 644,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.623965Z",
        "updated_at": "2021-12-13T15:13:13.623972Z"
    },
    {
        "id": 645,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.624304Z",
        "updated_at": "2021-12-13T15:13:13.624311Z"
    },
    {
        "id": 646,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.624694Z",
        "updated_at": "2021-12-13T15:13:13.624700Z"
    },
    {
        "id": 647,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.625081Z",
        "updated_at": "2021-12-13T15:13:13.625088Z"
    },
    {
        "id": 648,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.625435Z",
        "updated_at": "2021-12-13T15:13:13.625442Z"
    },
    {
        "id": 649,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.625779Z",
        "updated_at": "2021-12-13T15:13:13.625786Z"
    },
    {
        "id": 650,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.626167Z",
        "updated_at": "2021-12-13T15:13:13.626177Z"
    },
    {
        "id": 651,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.626560Z",
        "updated_at": "2021-12-13T15:13:13.626567Z"
    },
    {
        "id": 652,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.626915Z",
        "updated_at": "2021-12-13T15:13:13.626921Z"
    },
    {
        "id": 653,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.627262Z",
        "updated_at": "2021-12-13T15:13:13.627269Z"
    },
    {
        "id": 654,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.627612Z",
        "updated_at": "2021-12-13T15:13:13.627619Z"
    },
    {
        "id": 655,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.627930Z",
        "updated_at": "2021-12-13T15:13:13.627937Z"
    },
    {
        "id": 656,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.628239Z",
        "updated_at": "2021-12-13T15:13:13.628246Z"
    },
    {
        "id": 657,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.628714Z",
        "updated_at": "2021-12-13T15:13:13.628721Z"
    },
    {
        "id": 658,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.629044Z",
        "updated_at": "2021-12-13T15:13:13.629051Z"
    },
    {
        "id": 659,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.629352Z",
        "updated_at": "2021-12-13T15:13:13.629359Z"
    },
    {
        "id": 660,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.629667Z",
        "updated_at": "2021-12-13T15:13:13.629674Z"
    },
    {
        "id": 661,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.629995Z",
        "updated_at": "2021-12-13T15:13:13.630001Z"
    },
    {
        "id": 662,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.630323Z",
        "updated_at": "2021-12-13T15:13:13.630330Z"
    },
    {
        "id": 663,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.630645Z",
        "updated_at": "2021-12-13T15:13:13.630652Z"
    },
    {
        "id": 664,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.630969Z",
        "updated_at": "2021-12-13T15:13:13.630976Z"
    },
    {
        "id": 665,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.631283Z",
        "updated_at": "2021-12-13T15:13:13.631289Z"
    },
    {
        "id": 666,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.631597Z",
        "updated_at": "2021-12-13T15:13:13.631604Z"
    },
    {
        "id": 667,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.631943Z",
        "updated_at": "2021-12-13T15:13:13.631951Z"
    },
    {
        "id": 668,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.632268Z",
        "updated_at": "2021-12-13T15:13:13.632275Z"
    },
    {
        "id": 669,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.632585Z",
        "updated_at": "2021-12-13T15:13:13.632592Z"
    },
    {
        "id": 670,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.632924Z",
        "updated_at": "2021-12-13T15:13:13.632930Z"
    },
    {
        "id": 671,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.633261Z",
        "updated_at": "2021-12-13T15:13:13.633268Z"
    },
    {
        "id": 672,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.633600Z",
        "updated_at": "2021-12-13T15:13:13.633607Z"
    },
    {
        "id": 673,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.633911Z",
        "updated_at": "2021-12-13T15:13:13.633918Z"
    },
    {
        "id": 674,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.634281Z",
        "updated_at": "2021-12-13T15:13:13.634287Z"
    },
    {
        "id": 675,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.634629Z",
        "updated_at": "2021-12-13T15:13:13.634635Z"
    },
    {
        "id": 676,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "LATEROCEMENTO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.634948Z",
        "updated_at": "2021-12-13T15:13:13.634955Z"
    },
    {
        "id": 677,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.635265Z",
        "updated_at": "2021-12-13T15:13:13.635272Z"
    },
    {
        "id": 678,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "PREDALLES",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.635567Z",
        "updated_at": "2021-12-13T15:13:13.635573Z"
    },
    {
        "id": 679,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.635882Z",
        "updated_at": "2021-12-13T15:13:13.635889Z"
    },
    {
        "id": 680,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.636206Z",
        "updated_at": "2021-12-13T15:13:13.636213Z"
    },
    {
        "id": 681,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc07-.jpg",
        "created_at": "2021-12-13T15:13:13.636555Z",
        "updated_at": "2021-12-13T15:13:13.636561Z"
    },
    {
        "id": 682,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "CLS E LAMIERA GRECATA COLLABORANTE",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.636868Z",
        "updated_at": "2021-12-13T15:13:13.636875Z"
    },
    {
        "id": 683,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.637202Z",
        "updated_at": "2021-12-13T15:13:13.637209Z"
    },
    {
        "id": 684,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "solution_type": "TEGOLI BINERVANTI",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.637546Z",
        "updated_at": "2021-12-13T15:13:13.637553Z"
    },
    {
        "id": 685,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.637854Z",
        "updated_at": "2021-12-13T15:13:13.637861Z"
    },
    {
        "id": 686,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.638174Z",
        "updated_at": "2021-12-13T15:13:13.638180Z"
    },
    {
        "id": 687,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.638496Z",
        "updated_at": "2021-12-13T15:13:13.638502Z"
    },
    {
        "id": 688,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.638820Z",
        "updated_at": "2021-12-13T15:13:13.638827Z"
    },
    {
        "id": 689,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.639178Z",
        "updated_at": "2021-12-13T15:13:13.639184Z"
    },
    {
        "id": 690,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.639516Z",
        "updated_at": "2021-12-13T15:13:13.639523Z"
    },
    {
        "id": 691,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.639976Z",
        "updated_at": "2021-12-13T15:13:13.639982Z"
    },
    {
        "id": 692,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.640282Z",
        "updated_at": "2021-12-13T15:13:13.640289Z"
    },
    {
        "id": 693,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.640614Z",
        "updated_at": "2021-12-13T15:13:13.640620Z"
    },
    {
        "id": 694,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.640939Z",
        "updated_at": "2021-12-13T15:13:13.640945Z"
    },
    {
        "id": 695,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.641260Z",
        "updated_at": "2021-12-13T15:13:13.641266Z"
    },
    {
        "id": 696,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.641592Z",
        "updated_at": "2021-12-13T15:13:13.641599Z"
    },
    {
        "id": 697,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.641911Z",
        "updated_at": "2021-12-13T15:13:13.641918Z"
    },
    {
        "id": 698,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.642270Z",
        "updated_at": "2021-12-13T15:13:13.642277Z"
    },
    {
        "id": 699,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.642590Z",
        "updated_at": "2021-12-13T15:13:13.642597Z"
    },
    {
        "id": 700,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.642911Z",
        "updated_at": "2021-12-13T15:13:13.642918Z"
    },
    {
        "id": 701,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.643282Z",
        "updated_at": "2021-12-13T15:13:13.643289Z"
    },
    {
        "id": 702,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.643636Z",
        "updated_at": "2021-12-13T15:13:13.643643Z"
    },
    {
        "id": 703,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.643946Z",
        "updated_at": "2021-12-13T15:13:13.643953Z"
    },
    {
        "id": 704,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.644273Z",
        "updated_at": "2021-12-13T15:13:13.644280Z"
    },
    {
        "id": 705,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.644608Z",
        "updated_at": "2021-12-13T15:13:13.644615Z"
    },
    {
        "id": 706,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.644959Z",
        "updated_at": "2021-12-13T15:13:13.644965Z"
    },
    {
        "id": 707,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.645422Z",
        "updated_at": "2021-12-13T15:13:13.645427Z"
    },
    {
        "id": 708,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.645736Z",
        "updated_at": "2021-12-13T15:13:13.645742Z"
    },
    {
        "id": 709,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.646047Z",
        "updated_at": "2021-12-13T15:13:13.646054Z"
    },
    {
        "id": 710,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.646371Z",
        "updated_at": "2021-12-13T15:13:13.646378Z"
    },
    {
        "id": 711,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.646700Z",
        "updated_at": "2021-12-13T15:13:13.646707Z"
    },
    {
        "id": 712,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.647018Z",
        "updated_at": "2021-12-13T15:13:13.647025Z"
    },
    {
        "id": 713,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.647333Z",
        "updated_at": "2021-12-13T15:13:13.647340Z"
    },
    {
        "id": 714,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.647638Z",
        "updated_at": "2021-12-13T15:13:13.647644Z"
    },
    {
        "id": 715,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.647942Z",
        "updated_at": "2021-12-13T15:13:13.647948Z"
    },
    {
        "id": 716,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.648305Z",
        "updated_at": "2021-12-13T15:13:13.648312Z"
    },
    {
        "id": 717,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.648658Z",
        "updated_at": "2021-12-13T15:13:13.648664Z"
    },
    {
        "id": 718,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.648965Z",
        "updated_at": "2021-12-13T15:13:13.648971Z"
    },
    {
        "id": 719,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.649281Z",
        "updated_at": "2021-12-13T15:13:13.649287Z"
    },
    {
        "id": 720,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.649598Z",
        "updated_at": "2021-12-13T15:13:13.649604Z"
    },
    {
        "id": 721,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.649952Z",
        "updated_at": "2021-12-13T15:13:13.649958Z"
    },
    {
        "id": 722,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.650302Z",
        "updated_at": "2021-12-13T15:13:13.650309Z"
    },
    {
        "id": 723,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.650626Z",
        "updated_at": "2021-12-13T15:13:13.650633Z"
    },
    {
        "id": 724,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.651010Z",
        "updated_at": "2021-12-13T15:13:13.651017Z"
    },
    {
        "id": 725,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.651315Z",
        "updated_at": "2021-12-13T15:13:13.651321Z"
    },
    {
        "id": 726,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.651640Z",
        "updated_at": "2021-12-13T15:13:13.651647Z"
    },
    {
        "id": 727,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.651968Z",
        "updated_at": "2021-12-13T15:13:13.651975Z"
    },
    {
        "id": 728,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.652334Z",
        "updated_at": "2021-12-13T15:13:13.652341Z"
    },
    {
        "id": 729,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.652655Z",
        "updated_at": "2021-12-13T15:13:13.652662Z"
    },
    {
        "id": 730,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.652984Z",
        "updated_at": "2021-12-13T15:13:13.652991Z"
    },
    {
        "id": 731,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.653304Z",
        "updated_at": "2021-12-13T15:13:13.653310Z"
    },
    {
        "id": 732,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.653626Z",
        "updated_at": "2021-12-13T15:13:13.653633Z"
    },
    {
        "id": 733,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.653990Z",
        "updated_at": "2021-12-13T15:13:13.653996Z"
    },
    {
        "id": 734,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.654356Z",
        "updated_at": "2021-12-13T15:13:13.654363Z"
    },
    {
        "id": 735,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.654665Z",
        "updated_at": "2021-12-13T15:13:13.654672Z"
    },
    {
        "id": 736,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.654979Z",
        "updated_at": "2021-12-13T15:13:13.654984Z"
    },
    {
        "id": 737,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.655280Z",
        "updated_at": "2021-12-13T15:13:13.655286Z"
    },
    {
        "id": 738,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.655588Z",
        "updated_at": "2021-12-13T15:13:13.655595Z"
    },
    {
        "id": 739,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.655909Z",
        "updated_at": "2021-12-13T15:13:13.655916Z"
    },
    {
        "id": 740,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.656232Z",
        "updated_at": "2021-12-13T15:13:13.656238Z"
    },
    {
        "id": 741,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.656598Z",
        "updated_at": "2021-12-13T15:13:13.656604Z"
    },
    {
        "id": 742,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.656918Z",
        "updated_at": "2021-12-13T15:13:13.656925Z"
    },
    {
        "id": 743,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.657241Z",
        "updated_at": "2021-12-13T15:13:13.657248Z"
    },
    {
        "id": 744,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.657546Z",
        "updated_at": "2021-12-13T15:13:13.657552Z"
    },
    {
        "id": 745,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.657849Z",
        "updated_at": "2021-12-13T15:13:13.657856Z"
    },
    {
        "id": 746,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.658211Z",
        "updated_at": "2021-12-13T15:13:13.658217Z"
    },
    {
        "id": 747,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.658564Z",
        "updated_at": "2021-12-13T15:13:13.658570Z"
    },
    {
        "id": 748,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.658892Z",
        "updated_at": "2021-12-13T15:13:13.658898Z"
    },
    {
        "id": 749,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.659203Z",
        "updated_at": "2021-12-13T15:13:13.659209Z"
    },
    {
        "id": 750,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.659560Z",
        "updated_at": "2021-12-13T15:13:13.659566Z"
    },
    {
        "id": 751,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.659919Z",
        "updated_at": "2021-12-13T15:13:13.659926Z"
    },
    {
        "id": 752,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.660249Z",
        "updated_at": "2021-12-13T15:13:13.660255Z"
    },
    {
        "id": 753,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.660572Z",
        "updated_at": "2021-12-13T15:13:13.660578Z"
    },
    {
        "id": 754,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.660897Z",
        "updated_at": "2021-12-13T15:13:13.660903Z"
    },
    {
        "id": 755,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.661255Z",
        "updated_at": "2021-12-13T15:13:13.661261Z"
    },
    {
        "id": 756,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.661563Z",
        "updated_at": "2021-12-13T15:13:13.661570Z"
    },
    {
        "id": 757,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.661862Z",
        "updated_at": "2021-12-13T15:13:13.661868Z"
    },
    {
        "id": 758,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.662205Z",
        "updated_at": "2021-12-13T15:13:13.662212Z"
    },
    {
        "id": 759,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.662504Z",
        "updated_at": "2021-12-13T15:13:13.662510Z"
    },
    {
        "id": 760,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.662823Z",
        "updated_at": "2021-12-13T15:13:13.662831Z"
    },
    {
        "id": 761,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.663152Z",
        "updated_at": "2021-12-13T15:13:13.663159Z"
    },
    {
        "id": 762,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.663470Z",
        "updated_at": "2021-12-13T15:13:13.663476Z"
    },
    {
        "id": 763,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.663773Z",
        "updated_at": "2021-12-13T15:13:13.663780Z"
    },
    {
        "id": 764,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.664076Z",
        "updated_at": "2021-12-13T15:13:13.664082Z"
    },
    {
        "id": 765,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.664404Z",
        "updated_at": "2021-12-13T15:13:13.664410Z"
    },
    {
        "id": 766,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.664738Z",
        "updated_at": "2021-12-13T15:13:13.664745Z"
    },
    {
        "id": 767,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.665058Z",
        "updated_at": "2021-12-13T15:13:13.665065Z"
    },
    {
        "id": 768,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.665385Z",
        "updated_at": "2021-12-13T15:13:13.665391Z"
    },
    {
        "id": 769,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.665753Z",
        "updated_at": "2021-12-13T15:13:13.665759Z"
    },
    {
        "id": 770,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.666100Z",
        "updated_at": "2021-12-13T15:13:13.666106Z"
    },
    {
        "id": 771,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.666409Z",
        "updated_at": "2021-12-13T15:13:13.666416Z"
    },
    {
        "id": 772,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.666723Z",
        "updated_at": "2021-12-13T15:13:13.666730Z"
    },
    {
        "id": 773,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.667068Z",
        "updated_at": "2021-12-13T15:13:13.667075Z"
    },
    {
        "id": 774,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.667410Z",
        "updated_at": "2021-12-13T15:13:13.667416Z"
    },
    {
        "id": 775,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.667763Z",
        "updated_at": "2021-12-13T15:13:13.667770Z"
    },
    {
        "id": 776,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.668090Z",
        "updated_at": "2021-12-13T15:13:13.668096Z"
    },
    {
        "id": 777,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.668406Z",
        "updated_at": "2021-12-13T15:13:13.668412Z"
    },
    {
        "id": 778,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.668757Z",
        "updated_at": "2021-12-13T15:13:13.668763Z"
    },
    {
        "id": 779,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.669100Z",
        "updated_at": "2021-12-13T15:13:13.669106Z"
    },
    {
        "id": 780,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.669407Z",
        "updated_at": "2021-12-13T15:13:13.669414Z"
    },
    {
        "id": 781,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.669711Z",
        "updated_at": "2021-12-13T15:13:13.669717Z"
    },
    {
        "id": 782,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.670027Z",
        "updated_at": "2021-12-13T15:13:13.670033Z"
    },
    {
        "id": 783,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.670336Z",
        "updated_at": "2021-12-13T15:13:13.670342Z"
    },
    {
        "id": 784,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.670658Z",
        "updated_at": "2021-12-13T15:13:13.670663Z"
    },
    {
        "id": 785,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.670962Z",
        "updated_at": "2021-12-13T15:13:13.670967Z"
    },
    {
        "id": 786,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.671267Z",
        "updated_at": "2021-12-13T15:13:13.671273Z"
    },
    {
        "id": 787,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.671581Z",
        "updated_at": "2021-12-13T15:13:13.671586Z"
    },
    {
        "id": 788,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.671882Z",
        "updated_at": "2021-12-13T15:13:13.671887Z"
    },
    {
        "id": 789,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.672228Z",
        "updated_at": "2021-12-13T15:13:13.672235Z"
    },
    {
        "id": 790,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.672572Z",
        "updated_at": "2021-12-13T15:13:13.672578Z"
    },
    {
        "id": 791,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.672897Z",
        "updated_at": "2021-12-13T15:13:13.672904Z"
    },
    {
        "id": 792,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.673256Z",
        "updated_at": "2021-12-13T15:13:13.673262Z"
    },
    {
        "id": 793,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.673566Z",
        "updated_at": "2021-12-13T15:13:13.673572Z"
    },
    {
        "id": 794,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.673879Z",
        "updated_at": "2021-12-13T15:13:13.673885Z"
    },
    {
        "id": 795,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.674206Z",
        "updated_at": "2021-12-13T15:13:13.674212Z"
    },
    {
        "id": 796,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.674524Z",
        "updated_at": "2021-12-13T15:13:13.674531Z"
    },
    {
        "id": 797,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.674849Z",
        "updated_at": "2021-12-13T15:13:13.674856Z"
    },
    {
        "id": 798,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.675162Z",
        "updated_at": "2021-12-13T15:13:13.675169Z"
    },
    {
        "id": 799,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.675500Z",
        "updated_at": "2021-12-13T15:13:13.675505Z"
    },
    {
        "id": 800,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.675846Z",
        "updated_at": "2021-12-13T15:13:13.675852Z"
    },
    {
        "id": 801,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.676230Z",
        "updated_at": "2021-12-13T15:13:13.676237Z"
    },
    {
        "id": 802,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.676595Z",
        "updated_at": "2021-12-13T15:13:13.676601Z"
    },
    {
        "id": 803,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.676950Z",
        "updated_at": "2021-12-13T15:13:13.676957Z"
    },
    {
        "id": 804,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.677310Z",
        "updated_at": "2021-12-13T15:13:13.677317Z"
    },
    {
        "id": 805,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.677684Z",
        "updated_at": "2021-12-13T15:13:13.677690Z"
    },
    {
        "id": 806,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN LATERIZIO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.678063Z",
        "updated_at": "2021-12-13T15:13:13.678070Z"
    },
    {
        "id": 807,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.678460Z",
        "updated_at": "2021-12-13T15:13:13.678466Z"
    },
    {
        "id": 808,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.678880Z",
        "updated_at": "2021-12-13T15:13:13.678887Z"
    },
    {
        "id": 809,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.679307Z",
        "updated_at": "2021-12-13T15:13:13.679312Z"
    },
    {
        "id": 810,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.679720Z",
        "updated_at": "2021-12-13T15:13:13.679725Z"
    },
    {
        "id": 811,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.680107Z",
        "updated_at": "2021-12-13T15:13:13.680113Z"
    },
    {
        "id": 812,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.680498Z",
        "updated_at": "2021-12-13T15:13:13.680505Z"
    },
    {
        "id": 813,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.680888Z",
        "updated_at": "2021-12-13T15:13:13.680895Z"
    },
    {
        "id": 814,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.681264Z",
        "updated_at": "2021-12-13T15:13:13.681271Z"
    },
    {
        "id": 815,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.681651Z",
        "updated_at": "2021-12-13T15:13:13.681658Z"
    },
    {
        "id": 816,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.682026Z",
        "updated_at": "2021-12-13T15:13:13.682032Z"
    },
    {
        "id": 817,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.682381Z",
        "updated_at": "2021-12-13T15:13:13.682388Z"
    },
    {
        "id": 818,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.682717Z",
        "updated_at": "2021-12-13T15:13:13.682723Z"
    },
    {
        "id": 819,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.683049Z",
        "updated_at": "2021-12-13T15:13:13.683056Z"
    },
    {
        "id": 820,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.683413Z",
        "updated_at": "2021-12-13T15:13:13.683419Z"
    },
    {
        "id": 821,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.683760Z",
        "updated_at": "2021-12-13T15:13:13.683767Z"
    },
    {
        "id": 822,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN BLOCCHI DI PIETRA",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.684083Z",
        "updated_at": "2021-12-13T15:13:13.684090Z"
    },
    {
        "id": 823,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.684409Z",
        "updated_at": "2021-12-13T15:13:13.684417Z"
    },
    {
        "id": 824,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.684750Z",
        "updated_at": "2021-12-13T15:13:13.684756Z"
    },
    {
        "id": 825,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.685239Z",
        "updated_at": "2021-12-13T15:13:13.685245Z"
    },
    {
        "id": 826,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.685554Z",
        "updated_at": "2021-12-13T15:13:13.685561Z"
    },
    {
        "id": 827,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb00-1-1.jpg",
        "created_at": "2021-12-13T15:13:13.685887Z",
        "updated_at": "2021-12-13T15:13:13.685893Z"
    },
    {
        "id": 828,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "solution_type": "PARETI IN CARTONGESSO",
        "product_name": "Amotherm Gyps WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb16-.jpg",
        "created_at": "2021-12-13T15:13:13.686249Z",
        "updated_at": "2021-12-13T15:13:13.686255Z"
    },
    {
        "id": 829,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.686625Z",
        "updated_at": "2021-12-13T15:13:13.686632Z"
    },
    {
        "id": 830,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.686961Z",
        "updated_at": "2021-12-13T15:13:13.686968Z"
    },
    {
        "id": 831,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.687341Z",
        "updated_at": "2021-12-13T15:13:13.687347Z"
    },
    {
        "id": 832,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.687679Z",
        "updated_at": "2021-12-13T15:13:13.687686Z"
    },
    {
        "id": 833,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN LEGNO",
        "product_name": "Amotherm Wood WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe04-.jpg",
        "created_at": "2021-12-13T15:13:13.688035Z",
        "updated_at": "2021-12-13T15:13:13.688041Z"
    },
    {
        "id": 834,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN LEGNO",
        "product_name": "Amotherm Wood WSB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe33-.jpg",
        "created_at": "2021-12-13T15:13:13.688372Z",
        "updated_at": "2021-12-13T15:13:13.688378Z"
    },
    {
        "id": 835,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.688704Z",
        "updated_at": "2021-12-13T15:13:13.688710Z"
    },
    {
        "id": 836,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel WB HI",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/06/f-fc-fc33-.jpg",
        "created_at": "2021-12-13T15:13:13.689016Z",
        "updated_at": "2021-12-13T15:13:13.689023Z"
    },
    {
        "id": 837,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc31-.jpg",
        "created_at": "2021-12-13T15:13:13.689351Z",
        "updated_at": "2021-12-13T15:13:13.689357Z"
    },
    {
        "id": 838,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel SB HI",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/08/AMOTHERM-STEEL-SB_HI_1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.689749Z",
        "updated_at": "2021-12-13T15:13:13.689756Z"
    },
    {
        "id": 839,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Firetex",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/FIRETEX®-FX5120_20-L-Pail.jpg",
        "created_at": "2021-12-13T15:13:13.690091Z",
        "updated_at": "2021-12-13T15:13:13.690098Z"
    },
    {
        "id": 840,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.690399Z",
        "updated_at": "2021-12-13T15:13:13.690405Z"
    },
    {
        "id": 841,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.690709Z",
        "updated_at": "2021-12-13T15:13:13.690716Z"
    },
    {
        "id": 842,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.691067Z",
        "updated_at": "2021-12-13T15:13:13.691074Z"
    },
    {
        "id": 843,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.691411Z",
        "updated_at": "2021-12-13T15:13:13.691417Z"
    },
    {
        "id": 844,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN LEGNO",
        "product_name": "Amotherm Wood WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe04-.jpg",
        "created_at": "2021-12-13T15:13:13.691750Z",
        "updated_at": "2021-12-13T15:13:13.691756Z"
    },
    {
        "id": 845,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN LEGNO",
        "product_name": "Amotherm Wood WSB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe33-.jpg",
        "created_at": "2021-12-13T15:13:13.692069Z",
        "updated_at": "2021-12-13T15:13:13.692075Z"
    },
    {
        "id": 846,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.692393Z",
        "updated_at": "2021-12-13T15:13:13.692400Z"
    },
    {
        "id": 847,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel WB HI",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/06/f-fc-fc33-.jpg",
        "created_at": "2021-12-13T15:13:13.692722Z",
        "updated_at": "2021-12-13T15:13:13.692728Z"
    },
    {
        "id": 848,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc31-.jpg",
        "created_at": "2021-12-13T15:13:13.693086Z",
        "updated_at": "2021-12-13T15:13:13.693092Z"
    },
    {
        "id": 849,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel SB HI",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/08/AMOTHERM-STEEL-SB_HI_1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.693414Z",
        "updated_at": "2021-12-13T15:13:13.693421Z"
    },
    {
        "id": 850,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Firetex",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/FIRETEX®-FX5120_20-L-Pail.jpg",
        "created_at": "2021-12-13T15:13:13.693730Z",
        "updated_at": "2021-12-13T15:13:13.693736Z"
    },
    {
        "id": 851,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.694057Z",
        "updated_at": "2021-12-13T15:13:13.694063Z"
    },
    {
        "id": 852,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Brick WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fb-fb15-.jpg",
        "created_at": "2021-12-13T15:13:13.694374Z",
        "updated_at": "2021-12-13T15:13:13.694380Z"
    },
    {
        "id": 853,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Primer WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/10/Etichetta_Amotherm_-Primer_WB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.694697Z",
        "updated_at": "2021-12-13T15:13:13.694703Z"
    },
    {
        "id": 854,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN CLS",
        "product_name": "Amotherm Concrete WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/amotherm-concrete-wb-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.695010Z",
        "updated_at": "2021-12-13T15:13:13.695017Z"
    },
    {
        "id": 855,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN LEGNO",
        "product_name": "Amotherm Wood WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe04-.jpg",
        "created_at": "2021-12-13T15:13:13.695327Z",
        "updated_at": "2021-12-13T15:13:13.695334Z"
    },
    {
        "id": 856,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN LEGNO",
        "product_name": "Amotherm Wood WSB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe33-.jpg",
        "created_at": "2021-12-13T15:13:13.695653Z",
        "updated_at": "2021-12-13T15:13:13.695660Z"
    },
    {
        "id": 857,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc30-.jpg",
        "created_at": "2021-12-13T15:13:13.695984Z",
        "updated_at": "2021-12-13T15:13:13.695990Z"
    },
    {
        "id": 858,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel WB HI",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/06/f-fc-fc33-.jpg",
        "created_at": "2021-12-13T15:13:13.696307Z",
        "updated_at": "2021-12-13T15:13:13.696314Z"
    },
    {
        "id": 859,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fc-fc31-.jpg",
        "created_at": "2021-12-13T15:13:13.696694Z",
        "updated_at": "2021-12-13T15:13:13.696701Z"
    },
    {
        "id": 860,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Amotherm Steel SB HI",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2020/08/AMOTHERM-STEEL-SB_HI_1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.697009Z",
        "updated_at": "2021-12-13T15:13:13.697015Z"
    },
    {
        "id": 861,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "solution_type": "STRUTTURE IN ACCIAIO",
        "product_name": "Firetex",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/FIRETEX®-FX5120_20-L-Pail.jpg",
        "created_at": "2021-12-13T15:13:13.697351Z",
        "updated_at": "2021-12-13T15:13:13.697358Z"
    },
    {
        "id": 862,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "PAVIMENTAZIONI",
        "product_name": "Amotherm Wood Hydrolac",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe28-.jpg",
        "created_at": "2021-12-13T15:13:13.697679Z",
        "updated_at": "2021-12-13T15:13:13.697686Z"
    },
    {
        "id": 863,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "PAVIMENTAZIONI",
        "product_name": "Amotherm Wood 540 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe27-.jpg",
        "created_at": "2021-12-13T15:13:13.697995Z",
        "updated_at": "2021-12-13T15:13:13.698002Z"
    },
    {
        "id": 864,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 450 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe08-.jpg",
        "created_at": "2021-12-13T15:13:13.698309Z",
        "updated_at": "2021-12-13T15:13:13.698315Z"
    },
    {
        "id": 865,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe04-.jpg",
        "created_at": "2021-12-13T15:13:13.698648Z",
        "updated_at": "2021-12-13T15:13:13.698655Z"
    },
    {
        "id": 866,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood Top WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fme-fme35-.jpg",
        "created_at": "2021-12-13T15:13:13.698969Z",
        "updated_at": "2021-12-13T15:13:13.698975Z"
    },
    {
        "id": 867,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 461 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/02/AMOTHERM_WOOD_461_SB_QUARTETTO-scaled-e1614086453538.jpg",
        "created_at": "2021-12-13T15:13:13.699284Z",
        "updated_at": "2021-12-13T15:13:13.699291Z"
    },
    {
        "id": 868,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood WSB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe33-.jpg",
        "created_at": "2021-12-13T15:13:13.699606Z",
        "updated_at": "2021-12-13T15:13:13.699612Z"
    },
    {
        "id": 869,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 300 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/AMOTHERM-WOOD-300-SB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.699916Z",
        "updated_at": "2021-12-13T15:13:13.699922Z"
    },
    {
        "id": 870,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 480 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/AMOTHERM-WOOD-480-SB-1.jpg",
        "created_at": "2021-12-13T15:13:13.700225Z",
        "updated_at": "2021-12-13T15:13:13.700231Z"
    },
    {
        "id": 871,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "PAVIMENTAZIONI",
        "product_name": "Amotherm Wood Hydrolac",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe28-.jpg",
        "created_at": "2021-12-13T15:13:13.700583Z",
        "updated_at": "2021-12-13T15:13:13.700590Z"
    },
    {
        "id": 872,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "PAVIMENTAZIONI",
        "product_name": "Amotherm Wood 540 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe27-.jpg",
        "created_at": "2021-12-13T15:13:13.700912Z",
        "updated_at": "2021-12-13T15:13:13.700918Z"
    },
    {
        "id": 873,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 450 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe08-.jpg",
        "created_at": "2021-12-13T15:13:13.701229Z",
        "updated_at": "2021-12-13T15:13:13.701235Z"
    },
    {
        "id": 874,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe04-.jpg",
        "created_at": "2021-12-13T15:13:13.701530Z",
        "updated_at": "2021-12-13T15:13:13.701536Z"
    },
    {
        "id": 875,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood Top WB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fme-fme35-.jpg",
        "created_at": "2021-12-13T15:13:13.701874Z",
        "updated_at": "2021-12-13T15:13:13.701880Z"
    },
    {
        "id": 876,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 461 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/02/AMOTHERM_WOOD_461_SB_QUARTETTO-scaled-e1614086453538.jpg",
        "created_at": "2021-12-13T15:13:13.702190Z",
        "updated_at": "2021-12-13T15:13:13.702196Z"
    },
    {
        "id": 877,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood WSB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/f-fe-fe33-.jpg",
        "created_at": "2021-12-13T15:13:13.702523Z",
        "updated_at": "2021-12-13T15:13:13.702529Z"
    },
    {
        "id": 878,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 300 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/AMOTHERM-WOOD-300-SB-scaled.jpg",
        "created_at": "2021-12-13T15:13:13.702840Z",
        "updated_at": "2021-12-13T15:13:13.702847Z"
    },
    {
        "id": 879,
        "product_line": "amotherm",
        "solution": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "solution_type": "ELEMENTI DI RIVESTIMENTO",
        "product_name": "Amotherm Wood 480 SB",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/11/AMOTHERM-WOOD-480-SB-1.jpg",
        "created_at": "2021-12-13T15:13:13.703146Z",
        "updated_at": "2021-12-13T15:13:13.703152Z"
    },
    {
        "id": 880,
        "product_line": "bessemer",
        "solution": "Acqua",
        "solution_type": "",
        "product_name": "Bessemer Aqua Grund",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-aqua-grund-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.703452Z",
        "updated_at": "2021-12-13T15:13:13.703458Z"
    },
    {
        "id": 881,
        "product_line": "bessemer",
        "solution": "Acqua",
        "solution_type": "",
        "product_name": "Bessemer Aqua Super",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-aqua-super-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.703819Z",
        "updated_at": "2021-12-13T15:13:13.703826Z"
    },
    {
        "id": 882,
        "product_line": "bessemer",
        "solution": "Complementari",
        "solution_type": "",
        "product_name": "Bessemer Falzversiegelung",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2021/05/Bessemer-Blue-Water.jpg",
        "created_at": "2021-12-13T15:13:13.704130Z",
        "updated_at": "2021-12-13T15:13:13.704136Z"
    },
    {
        "id": 883,
        "product_line": "bessemer",
        "solution": "Complementari",
        "solution_type": "",
        "product_name": "Bessemer Reiniger W15",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-reiniger-w15-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.704438Z",
        "updated_at": "2021-12-13T15:13:13.704445Z"
    },
    {
        "id": 884,
        "product_line": "bessemer",
        "solution": "Complementari",
        "solution_type": "",
        "product_name": "Bessemer universal Spritzverduennung",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-universal-spritzverduennung-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.704741Z",
        "updated_at": "2021-12-13T15:13:13.704747Z"
    },
    {
        "id": 885,
        "product_line": "bessemer",
        "solution": "Complementari",
        "solution_type": "",
        "product_name": "Bessemer universal Streichverduennung",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-universal-streichverduennung-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.705037Z",
        "updated_at": "2021-12-13T15:13:13.705043Z"
    },
    {
        "id": 886,
        "product_line": "bessemer",
        "solution": "Olio",
        "solution_type": "",
        "product_name": "Bessemer Ambos",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-ambos-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.705336Z",
        "updated_at": "2021-12-13T15:13:13.705343Z"
    },
    {
        "id": 887,
        "product_line": "bessemer",
        "solution": "Olio",
        "solution_type": "",
        "product_name": "Bessemer Ambos Grund",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-ambos-grund-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.705661Z",
        "updated_at": "2021-12-13T15:13:13.705667Z"
    },
    {
        "id": 888,
        "product_line": "bessemer",
        "solution": "Olio",
        "solution_type": "",
        "product_name": "Bessemer Ambos Ultra",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-ambos-ultra-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.705986Z",
        "updated_at": "2021-12-13T15:13:13.705991Z"
    },
    {
        "id": 889,
        "product_line": "bessemer",
        "solution": "Solvente",
        "solution_type": "",
        "product_name": "Bessemer VB Grund",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-vb-grund-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.706289Z",
        "updated_at": "2021-12-13T15:13:13.706295Z"
    },
    {
        "id": 890,
        "product_line": "bessemer",
        "solution": "Solvente",
        "solution_type": "",
        "product_name": "Bessemer VB Super",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/07/bessemer-vb-super-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.706588Z",
        "updated_at": "2021-12-13T15:13:13.706594Z"
    },
    {
        "id": 891,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per falegnameria",
        "product_name": "Stufex E 80",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-ba-ba01-.jpg",
        "created_at": "2021-12-13T15:13:13.706948Z",
        "updated_at": "2021-12-13T15:13:13.706954Z"
    },
    {
        "id": 892,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per falegnameria",
        "product_name": "Stufex E 83 Plus",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-ba-ba02-.jpg",
        "created_at": "2021-12-13T15:13:13.707327Z",
        "updated_at": "2021-12-13T15:13:13.707333Z"
    },
    {
        "id": 893,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex 2000 Hflex",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/unnamed-file.jpg",
        "created_at": "2021-12-13T15:13:13.707685Z",
        "updated_at": "2021-12-13T15:13:13.707691Z"
    },
    {
        "id": 894,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex 2001",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl15-.jpg",
        "created_at": "2021-12-13T15:13:13.708041Z",
        "updated_at": "2021-12-13T15:13:13.708048Z"
    },
    {
        "id": 895,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex E 83 P",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl02-.jpg",
        "created_at": "2021-12-13T15:13:13.708367Z",
        "updated_at": "2021-12-13T15:13:13.708374Z"
    },
    {
        "id": 896,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex P 90",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl03-.jpg",
        "created_at": "2021-12-13T15:13:13.708676Z",
        "updated_at": "2021-12-13T15:13:13.708682Z"
    },
    {
        "id": 897,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex P 90 E",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl04-.jpg",
        "created_at": "2021-12-13T15:13:13.708990Z",
        "updated_at": "2021-12-13T15:13:13.708996Z"
    },
    {
        "id": 898,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex PL 200",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/stufex-pl-200-a-b-1200x1200.jpg",
        "created_at": "2021-12-13T15:13:13.709330Z",
        "updated_at": "2021-12-13T15:13:13.709337Z"
    },
    {
        "id": 899,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex PL 300",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl08-.jpg",
        "created_at": "2021-12-13T15:13:13.709651Z",
        "updated_at": "2021-12-13T15:13:13.709657Z"
    },
    {
        "id": 900,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex PL 96",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl09-.jpg",
        "created_at": "2021-12-13T15:13:13.709964Z",
        "updated_at": "2021-12-13T15:13:13.709970Z"
    },
    {
        "id": 901,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex PL 97",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl10-.jpg",
        "created_at": "2021-12-13T15:13:13.710283Z",
        "updated_at": "2021-12-13T15:13:13.710289Z"
    },
    {
        "id": 902,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Colle per pavimenti",
        "product_name": "Stufex S 100",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bl-bl20-.jpg",
        "created_at": "2021-12-13T15:13:13.710596Z",
        "updated_at": "2021-12-13T15:13:13.710603Z"
    },
    {
        "id": 903,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Pulitore per Colle",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by99-.jpg",
        "created_at": "2021-12-13T15:13:13.710898Z",
        "updated_at": "2021-12-13T15:13:13.710904Z"
    },
    {
        "id": 904,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Primer per pavimenti",
        "product_name": "Stufex PR 100",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bm-bm05-.jpg",
        "created_at": "2021-12-13T15:13:13.711198Z",
        "updated_at": "2021-12-13T15:13:13.711204Z"
    },
    {
        "id": 905,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Primer per pavimenti",
        "product_name": "Stufex PR 91",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bm-bm02-.jpg",
        "created_at": "2021-12-13T15:13:13.711505Z",
        "updated_at": "2021-12-13T15:13:13.711512Z"
    },
    {
        "id": 906,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Primer per pavimenti",
        "product_name": "Stufex PR 92",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bm-bm03-.jpg",
        "created_at": "2021-12-13T15:13:13.711842Z",
        "updated_at": "2021-12-13T15:13:13.711849Z"
    },
    {
        "id": 907,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Primer per pavimenti",
        "product_name": "Stufex PR 93",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bm-bm04-1.jpg",
        "created_at": "2021-12-13T15:13:13.712146Z",
        "updated_at": "2021-12-13T15:13:13.712152Z"
    },
    {
        "id": 908,
        "product_line": "stufex",
        "solution": "Adesivi e preparatori",
        "solution_type": "Stufex – Primer per pavimenti",
        "product_name": "Stufex PR 99",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bm-bm01-.jpg",
        "created_at": "2021-12-13T15:13:13.712444Z",
        "updated_at": "2021-12-13T15:13:13.712450Z"
    },
    {
        "id": 909,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 001",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by01-.jpg",
        "created_at": "2021-12-13T15:13:13.712745Z",
        "updated_at": "2021-12-13T15:13:13.712752Z"
    },
    {
        "id": 910,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 002",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by02-.jpg",
        "created_at": "2021-12-13T15:13:13.713109Z",
        "updated_at": "2021-12-13T15:13:13.713116Z"
    },
    {
        "id": 911,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 003",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by03-.jpg",
        "created_at": "2021-12-13T15:13:13.713437Z",
        "updated_at": "2021-12-13T15:13:13.713444Z"
    },
    {
        "id": 912,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 004",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by04-.jpg",
        "created_at": "2021-12-13T15:13:13.713781Z",
        "updated_at": "2021-12-13T15:13:13.713787Z"
    },
    {
        "id": 913,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 011",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by11-.jpg",
        "created_at": "2021-12-13T15:13:13.714092Z",
        "updated_at": "2021-12-13T15:13:13.714099Z"
    },
    {
        "id": 914,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 012",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by12-.jpg",
        "created_at": "2021-12-13T15:13:13.714400Z",
        "updated_at": "2021-12-13T15:13:13.714406Z"
    },
    {
        "id": 915,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Complementari",
        "product_name": "Stufex Diluente 019",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-by-by19-.jpg",
        "created_at": "2021-12-13T15:13:13.714711Z",
        "updated_at": "2021-12-13T15:13:13.714717Z"
    },
    {
        "id": 916,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Fondi per pavimenti",
        "product_name": "Stufex R 1551",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo05-.jpg",
        "created_at": "2021-12-13T15:13:13.715030Z",
        "updated_at": "2021-12-13T15:13:13.715037Z"
    },
    {
        "id": 917,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Fondi per pavimenti",
        "product_name": "Stufex R 24",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo01-.jpg",
        "created_at": "2021-12-13T15:13:13.715353Z",
        "updated_at": "2021-12-13T15:13:13.715360Z"
    },
    {
        "id": 918,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Fondi per pavimenti",
        "product_name": "Stufex R 31",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo03-.jpg",
        "created_at": "2021-12-13T15:13:13.715660Z",
        "updated_at": "2021-12-13T15:13:13.715667Z"
    },
    {
        "id": 919,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Stucchi per pavimenti",
        "product_name": "Stufex Gel Stucco",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bn-bn03-.jpg",
        "created_at": "2021-12-13T15:13:13.715974Z",
        "updated_at": "2021-12-13T15:13:13.715980Z"
    },
    {
        "id": 920,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Stucchi per pavimenti",
        "product_name": "Stufex R 1500",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bn-bn01-.jpg",
        "created_at": "2021-12-13T15:13:13.716336Z",
        "updated_at": "2021-12-13T15:13:13.716342Z"
    },
    {
        "id": 921,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Stucchi per pavimenti",
        "product_name": "Stufex Stucco VT",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bn-bn02-.jpg",
        "created_at": "2021-12-13T15:13:13.716666Z",
        "updated_at": "2021-12-13T15:13:13.716673Z"
    },
    {
        "id": 922,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Vernici per pavimenti",
        "product_name": "Stufex Hydrolac",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo13-.jpg",
        "created_at": "2021-12-13T15:13:13.716980Z",
        "updated_at": "2021-12-13T15:13:13.716987Z"
    },
    {
        "id": 923,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Vernici per pavimenti",
        "product_name": "Stufex R 1600",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo07-.jpg",
        "created_at": "2021-12-13T15:13:13.717345Z",
        "updated_at": "2021-12-13T15:13:13.717351Z"
    },
    {
        "id": 924,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Vernici per pavimenti",
        "product_name": "Stufex R 2660",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo16-.jpg",
        "created_at": "2021-12-13T15:13:13.717680Z",
        "updated_at": "2021-12-13T15:13:13.717687Z"
    },
    {
        "id": 925,
        "product_line": "stufex",
        "solution": "Finiture e fondi",
        "solution_type": "Stufex – Vernici per pavimenti",
        "product_name": "Stufex R 290",
        "product_image": "https://www.amonncolor.com/wp-content/uploads/2019/08/b-bo-bo14-.jpg",
        "created_at": "2021-12-13T15:13:13.717991Z",
        "updated_at": "2021-12-13T15:13:13.717997Z"
    }
]