export default {
    "IT": {
        "FILTER": "FILTRO",
        "form_title": "Compila il form per ottenere subito il file BIM:",
        "Society": "Società",
        "Client type": "Tipologia committente:",
        "Company": "Impresa",
        "Planner": "Progettista",
        "Public body": "Ente pubblico",
        "Worksite address": "Indirizzo cantiere",
        "Worksite": "cantiere:",
        "Airport": "Aeroporto",
        "School": "Scuola",
        "Hospital": "Ospedale",
        "Public Building": "Edificio pubblico",
        "Directional Building": "Edificio direzionale",
        "Shopping centres": "Centro commerciale",
        "Other": "Altro:",
        "Street name and number": "Via",
        "Town": "Città",
        "Fire reaction class": "Classe di resistenza al fuoco",
        "Required solution": "Soluzione richiesta",
        "Form_thanks_message": "Grazie per la richiesta! Scarica subito i file BIM della soluzione scelta.",
        "Form_contact_message": "E' possibile scaricare solo un pacchetto BIM per volta, se hai necessità di ricevere più soluzioni, contatta direttamente il nostro ufficio tecnico: ingass@amonncolor.com",
        "Download document": "Scarica documento",
        "alert message": "E' possibile scaricare solo un pacchetto BIM per volta, se hai necessità di ricevere più soluzioni, contatta direttamente il nostro ufficio tecnico: ingass@amonncolor.com",
        "Libreria BIM disponibile solo per Amotherm. \n Seleziona una soluzione nei filteri per \nvisulizzare i documenti BIM.": "Libreria BIM disponibile solo per Amotherm. \n Seleziona una soluzione nei filtri per \nVisualizzare i documenti BIM."
    },
    "EN": {
        "FILTER": "FILTER",
        "form_title": "Fill out the form to request the document:",
        "Society": "Society",
        "Client type": "Client type:",
        "Company": "Company",
        "Planner": "Planner",
        "Public body": "Public body",
        "Worksite": "Worksite",
        "Worksite address": "Worksite address",
        "Airport": "Airport",
        "School": "School",
        "Hospital": "Hospital",
        "Public Building": "Public Building",
        "Directional Building": "Directional Building",
        "Shopping centres": "Shopping centres",
        "Other": "Other:",
        "Street name and number": "Street name and number",
        "Town": "Town",
        "Fire reaction class": "Fire reaction class",
        "Required solution": "Required solution:",
        "Form_thanks_message": "Thank you for your request! Download the BIM files of your chosen solution here.",
        "Form_contact_message": "It is only possible to download one BIM package at a time, if you need more than one solution, please contact our technical department directly: ingass@amonncolor.com",
        "Download document": "Download document",
        "alert message": "It is only possible to download one BIM package at a time, if you need more than one solution, please contact our technical department directly: ingass@amonncolor.com",
        "Libreria BIM disponibile solo per Amotherm. \n Seleziona una soluzione nei filteri per \nvisulizzare i documenti BIM.": "BIM library currently only available for Amotherm \n Select a solution to view BIM documents"
    },
    "DE": {
        "form_title": "Füllen Sie das Formular aus, um Zugriff auf das gewünschte Dokument zu erhalten:",
        "Society": "Unternehmen",
        "Client type": "Kundentyp:",
        "Company": "Firma",
        "Planner": "Planer",
        "Public body": "Körperschaft des öffentlichen Rechts",
        "Worksite address": "Adresse der Baustelle",
        "Worksite": "Baustelle:",
        "Airport": "Flughafen",
        "School": "Schule",
        "Hospital": "Krankenhaus",
        "Public Building": "Öffentliches Gebäude",
        "Directional Building": "Bürogebäude",
        "Shopping centres": "Einkaufszentrum",
        "Other": "Sonstiges:",
        "Street name and number": "Straße",
        "Town": "Stadt",
        "Fire reaction class": "Feuerwiderstandsklasse",
        "Required solution": "Benötigte Lösung:",
        "Form_thanks_message": "Vielen Dank für Ihre Anfrage! Downloaden Sie hier die angeforderten BIM-Dateien.",
        "Form_contact_message": "Es kann jeweils nur ein BIM-Paket heruntergeladen werden. Wenn Sie mehr als eine Lösung benötigen, wenden Sie sich bitte direkt an unseren technischen Service: ingass@amonncolor.com",
        "Download document": "Dokument herunterladen",
        "alert message": "Es kann jeweils nur ein BIM-Paket heruntergeladen werden. Wenn Sie mehr als eine Lösung benötigen, wenden Sie sich bitte direkt an unseren technischen Service: ingass@amonncolor.com",
        "FILTER": "FILTER",
        "Libreria BIM disponibile solo per Amotherm. \n Seleziona una soluzione nei filteri per \nvisulizzare i documenti BIM.": "BIM-Bibliothek derzeit nur für Amotherm verfügbar\nverfügbar Wählen Sie eine Lösung aus,\num BIM-Dokumente anzuzeigen\n"

    },
    "ES": {
        "FILTER": "FILTRAR",
        "form_title": "Completa el formulario para obtener en seguida el documento:",
        "Society": "Empresa",
        "Client type": "Tipo de cliente:",
        "Company": "Empresa",
        "Planner": "Diseñador",
        "Public body": "Organismo público",
        "Worksite address": "Dirección de la obra:",
        "Worksite": "Obra:",
        "Airport": "Aeropuerto",
        "School": "Escuela",
        "Hospital": "Hospital",
        "Public Building": "Edificio público",
        "Directional Building": "Edificio de oficinas",
        "Shopping centres": "Centro comercial",
        "Other": "Otro:",
        "Street name and number": "Vía",
        "Town": "Ciudad",
        "Fire reaction class": "Clase de resistencia al fuego",
        "Required solution": "Solución solicitada:",
        "Form_thanks_message": "Gracias por su solicitud. Descargue ahora los archivos BIM de la solución elegida.",
        "Form_contact_message": "Sólo es posible descargar un documento BIM a la vez, si necesitas más de una solución contacta directamente nuestro departamento técnico: ingass@amonncolor.com.",
        "Download document": "Descarga documento",
        "alert message": "Sólo es posible descargar un documento BIM a la vez, si necesitas más de una solución contacta directamente nuestro departamento técnico: ingass@amonncolor.com",
        "Libreria BIM disponibile solo per Amotherm. \n Seleziona una soluzione nei filteri per \nvisulizzare i documenti BIM.": "Librería BIM actualmente disponible sólo para Amotherm \n Selecciona una solución para visualizar los documentos BIM"
    },


}