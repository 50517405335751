import { Dimensions, Platform, PixelRatio } from 'react-native';

const {
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
} = Dimensions.get('window');

// based on iphone 5s's scale
var normal = SCREEN_WIDTH > 786 ? 1366 : 409;
const scale = SCREEN_WIDTH / normal;

export function normalize(size, j) {
  const newSize = size * scale
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize))
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
  }
}
// "https://myadmin.myamonn.com/download/?name=SDS_CS(CZ)AQUA_PROFILASUR_R_3051-5.PDF,type=SDS"