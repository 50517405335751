import * as React from 'react';
import { Text, View, SafeAreaView,Image ,StyleSheet,ActivityIndicator } from 'react-native';
import { WebView } from 'react-native-webview';
import { getStatusBarHeight } from "react-native-status-bar-height";
import * as theme from "../../Theme";
import * as Linking from 'expo-linking';
const navbarHeight = getStatusBarHeight();
function LoadingIndicatorView() {
  return  <View style={styles.activityIndicatorStyle}>
            <Image
              // style={{width: normalize(200), height:normalize(200)}}
              source={require("../../../assets/MyAmonn_LoadingScreen.gif")} />
          </View>
}
const  Contact=()=> {
  return (
    window.open('https://www.amonncolor.com/contatto/', '_blank')
    // <WebView
    //       style={{marginTop:navbarHeight}}
    //       originWhitelist={['*']}
    //       source={{ uri: 'https://www.amonncolor.com/contatto/' }}  
    //       renderLoading={LoadingIndicatorView}
    //       onLoadStart={() => (LoadingIndicatorView())}
    //       onLoad={() => LoadingIndicatorView()}
    //       javaScriptEnabled={true}
    //       //For the Cache
    //       domStorageEnabled={true}
    //       //Want to show the view or not
    //       startInLoadingState={true}
    //     />
  );
}
export default Contact;
const styles = StyleSheet.create({
  activityIndicatorStyle: {
    flex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

