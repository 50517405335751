import React ,{ useEffect, useRef, useState }from 'react';
import {View,Dimensions,Text,TouchableHighlight,FlatList,ActivityIndicator,TouchableOpacity,Image,TextInput,ScrollView} from "react-native";
import { normalize } from '../../Helpers/Normalizer';
import Modal from "modal-react-native-web";
import styles from './styles';

const Alert=({message,setAlert})=>{
    const [showReqForm,setShowReqForm]=useState(true);
    return(
        <Modal
            animationType="fade"
            transparent={true}
            visible={showReqForm}
            onRequestClose={() => {
                setShowReqForm(!showReqForm);
            }}
        >
            <ScrollView contentContainerStyle={styles.centeredView}>
            
                <View style={styles.modalView}>
                <   View style={{alignSelf:"flex-start",marginLeft:normalize(20)}}>
                        <Text style={{fontFamily:"OpenSansCondensedBold",fontSize:normalize(18)}}>{"ALERT:"}</Text>
                    </View>
                    <View style={{alignSelf:"flex-start",marginLeft:normalize(20)}}>
                        <Text style={{fontFamily:"OpenSansCondensedLight",fontSize:normalize(18)}}>{message}</Text>

                    </View>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => {
                            setAlert();
                            setShowReqForm(!setShowReqForm)}}
                        >
                        <Text style={styles.textStyle}>OK</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </Modal>
         
    );
}
export default Alert