import React ,{ useEffect, useRef, useState }from 'react';
import {View,Dimensions,Text,TouchableHighlight,FlatList,TouchableOpacity,Image,Platform,KeyboardAvoidingView,Keyboard,TouchableWithoutFeedback,ActivityIndicator} from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import axios from 'axios';
import styles from './styles';
import BottomButtons from '../../Components/BottomButtons/BottomButtons';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { normalize } from '../../Helpers/Normalizer';
import BottomSheet from 'reanimated-bottom-sheet';
import RadixFilter from '../../Components/BottomSheetContent/RadixFiler';
// import { normalize } from 'react-native-responsive-fontsize';
import TextTicker from 'react-native-text-ticker';
import * as theme from "../../Theme";
import { downloadFile } from '../../Helpers/downloadDocument';
import { getDocuments } from '../../Helpers/downloadDocument';
import { deleteFile } from '../../Helpers/downloadDocument';
import { openMyDocument } from '../../Helpers/downloadDocument';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MPS_TRANS from '../MyProducts/MPS_TRANS';
const width = Dimensions.get('window').width;

const RadixDashboard = ({route,navigation}) => {
    const keyboardVerticalOffset = Platform.OS === 'ios' ? normalize(80) :0
    const [radixDocuments,setradixDocuments]=useState(route.params.radixDocuments);
    const [query,setQuery]=useState("");
    const [openFilter,setOpenFilter]=useState(false);
    const [docTypeFilter,setDocTypeFilter]=useState([]);
    const [dateFilter,setDateFilter]=useState([]);
    const [downloading,setDownloading]=useState("");
    const [downloadedDocuments,setDownloadedDocuments]=useState([]);
    const [language,setLanguage]=useState(route.params.language);
    useEffect(()=>{
        const getLanguage= ()=>{
            AsyncStorage.getItem("language").then((response)=>{
              if(response!=null){
                  setLanguage(JSON.parse(response));
                  console.log(language);
                }
              })
          }
        getLanguage();
        console.log(language);
    })
    useEffect(() => {
        AsyncStorage.getItem("token").then((response)=>{
            if(JSON.parse(response)==null){
                console.log(response,route)
                navigation.navigate("Login",{route:route});
            };
            })
    },[route]);
    const today=new Date()

    const downloadDoc=async (name,type)=>{
        await downloadFile(name,type,"radix",()=>{setDownloading("");});
    }
    const settingDownlaodedDocuments=async()=>{
        setDownloadedDocuments(await getDocuments());   
    }
    useEffect(()=>{
    //     const setup=async ()=>{
    //         await settingDownlaodedDocuments();
    //     }
    //    setup();
        // alert(radixDocuments);
       return()=>{
           return null;
       }
    });
    return (
    <View style={styles.container}>
        <KeyboardAvoidingView
                behavior= {Platform.OS === 'ios' ?'position':'height' }keyboardVerticalOffset={keyboardVerticalOffset}>
        <View
            style={{
                flexDirection:"row",
                justifyContent:"space-between",
                paddingTop:normalize(50),
                paddingHorizontal:normalize(20),
                backgroundColor:"white",
            }}
        >
        <BottomButtons
            Buttons={1}
            buttonText1={"My Radix"}
        />
        <TouchableOpacity
                onPress={()=>{
                    navigation.navigate("Dashboard");
                }}>
                <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
        </TouchableOpacity>        
        </View>
        <View style={styles.bottomTab}>
        <TouchableOpacity style={styles.radixLogout} 
            onPress={()=>{
                navigation.navigate("RadixLogin",{language:language});
            }}
            >
            <Image
                style={{height:normalize(15),width:normalize(15)}} 
                source={require("../../../assets/myamonn-icons/logout.png")} />
        </TouchableOpacity>
        <SearchBar width={width>786?544:220} language={language} onChangeText={(query)=>setQuery(query)}/>
        <TouchableOpacity style={styles.filterBtn}
            onPress={()=>{
                setOpenFilter(prev=>!prev);
            }}
            >
            <Text style={styles.filterText}>{MPS_TRANS[language]["FILTER"]}</Text>
            <Icon
                name="chevron-up"
                color="white"
                size={normalize(25)}
            />
        </TouchableOpacity>
        
        </View>
        </KeyboardAvoidingView>
        {openFilter?
        <RadixFilter language={language} doFilter={(documentType,date)=>{
                setDocTypeFilter(documentType); 
                setDateFilter(date);
            }} 
                openFilter={()=>setOpenFilter(false)}/>:null}
        <FlatList
            data={radixDocuments.filter(docname=>dateFilter.some(date=>(docname.name).includes(date)) && docTypeFilter.some(filter=>(docname.name).includes(filter)) || dateFilter.length==0 && docTypeFilter.some(filter=>(docname.name).includes(filter)) || dateFilter.some(date=>(docname.name).includes(date)) && docTypeFilter.length==0 || docTypeFilter.length==0 && dateFilter.length==0)}
            contentContainerStyle={{
                justifyContent:"center",
                alignItems:'center',
                marginVertical:normalize(8,735)
            }}
            renderItem={({item ,index}) => {
            let q=query.toLowerCase();
            let name=String(item.name).toLowerCase();
            if(name.includes(q) || q=="" && name!=""){
                 return(
                    <TouchableHighlight
                        underlayColor="none"
                        onPress={()=>{
                            downloadedDocuments.includes(String(item.name)+"&radix")?openMyDocument(String(item.name)+"&radix"):null;
                        }}
                    >
                        
                        <View 
                            style={{ 
                                backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
                                width:width>786?normalize(800):normalize(380),
                                height:normalize(60,735),
                                marginHorizontal:normalize(40),
                                paddingHorizontal:normalize(20),
                                justifyContent:"center",
                                alignContent:"center", }}>
                            <View style={{flexDirection:"row",justifyContent:"space-between"}}>
                            <View>
                                <View 
                                    style={{
                                        width:width>786?normalize(500):normalize(330),
                                        // alignItems:"center",
                                        // justifyContent:"center"
                                    }}
                                >
                                <TextTicker
                                    duration={9000}
                                    bounce
                                    style={{
                                        color:"#333333",
                                        fontFamily:"OpenSansCondensedBold",
                                        fontSize:normalize(16),
                                        marginLeft:normalize(5),
                                        
                                    }}>{item.name}</TextTicker>
                                </View>
                                <Text style={styles.amotherm}>{"Amotherm"}</Text>
                            </View>
                            <View style={styles.downloadBtn}>
                                {downloading==item.name?
                                    <Image
                                        style={{width: normalize(50), height:normalize(50)}}
                                        source={require("../../../assets/MyAmonn_loading_red.gif")} />
                                    :
                                    [downloadedDocuments.includes(String(item.name)+"&radix")?
                                    <TouchableOpacity
                                        onPress={()=>{
                                            deleteFile(String(item.name)+"&radix")
                                        }}
                                        >
                                        <Icon
                                            name={"delete"}
                                            color={"grey"}
                                            size={normalize(25)}
                                            />
                                    </TouchableOpacity>:
                                    <TouchableOpacity
                                        onPress={()=>{
                                            setDownloading(item.name);
                                            console.log((item.name.split("_"))[1].toLowerCase());
                                            downloadDoc(item.name,(item.name.split("_"))[1].toLowerCase());
                                        }}
                                        >
                                        <Icon
                                            name={"arrow-down-circle"}
                                            color={"grey"}
                                            size={normalize(25)}
                                            />
                                    </TouchableOpacity>]
                                }
                            </View>
                        </View>
                        </View>
                        
                    </TouchableHighlight>)}
            }}
            numColumns={1}
            key={"1"}
        />
        
    </View>
);};


export default RadixDashboard;

