import { StyleSheet,Dimensions} from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
// import { normalize } from "react-native-responsive-fontsize";
import * as theme from "../../Theme";
import { normalize } from "../../Helpers/Normalizer";
const styles = StyleSheet.create({
    container:{
        flex:1,
        justifyContent:"center"
    },
    logo:{
        height:normalize(30.33),
        width:normalize(200),
    },
})
export default styles;