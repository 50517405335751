export default {
    "IT": {
        "L'ACCESSO A RADIX È DEDICATO ESCLUSIVAMENTE AI CLIENTI \n AMONN.\n SE NON POSSIEDI UN USER ID PER ACCEDERE, CONTATTACI A \nINFO@AMONNCOLOR.COM\n PER SAPERE COME RICHIEDERNE UNO.": "L'ACCESSO A RADIX È DEDICATO ESCLUSIVAMENTE AI CLIENTI \n AMONN.\n SE NON POSSIEDI UN USER ID PER ACCEDERE, CONTATTACI A \nINFO@AMONNCOLOR.COM\n PER SAPERE COME RICHIEDERNE UNO.",
    },
    "DE": {
        "L'ACCESSO A RADIX È DEDICATO ESCLUSIVAMENTE AI CLIENTI \n AMONN.\n SE NON POSSIEDI UN USER ID PER ACCEDERE, CONTATTACI A \nINFO@AMONNCOLOR.COM\n PER SAPERE COME RICHIEDERNE UNO.": "Der Zugang zu Radix ist ausschließlich \nAmonnColor-Kunden vorbehalten.\n Fehlt Ihnen die Benutzer-ID zur Anmeldung? Kontaktieren Sie uns unter \nINFO@AMONNCOLOR.COM\n um diese anzufordern. ",
    },
    "ES": {
        "L'ACCESSO A RADIX È DEDICATO ESCLUSIVAMENTE AI CLIENTI \n AMONN.\n SE NON POSSIEDI UN USER ID PER ACCEDERE, CONTATTACI A \nINFO@AMONNCOLOR.COM\n PER SAPERE COME RICHIEDERNE UNO.": "El accesso a Radix está reservado exclusivamente a clientes de \nAmonnColor.\n Si no dispones de una identificación de usuario para acceder, ponte en contacto con nosotros en \ninfo@amonncolor.com\n para saber cómo solicitarla.",

    },
    "EN": {
        "L'ACCESSO A RADIX È DEDICATO ESCLUSIVAMENTE AI CLIENTI \n AMONN.\n SE NON POSSIEDI UN USER ID PER ACCEDERE, CONTATTACI A \nINFO@AMONNCOLOR.COM\n PER SAPERE COME RICHIEDERNE UNO.": "Access to Radix is reserved exclusively for \n AmonnColor customers. \n If you don't have a user id to log in,contact us at \n info@amonncolor.com\n to find out how to request one."
    },

}