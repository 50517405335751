import React,{useState,useEffect,useCallback}from 'react';
import {View,Text,Image,Dimensions,SafeAreaView,TouchableOpacity,KeyboardAvoidingView,Keyboard,TouchableWithoutFeedback} from "react-native";
import { FlatList } from 'react-native-gesture-handler';
import ProductButton from '../ProductButton/ProductButton';
import styles from './styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { getSolutions } from '../../Helpers/getSolutions';
import { normalize } from '../../Helpers/Normalizer';
// import { normalize } from 'react-native-responsive-fontsize';
import Product_Filter_Translation from './Product_Filter_Translation';

const BIMFilter=({doFilter,openFilter,language})=>{
    const [langFilter,setLangFilter]=useState("IT");
    const [solutionFilter,setSolutionFilter]=useState("");
    const [solutions,setSolutions]=useState([]);
    const gets =useCallback(async()=>{
        setSolutions(await getSolutions("amotherm"));
    },[solutions])
    useEffect(()=>{ 
        gets();
    },[gets])
    useEffect(()=>{
        
        
     },[]);
    return(
        <SafeAreaView style={
            {
                height: normalize(480),
            }}>
             <View
                style={{
                    backgroundColor: 'white',
                    paddingBottom:0,
                    height: normalize(480),
                }}>
                <View style={styles.radixbody}>
                    <Text
                        style={{
                            fontFamily:"OpenSansCondensedLight",
                            fontSize:normalize(16,735),
                            color:"#333333",
                            alignSelf:'center'
                        }}
                        >{Product_Filter_Translation[language].language}</Text>
                    <View style={styles.docbody}>
                        <View style={styles.bodyleft}>
                            <ProductButton width={160} productName={"ITA"} onPress={()=>{setLangFilter("IT")}} color={langFilter!="IT"?"pink":"red"}/>
                        </View>
                        <View style={styles.bodyright}>
                            <ProductButton width={160} productName={"ENG"} onPress={()=>{setLangFilter("EN")}} color={langFilter!="EN"?"pink":"red"}/>
                        </View>
                    </View>
                    <View style={{paddingTop:normalize(10,735)}}>
                        <Text
                            style={{
                                fontFamily:"OpenSansCondensedLight",
                                fontSize:normalize(16,735),
                                color:"#333333",
                                alignSelf:'center'
                            }}
                            >{Product_Filter_Translation[language].Soluzione}</Text>
                    </View>
                    <View style={styles.docbody}>
                    <FlatList
                        data={
                            solutions.filter((thing, index, self) =>
                               index === self.findIndex((t) => (
                                   t.solution === thing.solution
                               ))
                               )
                        }

                        contentContainerStyle={{
                            justifyContent:"center",
                            alignItems:"center",
                        }}
                        style={{
                            width:normalize(350),
                            height:normalize(200,735),
                            marginVertical:normalize(10,735),
                        }}
                        renderItem={({item ,index}) => (
                            <View
                                style={{
                                    marginTop:normalize(10)
                                }}
                                >
                                <ProductButton width={340} productName={Product_Filter_Translation[langFilter][item.solution]} onPress={()=>{setSolutionFilter(Product_Filter_Translation[langFilter][item.solution])}} color={solutionFilter!=Product_Filter_Translation[langFilter][item.solution]?"pink":"red"}/>
                            </View>
                        )}
                        keyExtractor={item => item.id.toString()}
                    />
                    {/* <View style={styles.bodyleft}>
                    </View> */}
                    </View>
                   
                </View>
                
                <View style={styles.footer}>
                    <ProductButton 
                        width={124}
                        productName={"CERCA"} 
                        color={"red"}
                        onPress={()=>{
                            doFilter(solutionFilter,langFilter);
                            openFilter();
                        }}
                    />
                    
                </View>
            </View>   
            {/* <View 
                onTouchEnd={()=>{
                    openFilter.current.snapTo(0);
                }}
                style={{
                    borderBottomWidth:2,
                    borderBottomColor:"red",
                    alignItems:"center",
                    justifyContent:"flex-end",
                    backgroundColor:'rgba(52, 52, 52, 0.8)',
                    height: normalize(400),
                    }}>
                <Icon
                    name="reorder-horizontal"
                    color="red"
                    size={25}/>
            </View> */}
           
        </SafeAreaView>
    );
}
export default BIMFilter;