export default {
    "IT": {
        "Benvenuto su MyAmonn": "Benvenuto su MyAmonn.",
        "selection": "Si prega di selezionare la gamma di prodotti",
        "nizia a creare la tua bacheca personale": "Inizia a creare la tua bacheca personale:",
        "message": {
            "lignex": "Protezione del legno",
            "amotherm": "Protezione passiva dal fuoco",
            "bessemer": "Anticorrosione per tetti",
            "stufex": "Vernici e colle per pavimenti",
        }
    },
    "EN": {
        "Benvenuto su MyAmonn": "Welcome to MyAmonn.",
        "nizia a creare la tua bacheca personale": "Start creating your personal dashboard:",
        "selection": "Please select product range",

        "message": {
            "lignex": "Wood protection",
            "amotherm": "Passive fire protection",
            "bessemer": "Corrosion protection for roofs",
            "stufex": "Paints and adhesives for flooring",
        }
    },
    "DE": {
        "Benvenuto su MyAmonn": "Willkommen auf MyAmonn.",
        "nizia a creare la tua bacheca personale": "Erstellen Sie Ihr persönliches Dashboard:",
        "message": {
            "lignex": "Holzschutz",
            "amotherm": "Passiver Brandschutz",
            "bessemer": "Korrosionsschutz für Dächer",
            "stufex": "Lacke und Klebstoffe für Böden",
        },
        "selection": "Bitte Produktbereich auswählen"


    },
    "ES": {
        "Benvenuto su MyAmonn": "Bienvenido a MyAmonn.",
        "nizia a creare la tua bacheca personale": "Empieza a crear tu area personal:",
        "message": {
            "lignex": "Preservación de la madera",
            "amotherm": "Protección pasiva contra incendios",
            "bessemer": "Protección contra la corrosión para techos",
            "stufex": "Pintura y adhesivo para suelos",
        },
        "selection": "Seleccione la gama de productos"
    },


}