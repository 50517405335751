import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  Animated,
} from "react-native";
import styles from "./style";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as theme from "../../Theme";
import axios from "axios";
import Alert from "../../Components/Alert/Alert";
import { CommonActions } from '@react-navigation/native';
import { normalize } from "../../Helpers/Normalizer";
import OTPTextView from 'react-native-otp-textinput';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import { getStatusBarHeight } from "react-native-status-bar-height";
import Translation from "./Translation";

// import cred from "../../../cred";
// const cheerio = require('cheerio');
// import Data from "../Data";

const screenHeight = Dimensions.get('screen').height;
const windowHeight = Dimensions.get('window').height;
const navbarHeight = screenHeight - (windowHeight + getStatusBarHeight());
const height = windowHeight - navbarHeight;
const width = Dimensions.get('window').width;
const iosHeight = Dimensions.get('window').height;
const Login = ({ navigation, route }) => {
  const { action } = route.params ?? 'N/a';
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(null);
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [otp, setOtp] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [timer, setTimer] = useState(true);
  const [cToken, setCToken] = useState("");
  const [getPassword, setGetPassword] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState("");
  const [nextRoute, setNextRoute] = useState(null);
  const [nextParams, setNextParams] = useState(null);
  const [language, setLanguage] = useState(route.params.language);
  function LoadingIndicatorView() {
    return (
      <View style={styles.activityIndicatorStyle}>
        <Image
          style={{ width: normalize(250), height: normalize(250) }}
          source={require("../../../assets/MyAmonn_loading_red.gif")} />
      </View>
    )
  }
  const getCurrentUser = (userToken) => {
    // console.log(userToken,"token in get user");
    axios.get('https://myadmin.myamonn.com/current-user', {
      headers: { Authorization: `Token ${userToken}` }
    })
      .then(function (response) {
        setRole(response.data.usertype);
        AsyncStorage.setItem('userRole', JSON.stringify(response.data.usertype));
        setLoading(false);
        navigation.navigate("Home");
      })
      .catch(function (error) {
        console.log(error, "getuser");
      })
      .then(function () {
        // always executed
      });
  }
  const getFavProducts = (userToken) => {
    axios.get('https://myadmin.myamonn.com/fav/', {
      headers: {
        Authorization: `Token ${userToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
      }
    })
      .then(function (response) {
        const cart = []
        const array = response.data.data
        array.forEach((value, index, array) => {
          const item = {
            title: value.name,
            image: value.image_url,
            product_line: value.product_line,
          }
          // console.log(item);
          cart.push(item);

        });
        AsyncStorage.setItem('cart', JSON.stringify(cart));
      })
      .catch(function (error) {
        // console.log(error,"getFav");
      })
      .then(function () {
        // always executed
      });
  }
  const handleLogin = () => {
    var form = new FormData();
    form.append('username', `${email.toLowerCase()}`);
    form.append('password', `${password}`);
    if (email == "" || password == "") {
      setAlert(true);
      setAlertMessage("PLEASE ENTER EMAIL AND PASSWORD");
      setLoading(false);
    }
    else {
      setLoading(true);
      fetch('https://myadmin.myamonn.com/auth/login/', {
        method: 'POST',
        body: form,
      })
        .then(r => r.json()
          .then(async data => {
            if (data.token) {
              // console.log(data.token);
              AsyncStorage.setItem('token', JSON.stringify(data.token));
              setToken(data.token);
              getFavProducts(data.token);
              getCurrentUser(data.token);
            }
            else {
              setEmail("");
              setPassword("");
              setAlert(true);
              setAlertMessage("please enter valid credentials");
              setLoading(false);
            }
          }))
        .catch((err) => {
          setAlert(true);
          setAlertMessage(err);
          setLoading(false);
        })
    }
  }
  const handleLogout = () => {
    setLoading(true);
    fetch('https://myadmin.myamonn.com/auth/logout/', {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then(() => {
        AsyncStorage.removeItem('userRole');
        AsyncStorage.removeItem('token');
        setRole(null);
        setToken(null);
        setEmail('');
        setPassword('');
        setLoading(false);
        navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: 'Login' },
              { name: "Login" }

            ],
          }),

        );
      }).catch((err) => {
        setLoading(false);
        setAlertMessage("No network found");
        setAlert(true);
      })
  };
  const getOtp = () => {
    setLoading(true);
    axios(`https://myadmin.myamonn.com/verify/${email}`, {
      method: 'GET',
    })
      .then((responce) => {
        // console.log(responce);
        setLoading(false);
        setOtp(true);

      })
      .catch((error) => {
        setLoading(false);
        setAlert(true);
        setAlertMessage("THERE MAY BE SOME PROBLEM")
      })

  }
  const verifyOtp = () => {
    setLoading(true);
    // console.log({"otp":otpCode})
    axios(`https://myadmin.myamonn.com/verify/${email}/`,
      {
        method: "POST",
        data: { "otp": otpCode }
      })
      .then(function (response) {
        // console.log(response);
        if (response.status == 200) {
          setOtp(false);
          setForgotPassword(false);
          setCToken(response.data);
          // console.log(response.data);
          setGetPassword(true);
        }
        setLoading(false);
      }
      )
      .catch(function (error) {
        setLoading(false);
        setTimer(false);
        setAlert(true);
        setAlertMessage("THERE MAY BE SOME PROBLEM")
        // console.log(error);
      })




    // axios(`http://192.168.0.105:8000/verify/${email}/`, {
    //     method: 'POST',
    //     data:{
    //       "otp":otpCode
    //     }
    //     })
    //     .then((responce) => {
    //       console.log(responce);
    //       setLoading(false);
    //       setOtp(true);

    //     })

  }
  const handlePassword = () => {
    setLoading(true);
    // console.log({"pwd":password})
    // console.log({"token":cToken})
    var form = new FormData();
    form.append("new_password", password);
    // console.log(`Token ${token}`,"I am in change password");
    fetch('https://myadmin.myamonn.com/reset/', {
      method: 'PUT',
      headers: {
        Authorization: `Token ${cToken}`,
      },
      body: form,
    })
      .then(r => r.json()
        .then(data => {
          if (data.code == 200) {
            setLoading(false);
            setAlert(true);
            setAlertMessage("Success");
            setGetPassword(false);
          }
          else {
            setLoading(false);
            setAlert(true);
            setAlertMessage("ERROR");
          }
          // console.log(data);
        }))
    // axios(`https://myadmin.myamonn.com/verify/`,
    // {
    //   headers: { 
    //     Authorization: `Token ${cToken}`,
    //   },
    //   method:"PUT",
    //   body:JSON.stringify({pwd:password}),
    // })
    // .then(res=>{console.log(res)})
    // .catch(err=>{
    //   console.log(err);
    //   setLoading(false);
    //   setAlert(true);
    //   setAlertMessage("NETWORK ERROR!")
    // })
  }


  useEffect(() => {
    // console.log(route.params.route.name,route.params.route.params);
    if (typeof route.params.route != "undefined") {
      setNextRoute(route.params.route.name ?? 'N/a');
      setNextParams(route.params.route.params ?? 'N/a');
    }
    if (action == "logout") {
      handleLogout();
    }
    else {
      AsyncStorage.getItem('userRole').then((role) => {
        if (role != null) {
          // console.log("role",role);
          setRole(role);
        }
      })
        .catch((err) => {
          alert(err)
        })
      AsyncStorage.getItem('token').then((token) => {
        if (token != null) {
          // console.log("token",token);
          setToken(token);
        }
      })
        .catch((err) => {
          alert(err)
        })
    }
  }, [route]);
  useEffect(() => {
    // console.log("token",token,"role",role);
    if (role && token) {
      if (nextParams == null && nextRoute == null) {
        navigation.navigate("Home");
      }
      else {
        navigation.navigate(nextRoute, nextParams);
      }
    }
  }, [role, token])
  const alerting = () => {
    setAlert(false);
    setAlertMessage("");

  }
  if (loading) {
    return LoadingIndicatorView();
  }
  else if (getPassword) {
    return (
      <ScrollView style={{ flex: 1 }}>
        {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
        <View style={styles.container}>
          <Image style={styles.avatar} source={require("../../../assets/myamonn-icons/USER.png")} />
          <Image style={styles.logo} source={require("../../../assets/myamonn-icons/logo-dark.png")} />
          <View style={[styles.inputView, password ? { backgroundColor: "grey" } : null]}>
            <TextInput
              style={[styles.TextInput, { width: "96%" }]}
              placeholder="Password"
              placeholderTextColor="#FFFFFF"
              secureTextEntry={showPassword}
              onChangeText={(password) => setPassword(password)}
            />
            <Icon
              onPress={() => setShowPassword(prev => !prev)}
              name={showPassword ? "eye-off" : "eye"}
              color={"white"}
              style={{ width: "6%" }}
              size={normalize(18)}
            />
          </View>
          <View style={[styles.inputView, confirmPassword ? { backgroundColor: "grey" } : null]}>
            <TextInput
              style={[styles.TextInput, { width: "96%" }]}
              placeholder="Confirm Password"
              placeholderTextColor="#FFFFFF"
              secureTextEntry={showPassword}
              onChangeText={(password) => setconfirmPassword(password)}
            />
            <Icon
              onPress={() => setShowPassword(prev => !prev)}
              name={showPassword ? "eye-off" : "eye"}
              color={"white"}
              style={{ width: "6%" }}
              size={normalize(18)}
            />
          </View>
          <TouchableOpacity style={styles.loginBtn}
            onPress={() => {
              if (password == confirmPassword && password.length >= 6) {
                handlePassword();
              }
              else {
                setAlertMessage("Both feilds should match & atleast 6 chars");
                setAlert(true);
              }
            }}
          >
            <Text style={styles.loginText}>{"Confirm"}</Text>
          </TouchableOpacity>
        </View>

      </ScrollView>
    )
  }
  else return (
    <ScrollView style={{ flex: 1 }}>
      {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
      <View style={styles.container}>
        {otp || forgotPassword ?
          <TouchableOpacity
            style={{ flexDirection: "row", alignSelf: "flex-start", marginLeft: normalize(20), bottom: normalize(40) }}
            onPress={() => {
              setOtp(false);
              setForgotPassword(false);
            }}
          >
            <Icon
              name={"keyboard-backspace"}
              color={"#D90000"}
              size={normalize(25)}
            />
            <Text style={{ fontFamily: "OpenSansCondensedBold", marginLeft: normalize(10), color: "#D90000", fontSize: normalize(14) }}>Back to login !</Text>
          </TouchableOpacity> : null}

        <Image style={styles.avatar} source={require("../../../assets/myamonn-icons/USER.png")} />
        <Image style={styles.logo} source={require("../../../assets/myamonn-icons/logo-dark.png")} />
        <View style={{ flexDirection: "row", marginBottom: normalize(10) }}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("login", { language: "IT" });
              setLanguage("IT");
              AsyncStorage.setItem('language', JSON.stringify("IT"));
            }}
            style={{
              width: normalize(30),
              height: normalize(35),
              borderRadius: normalize(10),
              borderWidth: 2,
              borderColor: theme.red,
              marginLeft: normalize(10),
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: language == "IT" ? theme.pink : "transparent"
            }}>
            <Text style={{
              fontFamily: "OpenSansCondensedBold",
              fontSize: normalize(14),
              color: theme.red
            }}>{"IT"}</Text>

          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("login", { language: "ES" });
              setLanguage("ES");
            }}
            style={{
              width: normalize(30),
              height: normalize(35),
              borderRadius: normalize(10),
              borderWidth: 2,
              borderColor: theme.red,
              marginLeft: normalize(10),
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: language == "ES" ? theme.pink : "transparent"
            }}>
            <Text style={{
              fontFamily: "OpenSansCondensedBold",
              fontSize: normalize(14),
              color: theme.red
            }}>{"ES"}</Text>

          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("login", { language: "DE" });
              setLanguage("DE");
              AsyncStorage.setItem('language', JSON.stringify("DE"));
            }}
            style={{
              width: normalize(30),
              height: normalize(35),
              borderRadius: normalize(10),
              borderWidth: 2,
              borderColor: theme.red,
              marginLeft: normalize(10),
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: language == "DE" ? theme.pink : "transparent"
            }}>
            <Text style={{
              fontFamily: "OpenSansCondensedBold",
              fontSize: normalize(14),
              color: theme.red
            }}>{"DE"}</Text>

          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("login", { language: "EN" });
              setLanguage("EN");
              AsyncStorage.setItem('language', JSON.stringify("EN"));
            }}
            style={{
              width: normalize(30),
              height: normalize(35),
              borderRadius: normalize(10),
              borderWidth: 2,
              borderColor: theme.red,
              marginLeft: normalize(10),
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: language == "EN" ? theme.pink : "transparent"
            }}>
            <Text style={{
              fontFamily: "OpenSansCondensedBold",
              fontSize: normalize(14),
              color: theme.red
            }}>{"EN"}</Text>

          </TouchableOpacity>

        </View>
        {role == null && token == null && otp == false ?
          <View style={[styles.inputView, email ? { backgroundColor: "grey" } : null]}>
            <TextInput
              style={styles.TextInput}
              placeholder="Email"
              placeholderTextColor="#FFFFFF"
              onChangeText={(email) => setEmail(email)}
            />
          </View> :
          [!role && !token ?
            <OTPTextView
              handleTextChange={(e) => {
                setOtpCode(e);
                // console.log(e);
              }}
              containerStyle={styles.textInputContainer}
              textInputStyle={styles.roundedTextInput}
              inputCount={4}
              inputCellLength={1}
              tintColor="#D90000"
            /> : null]
        }
        {role == null && token == null && forgotPassword == false ?
          <View style={[styles.inputView, password ? { backgroundColor: "grey" } : null]}>
            <TextInput
              style={[styles.TextInput, { width: "96%" }]}
              placeholder="Password"
              placeholderTextColor="#FFFFFF"
              secureTextEntry={showPassword}
              onChangeText={(password) => setPassword(password)}
            />
            <Icon
              onPress={() => setShowPassword(prev => !prev)}
              name={showPassword ? "eye-off" : "eye"}
              color={"white"}
              style={{ width: "6%" }}
              size={normalize(18)}
            />
          </View> : null}
        {otp ?
          [timer ?
            <CountdownCircleTimer
              isPlaying
              onComplete={() => {
                // do your stuff here
                setTimer(false);
                // return [true, 1500] // repeat animation in 1.5 seconds
              }}
              duration={120}
              colors={[
                ['#D90000', 0.4],
                ['#F7B801', 0.4],
                ['#A30000', 0.2],
              ]}
              size={normalize(50)}
              strokeWidth={normalize(4)}
            >
              {({ remainingTime, animatedColor }) => (
                <Animated.Text style={{ color: animatedColor }}>
                  {remainingTime}
                </Animated.Text>
              )}
            </CountdownCircleTimer> :
            <Text onPress={() => { getOtp(); setTimer(true); }}
              style={{
                fontFamily: "OpenSansCondensedBold",
                color: "#D90000",
                fontSize: normalize(14),
                height: normalize(30),
                textDecorationLine: "underline",
              }}>Resend_otp</Text>
          ] : null}
        <TouchableOpacity style={styles.loginBtn}
          onPress={() => {
            if (role == null && token == null && forgotPassword == false) {
              handleLogin();
            }
            else if (forgotPassword && otp == false) {
              getOtp();
            }
            else if (forgotPassword && otp) {
              verifyOtp();
            }
            else {
              navigation.navigate("Home");
            }

          }}
        >
          <Text style={styles.loginText}>{forgotPassword ? "NEXT" : Translation[language]["INVIA"]}</Text>
        </TouchableOpacity>
        {role == null && token == null && forgotPassword == false ?
          <TouchableOpacity
            onPress={() => {
              setForgotPassword(prev => !prev);
            }}>
            <Text style={styles.Registration_button}>{Translation[language]["Hai dimenticato la password?"]}</Text>
          </TouchableOpacity> : null}

        {role == null && token == null ?

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Register");
            }}>
            <Text style={styles.Registration_button}>{Translation[language]["Register"]}</Text>
          </TouchableOpacity> : null}

      </View>
    </ScrollView>
  );
};

export default Login;
