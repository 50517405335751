import { StyleSheet,Dimensions,StatusBar, Platform} from "react-native";
const screenHeight = Dimensions.get('screen').height;
const windowHeight = Dimensions.get('window').height;
const navbarHeight = screenHeight - (windowHeight+getStatusBarHeight());
// const height=windowHeight-navbarHeight;
const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
import { normalize } from "../../Helpers/Normalizer";
import { RFValue } from "react-native-responsive-fontsize";
import * as theme from "../../Theme";
// import { normalize } from "react-native-responsive-fontsize";
import { getStatusBarHeight } from "react-native-status-bar-height";

const styles =StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#fff",
      alignItems: "center",
      justifyContent: "center",
      alignSelf:"stretch",
      height:width>786?height-normalize(240):height-normalize(20),
      paddingVertical:width>786?normalize(40):normalize(110),
      marginHorizontal:width>786?normalize(120):normalize(15),
      marginVertical:width>786?normalize(120):normalize(20),
      borderBottomLeftRadius:width>786?normalize(200):normalize(150),
      borderBottomRightRadius:width>786?normalize(200):normalize(150),
      borderTopRightRadius:width>786?normalize(200):normalize(150),
      width:width>786?width-normalize(240):width-normalize(30)
    },
   
    avatar: {
      marginBottom: normalize(20),
      width:normalize(100),
      height:normalize(100)
    },
    logo: {
        marginBottom: normalize(30),
        width:normalize(150),
        height:normalize(25)
      },
   
    inputView: {
      flexDirection:"row",
      backgroundColor: "#D90000",
      borderRadius: normalize(30),
      width: "60%",
      height: normalize(45),
      marginBottom: normalize(20),
      justifyContent:"space-around",
      alignItems:"center",
    },
   
    TextInput: {
        
        width: "100%",
        height: normalize(50),
        paddingLeft:normalize(20),
        fontFamily:"OpenSansCondensedBold",
        color:"#FFFFFF",
        fontSize:normalize(14),
    },
   
    Registration_button: {
        fontFamily:"OpenSansCondensedBold",
        fontSize:normalize(14),
        height: normalize(30),
        marginTop: normalize(30),
        textDecorationLine:"underline",
    },
   
    loginBtn: {
      width: normalize(121),
      borderRadius: normalize(25),
      backgroundColor:"#D90000",
      height: normalize(40),
      alignItems: "center",
      justifyContent: "center",
      marginTop: normalize(40),
    
    },
    loginText:{
      fontFamily:"OpenSansCondensedBold",
      color:"#FFFFFF",
      fontSize:normalize(14),
    },
    profileDescription:{
      height:RFValue(40,735),
      justifyContent:"space-evenly",
      alignItems:"center"
    },
    username:{
        color:theme.red,
        fontFamily:"OpenSansCondensedBold",
        fontSize:normalize(16),
    },
    userType:{
        color:theme.red,
        fontFamily:"OpenSansCondensedLight",
        fontSize:normalize(14),
    },
    userInfoSection:{
      alignItems:'center'
    },
    activityIndicatorStyle: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    textInputContainer: {
      marginBottom: 20,
    },
    roundedTextInput: {
      borderRadius: 10,
      borderWidth: 4,
    },
});
export default styles;