export default {
    IT: {
        "PAVIMENTAZIONI": "PAVIMENTAZIONI",
        "ELEMENTI DI RIVESTIMENTO": "ELEMENTI DI RIVESTIMENTO",
        "STRUTTURE IN CLS": "STRUTTURE IN CLS",
        "STRUTTURE IN LEGNO": "STRUTTURE IN LEGNO",
        "STRUTTURE IN ACCIAIO": "STRUTTURE IN ACCIAIO",
        "PARETI PREFABBRICATE":"PARETI PREFABBRICATE",
        "PARETI IN LATERIZIO": "PARETI IN LATERIZIO",
        "PARETI IN BLOCCHI DI CALCESTRUZZO": "PARETI IN BLOCCHI DI CALCESTRUZZO",
        "PARETI IN BLOCCHI DI PIETRA": "PARETI IN BLOCCHI DI PIETRA",
        "PARETI IN CARTONGESSO": "PARETI IN CARTONGESSO",
        "Lignex": "Lignex",
        "Woodpro": "Woodpro",
        "Pratika": "Pratika",
        "Aquaprofi": "Aquaprofi",
        "CLOE": "CLOE",
        "Industria serramento": "Industria serramento",
        "Lignex – Fondi protettivi": "Lignex – Fondi protettivi",
        "Lignex – Impregnanti": "Lignex – Impregnanti",
        "Lignex – Finiture impregnanti effetto cera": "Lignex – Finiture impregnanti effetto cera",
        "Lignex – Finiture": "Lignex – Finiture",
        "Lignex – Protezione climatica": "Lignex – Protezione climatica",
        "Lignex – Prodotti Complementari": "Lignex – Prodotti Complementari",
        "Lignex – Oli e complementari": "Lignex – Oli e complementari",
        "Woodpro – Impregnanti": "Woodpro – Impregnanti",
        "Woodpro – Finiture impregnanti effetto cera": "Woodpro – Finiture impregnanti effetto cera",
        "Woodpro – Finiture": "Woodpro – Finiture",
        "Aquaprofi – Fondi protettivi": "Aquaprofi – Fondi protettivi",
        "Aquaprofi – Impregnanti": "Aquaprofi – Impregnanti",
        "Aquaprofi – Finiture impregnanti effetto cera": "Aquaprofi – Finiture impregnanti effetto cera",
        "Aquaprofi – Complementari": "Aquaprofi – Complementari",
        "Aquaprofi – Oli": "Aquaprofi – Oli",
        "Industria serramento – Fondi protettivi": "Industria serramento – Fondi protettivi",
        "Industria serramento – Impregnanti": "Industria serramento – Impregnanti",
        "Industria serramento – Fondi intermedi": "Industria serramento – Fondi intermedi",
        "Industria serramento – Finiture": "Industria serramento – Finiture",
        "Industria serramento – Complementari": "Industria serramento – Complementari",
        "CLOE – Impregnanti": "CLOE – Impregnanti",
        "CLOE – Finiture": "CLOE – Finiture",
        "CLOE – Oli": "CLOE – Oli",
        "CLOE – Detergenti e diluenti": "CLOE – Detergenti e diluenti ",
        "CLOE – Industria del parquet" : "CLOE – Industria del parquet",
        "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI": "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI",
        "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI": "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI",
        "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI": "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI",
        "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI": "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI",
        "VOLTE A BOTTE": "VOLTE A BOTTE",
        "LATEROCEMENTO": "LATEROCEMENTO",
        "PREDALLES": "PREDALLES",
        "CLS E LAMIERA GRECATA COLLABORANTE": "CLS E LAMIERA GRECATA COLLABORANTE",
        "TEGOLI BINERVANTI": "TEGOLI BINERVANTI",
        "Acqua": "Prodotti ad acqua",
        "Complementari": "Complementari",
        "Olio": "Prodotti ad olio",
        "Solvente": "Prodotti a solvente",
        "Bessemer – Fondi":"Bessemer – Fondi",
        "Bessemer – Finiture":"Bessemer – Finiture",
        "Bessemer- Complementari":"Bessemer- Complementari",
        "Adesivi e preparatori": "Adesivi e preparatori",
        "Finiture e fondi": "Finiture e fondi",
        "Stufex – Colle per falegnameria": "Stufex – Colle per falegnameria",
        "Stufex – Colle per pavimenti": "Stufex – Colle per pavimenti",
        "Stufex – Complementari": "Stufex – Complementari",
        "Stufex – Primer per pavimenti": "Stufex – Primer per pavimenti",
        "Stufex – Complementari": "Stufex – Complementari",
        "Stufex – Fondi per pavimenti": "Stufex – Fondi per pavimenti",
        "Stufex – Stucchi per pavimenti": "Stufex – Stucchi per pavimenti",
        "Stufex – Vernici per pavimenti": "Stufex – Vernici per pavimenti",

    },
    EN: {
        "PAVIMENTAZIONI": "FLOORING",
        "ELEMENTI DI RIVESTIMENTO": "COVERING ELEMENTS",
        "STRUTTURE IN CLS": "CLS CONCRETE STRUCTURES",
        "STRUTTURE IN LEGNO": "WOODEN STRUCTURES",
        "STRUTTURE IN ACCIAIO": "STEEL STRUCTURES",
        "PARETI IN LATERIZIO": "BRICK WALLS",
        "PARETI IN BLOCCHI DI CALCESTRUZZO": "CONCRETE WALLS",
        "PARETI IN BLOCCHI DI PIETRA": "STONE BLOCK WALLS",
        "PARETI IN CARTONGESSO": "PLASTERBOARD WALLS",
        "Lignex": "Lignex",
        "Woodpro": "Woodpro",
        "Pratika": "Pratika",
        "Aquaprofi": "Aquaprofi",
        "Industria serramento": "Products for doors and windows",
        "CLOE": "CLOE",
        "Lignex – Fondi protettivi": "Lignex – Protective primers",
        "Lignex – Impregnanti": "Lignex – Impregnating treatments",
        "Lignex – Finiture impregnanti effetto cera": "Lignex – Wax-effect finishing treatments",
        "Lignex – Finiture": "Lignex – Finishing treatments",
        "Lignex – Protezione climatica": "Lignex – Climatic protection",
        "Lignex – Prodotti Complementari": "Lignex – Contemplementary products",
        "Lignex – Oli e complementari": "Lignex – Oils and complementary products",
        "Woodpro – Impregnanti": "Woodpro – Impregnating treatments",
        "Woodpro – Finiture impregnanti effetto cera": "Woodpro – Wax-effect finishing treatments",
        "Woodpro – Finiture": "Woodpro – Finishing treatments",
        "Aquaprofi – Fondi protettivi": "Aquaprofi – Protective primers",
        "Aquaprofi – Impregnanti": "Aquaprofi – Impregnating treatments",
        "Aquaprofi – Finiture impregnanti effetto cera": "Aquaprofi – Wax-effect finishing treatments",
        "Aquaprofi – Complementari": "Aquaprofi – Complementary products",
        "Aquaprofi – Oli": "Aquaprofi – Oils",
        "Industria serramento – Fondi protettivi": "Products for doors and windows – Protective primers",
        "Industria serramento – Impregnanti": "Products for doors and windows – Impregnating treatments",
        "Industria serramento – Fondi intermedi": "Products for doors and windows – Intermediate primers",
        "Industria serramento – Finiture": "Products for doors and windows – Finishing treatments",
        "Industria serramento – Complementari": "Products for doors and windows – Complementary products",
        "CLOE – Impregnanti":"CLOE – Impregnating agents",
        "CLOE – Finiture":"CLOE – Finishes",
        "CLOE – Oli":"CLOE – Oils",
        "CLOE – Detergenti e diluenti":"CLOE – Detergents and thinners",
        "CLOE – Industria del parquet":"CLOE – Parquet industry",
        "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI": "Fire protection refurbishment of existing slabs",
        "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI": "Fire protection refurbishment of existing walls",
        "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI": "Fire protection refurbishment of structural elements",
        "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI": "Fire protection refurbishment of wooden surfaces",
        "VOLTE A BOTTE": "VAULTED CEILINGS",
        "LATEROCEMENTO": "Composite brick/concrete slabs",
        "PREDALLES": "Precast concrete slabs with blocks of polystyrene (Predalles)",
        "CLS E LAMIERA GRECATA COLLABORANTE": "Plane composite slabs with trapezoidal steel deck and concrete topping",
        "TEGOLI BINERVANTI": "Precast ceiling elements type TT-beams",
        "Acqua": "Water-based products",
        "Complementari": "Complementary products",
        "Olio": "Oil-based products",
        "Solvente": "Solvent-based products",
        "Bessemer – Fondi":"Bessemer – Fondi",
        "Bessemer – Finiture":"Bessemer – Finishes",
        "Bessemer- Complementari":"Bessemer – Complementaries",
        "Adesivi e preparatori": "Adhesives and preparatory",
        "Finiture e fondi": "Finishes and primers",
        "Stufex – Colle per falegnameria": "Stufex – Adhesives for flooring",
        "Stufex – Colle per pavimenti": "Stufex – Carpentry adhesives",
        "Stufex – Complementari": "Stufex – Complementary products",
        "Stufex – Primer per pavimenti": "Stufex – Primer for flooring",
        "Stufex – Complementari": "Stufex – Complementary products",
        "Stufex – Fondi per pavimenti": "Stufex – Primer for flooring",
        "Stufex – Stucchi per pavimenti": "Stufex – Fillers for flooring",
        "Stufex – Vernici per pavimenti": "Stufex – Varnishes for flooring",
    },
    ES: {
        "PAVIMENTAZIONI": "Pavimentos",
        "ELEMENTI DI RIVESTIMENTO": "Elementos de revestimiento",
        "STRUTTURE IN CLS": "Estructuras CLS",
        "STRUTTURE IN LEGNO": "Estructuras de madera",
        "STRUTTURE IN ACCIAIO": "Estructuras de acero",
        "PARETI IN LATERIZIO": "Paredes de ladrillo",
        "PARETI IN BLOCCHI DI CALCESTRUZZO": "Paredes de bloques de hormigón",
        "PARETI IN BLOCCHI DI PIETRA": "Paredes de bloques de piedra",
        "PARETI IN CARTONGESSO": "Paredes de cartón-yeso",
        "Lignex": "Lignex",
        "Woodpro": "Woodpro",
        "Pratika": "Pratika",
        "Aquaprofi": "Aquaprofi",
        "Industria serramento": "Industria ventanas y persianas",
        "CLOE": "CLOE",
        "Lignex – Fondi protettivi": "Lignex – Fondos protectores",
        "Lignex – Impregnanti": "Lignex – Impregnantes",
        "Lignex – Finiture impregnanti effetto cera": "Lignex – Acabados impregnantes efecto cera",
        "Lignex – Finiture": "Lignex – Impregnante de acabado",
        "Lignex – Protezione climatica": "Lignex – Protección meteorológica",
        "Lignex – Prodotti Complementari": "Lignex – Productos complementarios",
        "Lignex – Oli e complementari": "Lignex – Aceites y productos complementarios",
        "Woodpro – Impregnanti": "Woodpro – Impregnantes",
        "Woodpro – Finiture impregnanti effetto cera": "Woodpro – Acabados impregnantes efecto cera",
        "Woodpro – Finiture": "Woodpro – Impregnante de acabado",
        "Aquaprofi – Fondi protettivi": "Aquaprofi – Fondos protectores",
        "Aquaprofi – Impregnanti": "Aquaprofi – Impregnantes",
        "Aquaprofi – Finiture impregnanti effetto cera": "Aquaprofi – Acabados impregnantes efecto cera",
        "Aquaprofi – Complementari": "Aquaprofi – Productos complementarios",
        "Aquaprofi – Oli": "Aquaprofi – Aceites",
        "Industria serramento – Fondi protettivi": "Industria ventanas y persianas – Fondos protectores",
        "Industria serramento – Impregnanti": "Industria ventanas y persianas – Impregnantes",
        "Industria serramento – Fondi intermedi": "Industria ventanas y persianas – Fondos Intermedios",
        "Industria serramento – Finiture": "Industria ventanas y persianas – Acabados",
        "Industria serramento – Complementari": "Industria ventanas y persianas – Productos complementarios",
        "CLOE – Impregnanti":"CLOE – Impregnantes",
        "CLOE – Finiture":"CLOE – Acabados",
        "CLOE – Oli":"CLOE – Aceites",
        "CLOE – Detergenti e diluenti":"CLOE – Detergentes y diluyentes",
        "CLOE – Industria del parquet":"CLOE – Industria del parquet",
        "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI": "RENOVACIÓN CONTRA INCENDIOS DE PISOS EXISTENTES",
        "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI": "RENOVACIÓN CONTRA INCENDIOS DE MUROS EXISTENTES",
        "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI": "REQUALIFICACIÓN AL FUEGO DE ELEMENTOS ESTRUCTURALES",
        "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI": "REQUALIFICACIÓN DE ELEMENTOS DE REVESTIMIENTO DE MADERA NO ESTRUCTURALES",
        "LATEROCEMENTO": "Hormigón armado",
        "PREDALLES": "Losas prefabricadas de hormigón con bloques de poliestireno (Predalles)",
        "CLS E LAMIERA GRECATA COLLABORANTE": "Losas compuestas planas con cubierta de acero trapezoidal y cubierta de hormigón",
        "TEGOLI BINERVANTI": "Elementos de techo prefabricados tipo vigas TT",
        "Acqua": "Productos a base de agua",
        "Complementari": "Productos complementarios",
        "Olio": "Productos a base de aceite",
        "Solvente": "Productos con base de disolvente",
        "Bessemer – Fondi":"Bessemer – Fondi",
        "Bessemer – Finiture":"Bessemer – Acabados",
        "Bessemer- Complementari":"Bessemer – Complementarios",
        "Adesivi e preparatori": "Adhesivos y tratamientos previos",
        "Finiture e fondi": "Acabados e imprimaciones",
        "Stufex – Colle per falegnameria": "Stufex – Adhesivos para revestimientos de suelos",
        "Stufex – Colle per pavimenti": "Stufex – Adhesivos para carpinteros/ ebanistas",
        "Stufex – Complementari": "Stufex – Productos complementarios",
        "Stufex – Primer per pavimenti": "Stufex – Imprimación para el sustrato del suelo",
        "Stufex – Complementari": "Stufex – Productos complementarios",
        "Stufex – Fondi per pavimenti": "Stufex – Pisos para pavimentos",
        "Stufex – Stucchi per pavimenti": "Stufex – Rellenos de pavimentos",
        "Stufex – Vernici per pavimenti": "Stufex – Barnices para pavimentos",
    },
    DE: {
        "PAVIMENTAZIONI": "FUSSBÖDEN",
        "ELEMENTI DI RIVESTIMENTO": "VERKLEIDUNGSELEMENTE",
        "STRUTTURE IN CLS": "CLS-Beton-Konstruktionen",
        "STRUTTURE IN LEGNO": "HOLZKONSTRUKTIONEN",
        "STRUTTURE IN ACCIAIO": "STAHLKONSTRUKTIONEN",
        "PARETI IN LATERIZIO": "WÄNDE AUS ZIEGELN",
        "PARETI IN BLOCCHI DI CALCESTRUZZO": "WÄNDE AUS BETONBLÖCKEN",
        "PARETI IN BLOCCHI DI PIETRA": "Steinwände",
        "PARETI IN CARTONGESSO": "WÄNDE AUS GIPSKARTON",
        "Lignex": "Lignex",
        "Woodpro": "Woodpro",
        "Pratika": "Pratika",
        "Aquaprofi": "Aquaprofi",
        "Industria serramento": "Industriebereich Fenster und Türen",
        "CLOE": "CLOE",
        "Lignex – Fondi protettivi": "Lignex – Schutzgrundierungen",
        "Lignex – Impregnanti": "Lignex – Lasuren",
        "Lignex – Finiture impregnanti effetto cera": "Lignex – Mittelschichtlasuren mit Wachseffekt",
        "Lignex – Finiture": "Lignex – Mittelschichtlasuren",
        "Lignex – Protezione climatica": "Lignex – Wetterschutzfarben",
        "Lignex – Prodotti Complementari": "Lignex – Komplementäre",
        "Lignex – Oli e complementari": "Lignex – Öle und ergänzende Produkte",
        "Woodpro – Impregnanti": "Woodpro – Lasuren",
        "Woodpro – Finiture impregnanti effetto cera": "Woodpro – Mittelschichtlasuren mit Wachseffekt",
        "Woodpro – Finiture": "Woodpro – Mittelschichtlasuren",
        "Aquaprofi – Fondi protettivi": "Aquaprofi – Schutzgrundierungen",
        "Aquaprofi – Impregnanti": "Aquaprofi - Lasuren",
        "Aquaprofi – Finiture impregnanti effetto cera": "Aquaprofi - Mittelschichtlasuren mit Wachseffekt",
        "Aquaprofi – Complementari": "Aquaprofi - Komplementäre",
        "Aquaprofi – Oli": "Aquaprofi - Öle",
        "Industria serramento – Fondi protettivi": "Industriebereich Fenster und Türen – Schutzgrundierungen",
        "Industria serramento – Impregnanti": "Industriebereich Fenster und Türen – Lasuren",
        "Industria serramento – Fondi intermedi": "Industriebereich Fenster und Türen – Zwischengrundierungen",
        "Industria serramento – Finiture": "Industriebereich Fenster und Türen – Endbeschichtungen",
        "Industria serramento – Complementari": "Industriebereich Fenster und Türen – Komplementäre",
        "CLOE – Impregnanti":"CLOE – Imprägniermittel",
        "CLOE – Finiture":"CLOE – Ausführungen",
        "CLOE – Oli":"CLOE – Öle",
        "CLOE – Detergenti e diluenti":"CLOE – Wasch- und Verdünnungsmittel",
        "CLOE – Industria del parquet":"CLOE – Parkettindustrie",
        "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI": "BRANDTECHNISCHE NACHRÜSTUNG BESTEHENDER DECKEN",
        "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI": "BRANDTECHNISCHE NACHRÜSTUNG BESTEHENDER WANDELEMENTE",
        "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI": "BRANDTECHNISCHE NACHRÜSTUNG VON STRUKTURELLEN BAUELEMENTEN",
        "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI": "BRANDTECHNISCHE NACHRÜSTUNG VON NICHT-STRUKTURELLEN HOLZVERKLEIDUNGSELEMENTEN",
        "VOLTE A BOTTE": "TONNENGEWÖLBE",
        "LATEROCEMENTO": "Stahlbeton",
        "PREDALLES": "Betonfertigplatten mit Blöcken aus Polystyrol (Predalles)",
        "CLS E LAMIERA GRECATA COLLABORANTE": "Verbundplatten mit trapezförmigem Stahlblech und Betondeckung",
        "TEGOLI BINERVANTI": "Vorgefertigte Deckenelemente Typ TT-Träger",
        "Acqua": "Wasserbasierte Produkte",
        "Complementari": "Komplementäre",
        "Olio": "Ölbasierte Produkte",
        "Solvente": "Lösemittelbasierte Produkte",
        "Bessemer – Fondi":"Bessemer – Fondi",
        "Bessemer – Finiture":"Bessemer – Endet",
        "Bessemer- Complementari":"Bessemer – Komplementär",
        "Adesivi e preparatori": "Klebstoffe und Vorbehandlungen",
        "Finiture e fondi": "Lacke und Grundierungen",
        "Stufex – Colle per falegnameria": "Stufex – Kleber für Bodenbeläge",
        "Stufex – Colle per pavimenti": "Stufex – Klebstoffe für den Schreiner/Tischler",
        "Stufex – Complementari": "Stufex – Komplementäre",
        "Stufex – Primer per pavimenti": "Stufex - Primer für Fußböden",
        "Stufex – Complementari": "Stufex – Füllmassen für Holzböden",
        "Stufex – Fondi per pavimenti": "Stufex – Primer für Fußböden",
        "Stufex – Stucchi per pavimenti": "Stufex – Fußbodenspachtel",
        "Stufex – Vernici per pavimenti": "Stufex – Lacke für Fußböden",
    },
}