export default{
    "IT":{
        "FILTER":"FILTRO",
        "My Products":"I miei prodotti",
        "My Documents":"i miei documenti",
    },
    "DE":{
        "FILTER":"FILTER",
        "My Products":"meine Produkte",
        "My Documents":"meine Dokumente",
    },
    "ES":{
        "FILTER":"FILTRAR",
        "My Products":"mis productos",
        "My Documents":"mis documentos",

    },
    "EN":{
        "FILTER":"FILTER",
        "My Products":"My Products",
        "My Documents":"My Documents",
    },

}