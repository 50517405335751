export default {
    "IT": {
        "Privato": "Privato",
        "Tecnico professionista": "Tecnico professionista",
        "Agente Amonn": "Agente Amonn",
        "Cliente Amonn": "Cliente Amonn",
        "User type": "Tipologia di utente:",
        "Name": "Nome",
        "Surname": "Cognome",
        "Address": "Indirizzo",
        "Telephone": "Telefono",
        "E-mail": "Email",
        "Password": "Password",
        "Confirm password": "Conferma password",
        "VAT No": "Partita IVA",
        "Customer code": "Codice cliente",
        "Radix password": "Radix password",
        "error_message": "I dati inseriti non corrispondono. Se il problema persiste, contattare l’azienda.",
        "confirmation_message": "Registrazione effettuata. Riceverai un link alla email inserita, per confermare il tuo account. Grazie!",
        "Register": "Registrati",
        "VAT No reg": "Inserire cod. nazione (es.IT12345678)"
    },
    "EN": {
        "Privato": "Private",
        "Tecnico professionista": "Professional technician",
        "Agente Amonn": "Amonn Agent",
        "Cliente Amonn": "Customer Amonn",
        "User type": "User type",
        "Name": "Name",
        "Surname": "Surname",
        "Address": "Address",
        "Telephone": "Telephone",
        "E-mail": "Email",
        "Password": "Password",
        "Confirm password": "Confirm password",
        "VAT No": "VAT No",
        "Customer code": "Customer code",
        "Radix password": "Radix password",
        "error_message": "The details you entered do not match. In case of problems, contact the company.",
        "confirmation_message": "Registration completed. We will send you a link via email in order to confirm your account. Thank you!",
        "Register": "Register",
        "VAT No reg": "(eg.IT12345678)"


    },
    "DE": {
        "Privato": "Privatkunde",
        "Tecnico professionista": "Professioneller Techniker",
        "Agente Amonn": "Amonn Außendienstmitarbeiter",
        "Cliente Amonn": "Cliente Amonn",
        "User type": "Benutzertyp:",
        "Name": "Name",
        "Surname": "Nachname",
        "Address": "Anschrift",
        "Telephone": "Telefonnummer",
        "E-mail": "E-Mail-Adresse",
        "Password": "Passwort",
        "Confirm password": "Passwort bestätigen",
        "VAT No": "USt.-IdNr.",
        "Customer code": "Kundennummer",
        "Radix password": "Radix Passwort",
        "error_message": "Die eingegebenen Daten stimmen nicht überein. Bei Problemen wenden Sie sich bitte an das Unternehmen.",
        "confirmation_message": "Registrierung abgeschlossen. Sie erhalten in Kürze einen Link per E-Mail mit dem Sie Ihr Konto aktivieren können. Danke!",
        "Register": "Registrieren",
        "VAT No reg": "(eg.IT12345678)"

    },
    "ES": {
        "Privato": "Privado",
        "Tecnico professionista": "Técnico profesional",
        "Agente Amonn": "Agente Amonn",
        "Cliente Amonn": "Cliente Amonn",
        "User type": "Tipo de cuenta:",
        "Name": "Nombre",
        "Surname": "Apellido",
        "Address": "Dirección",
        "Telephone": "Telefono",
        "E-mail": "Email",
        "Password": "Contraseña",
        "Confirm password": "Confirma contraseña",
        "VAT No": "NIF-IVA",
        "Customer code": "Código cliente",
        "Radix password": "Contraseña Radix",
        "error_message": "Los datos introducidos no coinciden. Si el problema persiste, póngase en contacto con la empresa.",
        "confirmation_message": "Registración completada. Recibirás un enlace al correo electrónico que has introducido para confirmar tu cuenta. Gracias.",
        "Register": "Regístrate",
        "VAT No reg": "(eg.IT12345678)"

    },
}