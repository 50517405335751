import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import DrawerNavigation from './src/navigation/drawerNavigation';
import { useFonts } from "@use-expo/font";
import { Asset } from 'expo-asset';
import AppLoading from 'expo-app-loading';
import { normalize } from './src/Helpers/Normalizer';
// import SplashScreen from 'react-native-splash-screen'
// import stackNavigation from './src/navigation/stackNavigation';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Products from './src/Products';

const customFonts = {
  OpenSansCondensedBold: require("./assets/Fonts/OpenSansCondensed-Bold.ttf"),
  OpenSansCondensedLight: require("./assets/Fonts/OpenSansCondensed-Light.ttf"),
  OpenSansCondensedLightItalic: require("./assets/Fonts/OpenSansCondensed-LightItalic.ttf"),
};
export default function App() {
  const [isLoaded] = useFonts(customFonts);
  const [splash, setSplash] = useState(true);


  if (!isLoaded && splash == true) {

    return null;

  }
  else if (isLoaded && splash == true) {
    setTimeout(() => {
      axios.get("https://myadmin.myamonn.com/Products").then((response) => {
        AsyncStorage.setItem("PRODUCTS", JSON.stringify(response.data))
        // setSplash(false);
      }).catch((err) => {
        AsyncStorage.setItem("PRODUCTS", JSON.stringify(Products))
        // setSplash(false);
      })
      setSplash(false)
    }, 4500)
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            resizeMode: "contain",
            width: normalize(424),
            height: normalize(736)
          }}
          source={require("./assets/logo.gif")} />
      </View>
    )
  }
  else {
    return (

      <DrawerNavigation />

    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
