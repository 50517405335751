import React, { useEffect, useRef, useState, useCallback } from 'react';
import { View, Text, TouchableHighlight, TouchableWithoutFeedback, FlatList, Dimensions, Platform, KeyboardAvoidingView, Keyboard, ActivityIndicator, TouchableOpacity, Image, TextInput } from "react-native";
import styles from './styles';
import BottomButtons from '../../Components/BottomButtons/BottomButtons';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { normalize } from '../../Helpers/Normalizer';
// import { normalize } from 'react-native-responsive-fontsize';
import TextTicker from 'react-native-text-ticker';
import ProductButton from '../../Components/ProductButton/ProductButton';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import * as theme from "../../Theme";
import AsyncStorage from '@react-native-async-storage/async-storage';
import BIM_TRANSLATION from './BIM_TRANSLATION';
import { CheckBox } from 'react-native-elements'
import { ScrollView } from 'react-native-gesture-handler';
import Alert from '../../Components/Alert/Alert';
import { downloadFile } from '../../Helpers/downloadDocument';
import { getDocuments as getDocs } from '../../Helpers/downloadDocument';
import { deleteFile } from '../../Helpers/downloadDocument';
import { openMyDocument } from '../../Helpers/downloadDocument';
import BIMFilter from '../../Components/BottomSheetContent/BIMFilter';
import BottomSheet from 'reanimated-bottom-sheet';
import { useTheme } from '@react-navigation/native';
import Modal from "modal-react-native-web";
const { width, height } = Dimensions.get('window');
const BIM = ({ route, navigation }) => {
    const keyboardVerticalOffset = Platform.OS === 'ios' ? normalize(80) : 0
    // const [radixDocuments,setradixDocuments]=useState(route.params.radixDocuments);
    const [query, setQuery] = useState("");
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [langFilter, setLangFilter] = useState("IT");
    const [showReqForm, setShowReqForm] = useState(false);
    const [society, setSociety] = useState('');
    const [userType, setUserType] = useState('');
    const [worksiteAddress, setWorksiteAddress] = useState('');
    const [wsite, setWsite] = useState('');
    const [streetName, setstreetName] = useState('');
    const [fireReactionClass, setfireReactionClass] = useState('');
    const [requiredFile, setrequiredFile] = useState('');
    const [town, setTown] = useState('');
    const [token, setToken] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState();
    const [downloadedDocuments, setDownloadedDocuments] = useState([]);
    const [solutionFilter, setSolutionFilter] = useState("");
    const [language, setLanguage] = useState(route.params.language);
    const [openFilter, setOpenFilter] = useState(false);

    const [clientType, setClientType] = useState(
        {
            "company": false,
            "planner": false,
            "public body": false
        })
    const [worksite, setWorksite] = useState(
        {
            "Airport": false,
            "School": false,
            "Hospital": false,
            "Public Building": false,
            "Directional Building": false,
            "Shopping centres": false,
            "Other": false,
        })
    const downloadDoc = async (name, type) => {
        await downloadFile(name, type, "bim", () => { setDownloading(""); });
    }
    const settingDownlaodedDocuments = async () => {
        setDownloadedDocuments(await getDocs());
    }
    const sendRequest = () => {
        // console.log(token,"I am token");
        var form = new FormData();
        form.append('society', `${society}`);
        form.append('client_type', `${userType}`);
        form.append('worksite', `${wsite}`);
        form.append('address', `${worksiteAddress}`);
        // form.append('townn', `${town}`);
        form.append('streetname', `${streetName}`);
        form.append('reaction_class', `${fireReactionClass}`);
        form.append('required_file', `${requiredFile}`);
        if (society != '' && userType != "" && wsite != "" && worksiteAddress != "" && streetName != "" && fireReactionClass != "" && requiredFile != "") {
            fetch('https://myadmin.myamonn.com/bim-request/', {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                },
                body: form,
            })
                .then(r => r.json()
                    .then(data => {
                        // console.log(data);
                        setShowReqForm(!setShowReqForm);
                        downloadDoc(requiredFile, "bim");
                        // setDownloading("");
                    }))
                .catch(err => {
                    setAlert(true);
                    setAlertMessage("Please check your Network and try later");
                    setDownloading("");
                })
        }
        else {
            setAlert(true);
            setAlertMessage("All fields are required");
        }
    }
    function LoadingIndicatorView() {
        return <View style={styles.activityIndicatorStyle}>
            <Image
                style={{ width: normalize(200), height: normalize(200) }}
                source={require("../../../assets/MyAmonn_loading_red.gif")} />
        </View>
    }
    const checkStatus = async (filename) => {
        fetch(`https://myadmin.myamonn.com/bim-request/${filename}`, {
            method: 'GET',
            headers: { Authorization: `Token ${token}` }
        })
            .then(r => r.json()
                .then(data => {
                    console.log(data);

                    if (data.data == "Fill Form" && data.status == "success") {
                        setShowReqForm(true);
                        setDownloading("");
                    }
                    else {
                        setAlert(true);
                        setAlertMessage(BIM_TRANSLATION[language]["alert message"] + "\n Or download in " + data.data);
                        setDownloading("");
                    }
                }))
            .catch(err => {
                // console.log(err);
                setDownloading("");
            });
    }
    // const getDocuments=useCallback(async()=>{
    //     fetch('https://myadmin.myamonn.com/bim-documents/', {
    //         method: 'GET',
    //         headers: { Authorization: `Token ${token}` }
    //         })
    //         .then(r =>  r.json().then(data => {
    //             setDocuments(data);
    //             console.log(documents);
    //             setLoading(false);
    //         }))
    // },[documents,loading])
    // const setup=useCallback(async ()=>{
    //     await settingDownlaodedDocuments();
    //     console.log(downloadedDocuments);
    // },[downloadedDocuments]);
    // useEffect(()=>{
    //     console.log(route.params.language);
    //     getToken();
    //     getDocuments();
    //     setup();
    // },[getDocuments,setup]);
    useEffect(() => {
        setLoading(true);
        const getLanguage = () => {
            AsyncStorage.getItem("language").then((response) => {
                if (response != null) {
                    setLanguage(JSON.parse(response));
                    //   console.log(language);
                }
            })
        }
        getLanguage();
        AsyncStorage.getItem("token").then((response) => {
            setToken(JSON.parse(response));

            // console.log(JSON.parse(response));
            // settingDownlaodedDocuments();
            fetch('https://myadmin.myamonn.com/bim-documents/', {
                method: 'GET',
                headers: { Authorization: `Token ${JSON.parse(response)}` }
            })
                .then(r => r.json())
                .then(data => {
                    setDocuments(data);
                    // console.log(data,"Documents");
                    setLoading(false);
                })
        })
    }, []);
    useEffect(() => {
        AsyncStorage.getItem("token").then((response) => {
            if (JSON.parse(response) == null) {
                console.log(response, route)
                navigation.navigate("Login", { route: route });
            };
        })
    }, [route]);
    // useEffect(()=>{console.log(clientType,requiredFile)},[downloadedDocuments,token,]);
    const alerting = () => {
        setAlert(false);
        setAlertMessage("");

    }
    if (loading) {
        return LoadingIndicatorView();
    }
    else if (solutionFilter == "") {
        return (
            <TouchableWithoutFeedback
            // onPress={()=>{Keyboard.dismiss()}}  
            >
                <View style={styles.container}>
                    <KeyboardAvoidingView
                        behavior={Platform.OS === 'ios' ? 'position' : 'height'} keyboardVerticalOffset={keyboardVerticalOffset}>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                paddingTop: normalize(50),
                                paddingHorizontal: normalize(20),
                                backgroundColor: "white",
                            }}
                        >
                            <BottomButtons
                                Buttons={1}
                                buttonText1={"My BIM"}
                            />
                            <TouchableOpacity
                                onPress={() => {
                                    navigation.navigate("Dashboard");
                                }}>
                                <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.bottomTab}>
                            <SearchBar width={width > 786 ? 700 : 250} language={language} onChangeText={(query) => setQuery(query)} />
                            <TouchableOpacity style={[styles.filterBtn, { marginHorizontal: normalize(30) }]}
                                onPress={() => {
                                    setOpenFilter(prev => !prev);
                                }}
                            >
                                <Text style={styles.filterText}>{BIM_TRANSLATION[language]["FILTER"]}</Text>
                                <Icon
                                    name="chevron-up"
                                    color="white"
                                    size={25}
                                />
                            </TouchableOpacity>

                        </View>
                    </KeyboardAvoidingView>
                    {openFilter ?
                        <BIMFilter
                            doFilter={(solutionType, langFilter) => {
                                setSolutionFilter(solutionType);
                                setLangFilter(langFilter);

                            }}
                            language={language}
                            openFilter={() => setOpenFilter(false)} /> : null}

                    <View style={styles.initialWindow}>
                        <View style={styles.messageWindow}>
                            <Text style={styles.message}>
                                {BIM_TRANSLATION[language]["Libreria BIM disponibile solo per Amotherm. \n Seleziona una soluzione nei filteri per \nvisulizzare i documenti BIM."]}
                            </Text>
                        </View>
                    </View>

                    {/* <BottomSheet
            ref={openFilter}
            snapPoints={[normalize(0), normalize(900)]}
            renderContent={()=><BIMFilter 
                doFilter={(solutionType,langFilter)=>{
                    setSolutionFilter(solutionType);
                    setLangFilter(langFilter);

                }} 
                language={language}
                openFilter={()=>setOpenFilter(false)} />}
                initialSnap={0}
                enabledContentTapInteraction={false}
        /> */}
                </View>
            </TouchableWithoutFeedback>
        )
    }
    else return (
        <TouchableWithoutFeedback
        // onPress={()=>{Keyboard.dismiss()}}  
        >
            <View style={styles.container}>
                {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'ios' ? 'position' : 'height'} keyboardVerticalOffset={keyboardVerticalOffset}>
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: normalize(50),
                            paddingHorizontal: normalize(20),
                            backgroundColor: "white",
                        }}
                    >
                        <BottomButtons
                            Buttons={1}
                            buttonText1={"My BIM"}
                        />
                        <Image style={styles.logo} resizeMode="contain" source={require("../../../assets/myamonn-icons/logo-dark2x.png")} />
                    </View>
                    <View style={styles.bottomTab}>
                        <SearchBar width={width > 786 ? 700 : 250} language={language} onChangeText={(query) => setQuery(query)} />
                        <TouchableOpacity style={[styles.filterBtn, { marginHorizontal: normalize(30) }]}
                            onPress={() => {
                                setOpenFilter(true);
                            }}
                        >
                            <Text style={styles.filterText}>FILTER</Text>
                            <Icon
                                name="chevron-up"
                                color="white"
                                size={25}
                            />
                        </TouchableOpacity>
                    </View>
                </KeyboardAvoidingView>
                {openFilter ?
                    <BIMFilter
                        doFilter={(solutionType, langFilter) => {
                            setSolutionFilter(solutionType);
                            setLangFilter(langFilter);
                            // console.log(solutionFilter);


                        }}
                        language={language}
                        openFilter={() => setOpenFilter(false)} /> : null}

                <View style={styles.initialWindow}>
                    <Modal
                        animationType="fade"
                        transparent={true}
                        visible={showReqForm}
                        onRequestClose={() => {
                            setShowReqForm(!showReqForm);
                        }}
                    >
                        <ScrollView style={{ flex: 1, backgroundColor: 'rgba(52, 52, 52, 0.8)' }} contentContainerStyle={styles.centeredView}>

                            <View style={styles.modalView}>
                                <TouchableOpacity
                                    onPress={() => { setShowReqForm(false) }}
                                    style={{ alignSelf: "flex-start", top: normalize(-6), left: normalize(-6) }}
                                >
                                    <Icon
                                        name={"close-circle"}
                                        color={"#D90000"}
                                        size={normalize(30)}
                                    />
                                </TouchableOpacity>
                                <View style={styles.regForm}>
                                    <Text
                                        style={{
                                            color: "#333333",
                                            fontFamily: "OpenSansCondensedLight",
                                            fontSize: normalize(16),
                                            marginLeft: normalize(10),
                                            marginBottom: normalize(21, 735)
                                        }}
                                    >{BIM_TRANSLATION[route.params.language].form_title}</Text>
                                    {/* <View style={styles.twoInputView}> */}
                                    <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder={(BIM_TRANSLATION[route.params.language]["Society"]).toUpperCase()}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(name) => setSociety(name)}
                                        />
                                    </View>
                                    <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                        <TextInput
                                            style={styles.TextInput}
                                            editable={false}
                                            placeholder={(BIM_TRANSLATION[route.params.language]["Client type"]).toUpperCase()}
                                            placeholderTextColor={theme.red}
                                        // onChangeText={(lastname) => setLastName(lastname)}
                                        />
                                    </View>
                                    <View style={{ width: normalize(330), flexDirection: "row", alignSelf: "center", alignItems: "center", justifyContent: "flex-start" }}>
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginTop: 0, marginLeft: normalize(7) }}
                                            textStyle={{
                                                color: "#D90000",
                                                marginLeft: normalize(4),
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            size={normalize(15)}
                                            title={(BIM_TRANSLATION[route.params.language]["Company"]).toUpperCase()}
                                            onPress={() => {
                                                setUserType((BIM_TRANSLATION[route.params.language]["Company"]).toUpperCase());
                                                setClientType({ "company": true, "planner": false, "public body": false })
                                            }}
                                            checked={clientType["company"]}
                                        />
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginTop: 0 }}
                                            size={normalize(15)}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            textStyle={{
                                                color: "#D90000",
                                                marginLeft: normalize(4),
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            title={(BIM_TRANSLATION[route.params.language]["Planner"]).toUpperCase()}
                                            onPress={() => {
                                                setUserType((BIM_TRANSLATION[route.params.language]["Planner"]).toUpperCase());
                                                setClientType({ "company": false, "planner": true, "public body": false })
                                            }}
                                            checked={clientType["planner"]}
                                        />
                                        {route.params.language !== "DE" ?
                                            <CheckBox
                                                containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginTop: 0 }}
                                                textStyle={{
                                                    color: "#D90000",
                                                    marginLeft: normalize(4),
                                                    fontFamily: "OpenSansCondensedLight",
                                                    fontSize: normalize(12)
                                                }}
                                                checkedColor="#D90000"
                                                uncheckedColor="#D90000"
                                                size={normalize(15)}
                                                title={(BIM_TRANSLATION[route.params.language]["Public body"]).toUpperCase()}
                                                onPress={() => {
                                                    setUserType((BIM_TRANSLATION[route.params.language]["Public body"]).toUpperCase());
                                                    setClientType({ "company": false, "planner": false, "public body": true })
                                                }}
                                                checked={clientType["public body"]}
                                            /> : null}
                                    </View>
                                    {route.params.language == "DE" ?
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginTop: 0 }}
                                            textStyle={{
                                                color: "#D90000",
                                                marginLeft: normalize(4),
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            size={normalize(15)}
                                            title={(BIM_TRANSLATION[route.params.language]["Public body"]).toUpperCase()}
                                            onPress={() => {
                                                setUserType((BIM_TRANSLATION[route.params.language]["Public body"]).toUpperCase());
                                                setClientType({ "company": false, "planner": false, "public body": true })
                                            }}
                                            checked={clientType["public body"]}
                                        /> : null}
                                    <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder={(BIM_TRANSLATION[route.params.language]["Worksite address"]).toUpperCase()}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(address) => setWorksiteAddress(address)}
                                        />
                                    </View>
                                    <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                        <TextInput
                                            style={styles.TextInput}
                                            editable={false}
                                            placeholder={(BIM_TRANSLATION[route.params.language]["Worksite"]).toUpperCase()}
                                            placeholderTextColor={theme.red}
                                        // onChangeText={(lastname) => setLastName(lastname)}
                                        />
                                    </View>
                                    <View style={{ width: normalize(330), flexDirection: "row", alignSelf: "center", alignItems: "center", justifyContent: "flex-start" }}>
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginVertical: 0, marginLeft: normalize(7) }}
                                            textStyle={{
                                                marginLeft: normalize(4),
                                                color: "#D90000",
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            size={normalize(15)}
                                            title={(BIM_TRANSLATION[route.params.language]["Airport"]).toUpperCase()}
                                            onPress={() => {
                                                setWsite((BIM_TRANSLATION[route.params.language]["Airport"]).toUpperCase());
                                                setWorksite(
                                                    {
                                                        "Airport": true,
                                                        "School": false,
                                                        "Hospital": false,
                                                        "Public Building": false,
                                                        "Directional Building": false,
                                                        "Shopping centres": false,
                                                        "Other": false
                                                    }
                                                )
                                            }}
                                            checked={worksite["Airport"]}
                                        />
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginVertical: 0 }}
                                            size={normalize(15)}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            textStyle={{
                                                color: "#D90000",
                                                marginLeft: normalize(4),
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            title={(BIM_TRANSLATION[route.params.language]["School"]).toUpperCase()}
                                            onPress={() => {
                                                setWsite((BIM_TRANSLATION[route.params.language]["School"]).toUpperCase());
                                                setWorksite(
                                                    {
                                                        "Airport": false,
                                                        "School": true,
                                                        "Hospital": false,
                                                        "Public Building": false,
                                                        "Directional Building": false,
                                                        "Shopping centres": false,
                                                        "Other": false
                                                    }
                                                )
                                            }}
                                            checked={worksite["School"]}
                                        />
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginVertical: 0 }}
                                            textStyle={{
                                                color: "#D90000",
                                                marginLeft: normalize(4),
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            size={normalize(15)}
                                            title={(BIM_TRANSLATION[route.params.language]["Hospital"]).toUpperCase()}
                                            onPress={() => {
                                                setWsite((BIM_TRANSLATION[route.params.language]["School"]).toUpperCase());
                                                setWorksite(
                                                    {
                                                        "Airport": false,
                                                        "School": false,
                                                        "Hospital": true,
                                                        "Public Building": false,
                                                        "Directional Building": false,
                                                        "Shopping centres": false,
                                                        "Other": false
                                                    }
                                                )
                                            }}
                                            checked={worksite["Hospital"]}
                                        />

                                    </View>
                                    <View style={{ width: normalize(330), flexDirection: "row", alignSelf: "center", alignItems: "center", justifyContent: "flex-start" }}>
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginVertical: 0, marginLeft: normalize(7) }}
                                            textStyle={{
                                                marginLeft: normalize(4),
                                                color: "#D90000",
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            size={normalize(15)}
                                            title={(BIM_TRANSLATION[route.params.language]["Public Building"]).toUpperCase()}
                                            onPress={() => {
                                                setWsite((BIM_TRANSLATION[route.params.language]["Public Building"]).toUpperCase());
                                                setWorksite(
                                                    {
                                                        "Airport": false,
                                                        "School": false,
                                                        "Hospital": false,
                                                        "Public Building": true,
                                                        "Directional Building": false,
                                                        "Shopping centres": false,
                                                        "Other": false
                                                    }

                                                )
                                            }}
                                            checked={worksite["Public Building"]}
                                        />
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginVertical: 0 }}
                                            size={normalize(15)}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            textStyle={{
                                                marginLeft: normalize(4),
                                                color: "#D90000",
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            title={(BIM_TRANSLATION[route.params.language]["Directional Building"]).toUpperCase()}
                                            onPress={() => {
                                                setWsite((BIM_TRANSLATION[route.params.language]["Directional Building"]).toUpperCase());
                                                setWorksite(
                                                    {
                                                        "Airport": false,
                                                        "School": false,
                                                        "Hospital": false,
                                                        "Public Building": false,
                                                        "Directional Building": true,
                                                        "Shopping centres": false,
                                                        "Other": false
                                                    }
                                                )
                                            }}
                                            checked={worksite["Directional Building"]}
                                        />

                                    </View>
                                    <View style={{ width: normalize(330), flexDirection: "row", alignSelf: "center", alignItems: "center", justifyContent: "flex-start" }}>
                                        <CheckBox
                                            containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginTop: 0, marginLeft: normalize(7) }}
                                            textStyle={{
                                                marginLeft: normalize(4),
                                                color: "#D90000",
                                                fontFamily: "OpenSansCondensedLight",
                                                fontSize: normalize(12)
                                            }}
                                            checkedColor="#D90000"
                                            uncheckedColor="#D90000"
                                            size={normalize(15)}
                                            title={(BIM_TRANSLATION[route.params.language]["Shopping centres"]).toUpperCase()}
                                            onPress={() => {
                                                setWsite((BIM_TRANSLATION[route.params.language]["Shopping centres"]).toUpperCase());
                                                setWorksite(
                                                    {
                                                        "Airport": false,
                                                        "School": false,
                                                        "Hospital": false,
                                                        "Public Building": false,
                                                        "Directional Building": false,
                                                        "Shopping centres": true,
                                                        "Other": false
                                                    }
                                                )
                                            }}
                                            checked={worksite["Shopping centres"]}
                                        />
                                        {worksite["Other"] ?
                                            <View style={[styles.inputView, { width: normalize(190), alignSelf: "center" }]}>
                                                <TextInput
                                                    style={styles.TextInput}
                                                    autoFocus={true}
                                                    placeholder={(BIM_TRANSLATION[route.params.language]["Other"]).toUpperCase()}
                                                    placeholderTextColor={theme.red}
                                                    onChangeText={(w) => setWsite(w)}
                                                />
                                            </View>

                                            : <CheckBox
                                                containerStyle={{ backgroundColor: "transparent", borderWidth: 0, paddingHorizontal: 0, marginTop: 0 }}
                                                size={normalize(15)}
                                                checkedColor="#D90000"
                                                uncheckedColor="#D90000"
                                                textStyle={{
                                                    marginLeft: normalize(4),
                                                    color: "#D90000",
                                                    fontFamily: "OpenSansCondensedLight",
                                                    fontSize: normalize(12),

                                                }}
                                                title={(BIM_TRANSLATION[route.params.language]["Other"]).toUpperCase()}
                                                onPress={() => setWorksite(
                                                    {
                                                        "Airport": false,
                                                        "School": false,
                                                        "Hospital": false,
                                                        "Public Building": false,
                                                        "Directional Building": false,
                                                        "Shopping centres": false,
                                                        "Other": true
                                                    }
                                                )}
                                                checked={worksite["Other"]}
                                            />}

                                    </View>
                                    <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                        <TextInput
                                            style={styles.TextInput}
                                            placeholder={(BIM_TRANSLATION[route.params.language]["Street name and number"]).toUpperCase()}
                                            placeholderTextColor={theme.red}
                                            onChangeText={(address) => setstreetName(address)}
                                        />
                                    </View>

                                    <View style={styles.twoInputView}>
                                        <View style={[styles.inputView, { width: normalize(100) }]}>
                                            <TextInput
                                                style={styles.TextInput}
                                                placeholder={(BIM_TRANSLATION[route.params.language]["Town"]).toUpperCase()}
                                                placeholderTextColor={theme.red}
                                                onChangeText={(town) => setTown(town)}
                                            />
                                        </View>
                                        <View style={[styles.inputView, { width: normalize(228) }]}>
                                            <TextInput
                                                style={styles.TextInput}
                                                placeholder={(BIM_TRANSLATION[route.params.language]["Fire reaction class"]).toUpperCase()}
                                                placeholderTextColor={theme.red}
                                                onChangeText={(rclass) => setfireReactionClass(rclass)}
                                            />
                                        </View>
                                    </View>
                                    <View style={[styles.inputView, { width: normalize(338), alignSelf: "center" }]}>
                                        <TextInput
                                            editable={false}
                                            style={styles.TextInput}
                                            placeholder={requiredFile}
                                            placeholderTextColor={theme.red}
                                        />
                                    </View>
                                </View>
                                {downloading == "req-btn" ?
                                    <Image
                                        style={{ width: normalize(100), height: normalize(100) }}
                                        source={require("../../../assets/MyAmonn_loading_red.gif")} />
                                    : <TouchableOpacity
                                        style={styles.button}
                                        onPress={() => {
                                            setDownloading("req-btn");
                                            sendRequest();
                                        }}
                                    >
                                        <Text style={styles.textStyle}>Download</Text>
                                    </TouchableOpacity>}
                            </View>
                        </ScrollView>
                    </Modal>

                    {/* <BottomSheet
            ref={openFilter}
            snapPoints={[normalize(0), normalize(900)]}
            renderContent={()=><BIMFilter 
                doFilter={(solutionType,langFilter)=>{
                    setSolutionFilter(solutionType);
                    setLangFilter(langFilter);
                    console.log(solutionFilter);

                }} 
                language={language}
                openFilter={openFilter}/>}
                initialSnap={0}
                enabledContentTapInteraction={false}
        /> */}
                    <FlatList
                        data={documents}
                        contentContainerStyle={{
                            justifyContent: "center",
                            alignItems: 'center',
                            marginVertical: normalize(8, 735)
                        }}
                        key={2}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item, index }) => {
                            let q = query.toLowerCase();
                            let name = String(item.name).toLowerCase();
                            var n = name.lastIndexOf('_');
                            var result = name.substring(n + 1);
                            var cat = "";
                            if (solutionFilter == "RIQUALIFICA AL FUOCO DI SOLAI ESISTENTI" && langFilter == "IT") {
                                cat = "SOLAI".toLowerCase()
                                // console.log(cat,name);
                            }
                            else if (solutionFilter == "RIQUALIFICA AL FUOCO DI PARETI ESISTENTI" && langFilter == "IT") {
                                cat = "PARETI".toLowerCase()
                            }
                            else if (solutionFilter == "RIQUALIFICA AL FUOCO DI ELEMENTI STRUTTURALI" && langFilter == "IT") {
                                cat = "_STRUTTURE".toLowerCase()
                            }
                            else if (solutionFilter == "RIQUALIFICA DI ELEMENTI DI RIVESTIMENTO IN LEGNO NON STRUTTURALI" && langFilter == "IT") {
                                cat = "REAZIONE".toLowerCase()

                            }
                            else if (solutionFilter == "PROTECTION TO FIRE RETROFIT OF EXISTING SLABS" && langFilter == "EN") {
                                cat = "SLABS".toLowerCase()

                            }
                            else if (solutionFilter == "PROTECTION TO FIRE RETROFIT OF EXISTING WALLS" && langFilter == "EN") {
                                cat = "WALLS".toLowerCase()

                            }
                            else if (solutionFilter == "PROTECTION TO FIRE RETROFIT OF STRUCTURAL ELEMENTS" && langFilter == "EN") {
                                cat = "STRUCTURES".toLowerCase()

                            }
                            else if (solutionFilter == "PROTECTION TO FIRE RETROFIT OF NON-STRUCTURAL WOODEN ELEMENTS" && langFilter == "EN") {
                                cat = "WOOD_REACTION".toLowerCase()

                            }
                            else {

                            }
                            if ((name.includes(q) && result.includes(langFilter.toLowerCase()) && name.includes(cat)) || (q == "" && result.includes(langFilter.toLowerCase()) && name.includes(cat))) {

                                return (
                                    <TouchableHighlight
                                        underlayColor={"transparent"}
                                        onPress={() => {

                                            downloadedDocuments.includes(String(item.name) + "&bim") ? openMyDocument(String(item.name) + "&bim") : null;
                                        }
                                        }
                                    >
                                        <View
                                            style={{
                                                backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
                                                width: width > 786 ? normalize(900) : normalize(380),
                                                height: normalize(48, 735),
                                                marginHorizontal: width > 786 ? normalize(40) : normalize(10),
                                                paddingHorizontal: normalize(20),
                                                flexDirection: "row",
                                                justifyContent: "space-between"
                                            }}>
                                            <View
                                                style={{
                                                    width: width > 786 ? normalize(850) : normalize(320),
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <TextTicker
                                                    style={{
                                                        color: "#333333",
                                                        fontFamily: "OpenSansCondensedBold",
                                                        fontSize: normalize(16),
                                                        marginLeft: normalize(5),
                                                    }}
                                                    duration={9500}
                                                    bounce
                                                >{item.name}</TextTicker>
                                            </View>
                                            <View style={styles.downloadBtn}>
                                                {downloading == item.name ?
                                                    <Image
                                                        style={{ width: normalize(50), height: normalize(50) }}
                                                        source={require("../../../assets/MyAmonn_loading_red.gif")} />
                                                    :
                                                    [downloadedDocuments.includes(String(item.name) + "&bim") ?
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                deleteFile(String(item.name) + "&bim")
                                                            }}
                                                        >
                                                            <Icon
                                                                name={"delete"}
                                                                color={"grey"}
                                                                size={normalize(25)}
                                                            />
                                                        </TouchableOpacity> :
                                                        <TouchableOpacity
                                                            onPress={async () => {
                                                                setrequiredFile(item.name);
                                                                setDownloading(item.name);
                                                                await checkStatus(item.name);

                                                            }}
                                                        >
                                                            <Icon
                                                                name={"arrow-down-circle"}
                                                                color={"grey"}
                                                                size={normalize(25)}
                                                            />
                                                        </TouchableOpacity>]}
                                            </View>
                                        </View>
                                    </TouchableHighlight>)
                            }
                        }}
                        numColumns={1}
                        key={"1"}
                    />
                </View>


            </View>
        </TouchableWithoutFeedback>
    );
};


export default BIM;

