import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, TextInput, Text, ActivityIndicator, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import styles from './styles';
import axios from "axios";
import * as theme from "../../Theme";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Alert from '../../Components/Alert/Alert';
import RADIX_TRANSLATION from './RADIX_TRANSLATION';
import { normalize } from '../../Helpers/Normalizer';
const RadixLogin = ({ navigation, route }) => {
    const [language, setLanguage] = useState(route.params.language)
    const [radixId, setradixID] = useState(null);
    const [radixDocuments, setRadixDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    function LoadingIndicatorView() {
        return (
            // <ActivityIndicator
            //           color={theme.red}
            //           size="large"
            //           style={styles.activityIndicatorStyle}
            //         />
            <View style={styles.activityIndicatorStyle}>
                <Image
                    style={{ width: normalize(250), height: normalize(250) }}
                    source={require("../../../assets/MyAmonn_loading_red.gif")} />
            </View>)
    }
    const goToRadix = async () => {
        if (radixId) {
            setLoading(true);
            axios.get('https://myadmin.myamonn.com/Radix/' + radixId, {
                // params: {
                // search: radixId
                // },
                headers: { Authorization: `Token ${token}` }
            })
                .then(function (response) {
                    setRadixDocuments(response.data);
                    setLoading(false);
                    navigation.navigate("RadixDashboard", {
                        radixDocuments: response.data
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                    setAlert(true);
                    setAlertMessage("Please enter valid Password");
                })
                .then(function () {
                    // always executed
                });
        }
        else {
            setAlert(true);
            setAlertMessage("Please enter valid User ID");
        }
    }
    const alerting = () => {
        setAlert(false);
        setAlertMessage("");

    }
    useEffect(() => {
        AsyncStorage.getItem('token').then((token) => {
            // console.log("token",token);
            if (token != null) {

                setToken(JSON.parse(token));
            }
            else {
                navigation.navigate("Login", { route: route });
            }
        })
            .catch((err) => {
                alert(err)
            })
    }, [route])
    if (loading) {
        return LoadingIndicatorView();
    }
    else return (
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ alignItems: "center" }}>
            {alert ? <Alert message={alertMessage} setAlert={alerting} /> : null}
            <View style={styles.circle}>
                <Image style={styles.avatar} source={require("../../../assets/myamonn-icons/USER.png")} />
            </View>
            <View style={styles.body}>
                <View style={styles.logoView}>
                    <Image style={styles.logo} source={require("../../../assets/myamonn-icons/logo-white.png")} />
                </View>
                <Text style={styles.radixText}>ACCESS RADIX</Text>

                <View style={styles.inputView}>
                    <TextInput
                        style={styles.TextInput}
                        placeholder="Password"
                        placeholderTextColor="#D90000"
                        onChangeText={(radixId) => setradixID(radixId)}
                    />
                </View>

                <TouchableOpacity style={styles.RgBtn}
                    onPress={async () => {
                        await goToRadix()
                    }}>
                    <Text style={styles.RgText}>ENTER</Text>
                </TouchableOpacity>
                <Text style={styles.bottomText}>
                    {RADIX_TRANSLATION[language]["L'ACCESSO A RADIX È DEDICATO ESCLUSIVAMENTE AI CLIENTI \n AMONN.\n SE NON POSSIEDI UN USER ID PER ACCEDERE, CONTATTACI A \nINFO@AMONNCOLOR.COM\n PER SAPERE COME RICHIEDERNE UNO."]}
                </Text>

            </View>
        </ScrollView>



    );
};

export default RadixLogin;

