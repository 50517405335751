import React, { useRef,useState,useEffect} from 'react';
import { NavigationContainer } from '@react-navigation/native';
import {useWindowDimensions,Platform,Image} from "react-native";
import { createDrawerNavigator } from '@react-navigation/drawer';
// import Icon from 'react-native-vector-icons/Ionicons';
import Onboarding from '../Components/Onboarding/Onboarding';
import Login from '../screens/Login/Login';
import Register from '../screens/Register/Register';
import StackNavigation from './stackNavigation';
import DrawerContent from '../Components/DrawerContent/DrawerContent';
// import { normalize } from 'react-native-responsive-fontsize';
import { normalize } from '../Helpers/Normalizer';
import { RFValue } from 'react-native-responsive-fontsize';
import Contact from '../screens/Contact/Contact';
import AsyncStorage from '@react-native-async-storage/async-storage';
const Drawer = createDrawerNavigator();

const DrawerNavigation=()=>{
    const dimensions = useWindowDimensions();
    const [language,setLanguage]=useState("IT");
    const linking = {
      config: {
        initialRouteName:"web-build",
      },
    };
    
    const getLanguage= ()=>{
      AsyncStorage.getItem("language").then((response)=>{
        if(response!=null){
            setLanguage(JSON.parse(response));
            // console.log(language);
          }
        })
    }
      useEffect(()=>{
        getLanguage();
      })
    return(
        <NavigationContainer linking={linking}>
        <Drawer.Navigator
            drawerContent={props=><DrawerContent {...props} />}
            screenOptions={{
                drawerStyle: {
                  backgroundColor: '#c6cbef',
                  width: normalize(240),
                },
                drawerType: dimensions.width >= 768 ? 'front' : 'front',
                drawerPosition:'left',
                drawerStyle:{
                  backgroundColor: '#D90000',
                  width: normalize(220),
                  height:normalize(610,735),
                  borderBottomRightRadius:normalize(40),
                }
              }}
              
            >
            {/* {Platform.OS !=="web"?
            <Drawer.Screen  name="Onboarding" 
              component={Onboarding} 
              options={
                {
                  headerShown:false,
                  swipeEnabled:false,
                }}
            />:null} */}
            <Drawer.Screen  name="Login" 
              component={Login}
              initialParams={{language:language}}
              options={
                {
                  title:"Login | myAmonn",
                  headerShown:false,
                  swipeEnabled:false,
                }}
            />
            <Drawer.Screen  name="Register" 
              component={Register} 
              initialParams={{language:language}}
              options={
                {
                  title:"Register | myAmonn",
                  headerShown:false,
                  swipeEnabled:false,
                }}
            />
            <Drawer.Screen  
              name="Home" 
              component={StackNavigation}
              options={
                {
                  title:"Home | myAmonn",
                  headerShown:false,
                  swipeEnabled:false,
                  
                }}

            />
            {/* <Drawer.Screen  
              name="Contact" 
              component={Contact} 
              options={
                {
                  // headerShown:false,
                  // swipeEnabled:false,
                  
                }}

            /> */}
      </Drawer.Navigator>
      </NavigationContainer>
    );
}
export default DrawerNavigation;