import React, { useEffect,useState } from 'react';
import { Image,TouchableOpacity,View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import Icon from 'react-native-vector-icons/Ionicons';
import Dashboard from '../screens/Dashboard/Dashboard';
import AllProducts from '../screens/AllProducts/AllProducts';
import Product from '../screens/Product/Product';
import MyProducts from '../screens/MyProducts/MyProducts';
import RadixLogin from '../screens/RadixLogin/RadixLogin';
import RadixDashboard from '../screens/RadixDashboard/RadixDashboard';
import VideoLibrary from '../screens/VideoLibrary/VideoLibrary';
import BIM from '../screens/BIM/BIM';
import MyProfile from '../screens/MyProfile/MyProfile';
import Contact from '../screens/Contact/Contact';
import Notifications from '../screens/Notification/Notification';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { normalize } from '../Helpers/Normalizer';
import UpdateProfile from '../screens/UpdateProfile/UpdateProfile';

const DashboardStack = createStackNavigator();
const StackNavigation=({navigation})=>{
  const [language,setLanguage]=useState("IT");
  const getLanguage= ()=>{
    AsyncStorage.getItem("language").then((response)=>{

        setLanguage(JSON.parse(response));
        // console.log(language);
        })
}
    useEffect(()=>{
      getLanguage();
    })
    return(
        <DashboardStack.Navigator screenOptions={{
          headerStyle:{
            height:normalize(60),
            backgroundColor:"#D90000",
          },
          headerTintColor:"#fff",
          // title:
          //   <View
          //     style={{justifyContent:"center"}}
          //     >
          //     <TouchableOpacity onPress={()=>{navigation.navigate("Dashboard")}}>
          //       <Image 
          //         style={{
          //           width:normalize(238),
          //           height:normalize(100),
          //           alignSelf:"flex-start"
          //         }} 
          //         source={require("../../assets/myamonn-icons/logo-white.png")}/>
          //     </TouchableOpacity>
          //   </View>,
            headerLeft:()=>(
              <Icon.Button 
                name="ios-menu"
                size={normalize(25)}
                backgroundColor="#D90000" onPress={()=>navigation.openDrawer()}>
              </Icon.Button>
            ),
            headerTitle:false,
            headerRight:false,
          
        }}>
          <DashboardStack.Screen name="Dashboard"  component={Dashboard} initialParams={{ language: language }} options={{title:"Dashboard | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="AllProducts" component={AllProducts} initialParams={{ language: language }} options={{title:"All Products | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="Product" component={Product} initialParams={{ language: language }} options={{title:"Product | myAmonn",headerTitle:()=>{<View></View>}, gestureEnabled:false}}/>
          <DashboardStack.Screen name="MyProducts" component={MyProducts} initialParams={{ language: language }} options={{title:"myProducts | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="RadixLogin" component={RadixLogin} initialParams={{ language: language }} options={{title:"Radix | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="RadixDashboard" component={RadixDashboard} initialParams={{ language: language }} options={{title:"Radix | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          {/* <DashboardStack.Screen name="VideoLibrary" component={VideoLibrary} options={{gestureEnabled:false}}/> */}
          <DashboardStack.Screen name="BIM" component={BIM} initialParams={{ language: language }} options={{title:"BIM | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="MyProfile" component={MyProfile} initialParams={{ language: language }} options={{title:"myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="UpdateProfile" component={UpdateProfile} initialParams={{ language: language }} options={{title:"myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>
          <DashboardStack.Screen name="Contact" component={Contact} initialParams={{ language: language }} options={{gestureEnabled:false}}/>
          <DashboardStack.Screen name="Notifications" component={Notifications} initialParams={{ language: language }} options={{title:"Notifications | myAmonn",headerTitle:()=>{<View></View>},gestureEnabled:false}}/>


          {/* component={()=><BIM language={language}/> */}

      </DashboardStack.Navigator>
      );
}
export default StackNavigation;
